/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class Trace extends FabricCanvasTool {
	configureCanvas(props) {
		let canvas = this._canvas;
		canvas.isDrawingMode = canvas.selection = true;
		canvas.freeDrawingBrush.width = props.lineWidth;
		canvas.freeDrawingBrush.color = props.lineColor;
	}
}

export default Trace;
