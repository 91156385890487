/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

const fabric = require("fabric").fabric;

class Rectangle extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = false;

    canvas.forEachObject(o => {
      o.selectable = false;
      o.evented = false;
      o.hasControls = false;
      o.hasBorders = false;
    });

    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    if (this.rect) return; // If a rectangle already exists, return early

    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;
    const fixedSize = 200; // Set the desired size here
    const halfSize = fixedSize / 2;

    this.rect = new fabric.Rect({
      left: this.startX - halfSize,
      top: this.startY - halfSize,
      originX: "left",
      originY: "top",
      width: fixedSize,
      height: fixedSize,
      stroke: this._color,
      strokeWidth: this._width,
      fill: this._fill,
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0,
      strokeUniform: true
    });

    canvas.add(this.rect);
    canvas.bringToFront(this.rect);
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.rect = null; // Reset the rectangle so a new one can be added on the next mouse down
  }
}

export default Rectangle;
