import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProjectsInVault } from "../../redux/user/actions";
import classnames from "classnames";
import { swal } from "react-redux-sweetalert2";
import { ClipLoader } from "halogenium";
import { isTablet } from "react-device-detect";
import CommonMethod from "../../utilities/CommonMethod";

class ProjectVault extends Component {
	state = {
		wiggleProjects: [],
		flipBookProjects: [],
		advancedProjects: [],
		activeProject: "",
		activeProjectType: "",
		showWiggleLoader: false,
		showFlipbookLoader: false,
		showAdvancedLoader: false
	};

	componentDidMount() {
		let { userDetails } = this.props.user;
		this.setState({
			showWiggleLoader: true,
			showFlipbookLoader: true,
			showAdvancedLoader: true
		});
		let wiggleObj = {
				userId: CommonMethod.samplesInProjectVault,
				category: "wiggledoodle"
			},
			flipbookObj = {
				userId: CommonMethod.samplesInProjectVault,
				category: "flipbook"
			},
			advancedObj = {
				userId: CommonMethod.samplesInProjectVault,
				category: "advanced-doodle"
			};
		this.props.getProjectsInVault(wiggleObj, res => {
			this.setState({
				wiggleProjects: res,
				showWiggleLoader: false
			});
		});
		this.props.getProjectsInVault(flipbookObj, res => {
			this.setState({
				flipBookProjects: res,
				showFlipbookLoader: false
			});
		});
		this.props.getProjectsInVault(advancedObj, res => {
			this.setState({
				advancedProjects: res,
				showAdvancedLoader: false
			});
		});
	}

	renderWiggleDoodleProjectNames(wiggleProjects) {
		let { activeProject, showWiggleLoader } = this.state;

		if (showWiggleLoader) {
			return (
				<div className="projectVaultLoader">
					<ClipLoader color="#33A5C9" size="22px" margin="4px" />
				</div>
			);
		}

		return (
			wiggleProjects.length > 0 &&
			wiggleProjects.map((item, index) => {
				item.fromProjectVault = true;
				return (
					<li key={index}>
						<div
							className={classnames({
								active_project_in_vault:
									activeProject._id === item._id,
								art_inner_col: true
							})}
							onDoubleClick={() => {
								this.props.history.push("/wiggledoodle", item);
							}}
							onClick={() => {
								if (isTablet) {
									this.props.history.push(
										"/wiggledoodle",
										item
									);
								}
								this.setState({
									activeProject: item,
									activeProjectType: "wiggledoodle"
								});
							}}
						>
							<span
							style={{
								cursor: 'pointer',
							  }}
							>
								<img src={item.keyframesBase64} />
							</span>
							<span
							style={{
								cursor: 'pointer',
							  }}
							  title="Give the project file two quick clicks."
							>
								{/*<img src="images/advanced/icon1text.png" />*/}
								{item.name}
							</span>
						</div>
					</li>
				);
			})
		);
	}

	renderFlipbookProjectNames(flipBookProjects) {
		let { activeProject, showFlipbookLoader } = this.state;

		if (showFlipbookLoader) {
			return (
				<div className="projectVaultLoader">
					<ClipLoader color="#33A5C9" size="22px" margin="4px" />
				</div>
			);
		}
		return (
			flipBookProjects.length > 0 &&
			flipBookProjects.map((item, index) => {
				item.fromProjectVault = true;
				return (
					<li key={index}>
						<div
							className={classnames({
								active_project_in_vault:
									activeProject._id === item._id,
								art_inner_col: true
							})}
							onDoubleClick={() => {
								this.props.history.push("/flipbook", item);
							}}
							onClick={() => {
								if (isTablet) {
									this.props.history.push("/flipbook", item);
								}
								this.setState({
									activeProject: item,
									activeProjectType: "flipbook"
								});
							}}
						>
							<span>
								<img src={item.keyframesBase64} />
							</span>
							<span
							style={{
								cursor: 'pointer',
							  }}
							  title="Give the project file two quick clicks."
							>
								{/*<img src="images/advanced/icon1text.png" />*/}
								{item.name}
							</span>
						</div>
					</li>
				);
			})
		);
	}

	renderAdvancedProjectNames(advancedProjects) {
		let { activeProject, showAdvancedLoader } = this.state;

		if (showAdvancedLoader) {
			return (
				<div className="projectVaultLoader">
					<ClipLoader color="#33A5C9" size="22px" margin="4px" />
				</div>
			);
		}

		return (
			advancedProjects.length > 0 &&
			advancedProjects.map((item, index) => {
				item.fromProjectVault = true;
				return (
					<li key={index}>
						<div
							className={classnames({
								active_project_in_vault:
									activeProject._id === item._id,
								art_inner_col: true
							})}
							onDoubleClick={() => {
								this.props.history.push(
									"/advanced-doodle",
									item
								);
							}}
							onClick={() => {
								if (isTablet) {
									this.props.history.push(
										"/advanced-doodle",
										item
									);
								}
								this.setState({
									activeProject: item,
									activeProjectType: "advanced-doodle"
								});
							}}
						>
							<span
							style={{
								cursor: 'pointer',
							  }}
							>
								<img src={item.keyframesBase64} />
							</span>
							<span
							style={{
								cursor: 'pointer',
							  }}
							  title="Give the project file two quick clicks."
							>
								{/*<img src="images/advanced/icon1text.png" />*/}
								{item.name}
							</span>
						</div>
					</li>
				);
			})
		);
	}

	openSelectedProject() {
		let { activeProject, activeProjectType } = this.state;
		if (activeProjectType)
			this.props.history.push(`/${activeProjectType}`, activeProject);
	}

	cannotBrowse() {
		this.props.swal({
			title: "Oops!",
			text:
				"Projects are saved over the cloud, cannot browse through them in the local system..",
			type: "info",
			confirmAlert: () => false
		});
	}

	render() {
		let { wiggleProjects, flipBookProjects, advancedProjects } = this.state;
		let { userDetails } = this.props.user;

		return (
			<div className="body_wrap">
				<div className="conta_iner">
					<div className="animation_ish_box">
						<div className="project_vault_section">
							<div className="main_logo_vault">
								<img src="https://d3rc7k2n78msdl.cloudfront.net/project/logo.png" />
							</div>
							<div className="main_mid_projectvault_sec">
								<div className="main_mid_img">
									<img style={{width: "1160px", height: "450px"}} src="https://d3rc7k2n78msdl.cloudfront.net/project/main_box.png" />
									<div className="projectvault_overlay">
										<ul>
											<li>
												<div className="vault_logo">
													<img src="https://d3rc7k2n78msdl.cloudfront.net/project/icon-1.png" />
												</div>
												<div className="bault_small_box">
													<img src="https://d3rc7k2n78msdl.cloudfront.net/project/small_box.png" />
													<div className="art_list">
														<ul>
															{this.renderWiggleDoodleProjectNames(
																wiggleProjects
															)}
														</ul>
													</div>
												</div>
											</li>
											<li>
												<div className="vault_logo">
													<img src="https://d3rc7k2n78msdl.cloudfront.net/project/icon-2.png" />
												</div>
												<div className="bault_small_box">
													<img src="https://d3rc7k2n78msdl.cloudfront.net/project/small_box.png" />
													<div className="art_list">
														<ul>
															{this.renderFlipbookProjectNames(
																flipBookProjects
															)}
														</ul>
													</div>
												</div>
											</li>
											<li>
												<div className="vault_logo">
													<img src="https://d3rc7k2n78msdl.cloudfront.net/project/icon-3.png" />
												</div>
												<div className="bault_small_box">
													<img src="https://d3rc7k2n78msdl.cloudfront.net/project/small_box.png" />
													<div className="art_list">
														<ul>
															{this.renderAdvancedProjectNames(
																advancedProjects
															)}
														</ul>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div
								className="open_or_home_buttons"
								title="Open My Projects"
							>
								<div
									className="left_open_buttons"
									onClick={() =>
										this.props.history.push("my-projects")
									}
								>
									<img src="https://d3rc7k2n78msdl.cloudfront.net/myproject/open_myprojects.png" />
								</div>
								<div className="left_open_buttons">
									<Link to="/dashboard">
										<img src="https://d3rc7k2n78msdl.cloudfront.net/project/home.png" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	canvas: state.canvas,
	user: state.user
});

const mapDispatchToProps = dispatch => ({
	getProjectsInVault: bindActionCreators(getProjectsInVault, dispatch),
	swal: bindActionCreators(swal.showAlert, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectVault);
