import moment from "moment";
import Messages from "./messages";
import _ from "underscore";

const CommonMethod = {
  samplesInProjectVault: 130806, //100 // 84685 oldId // 130806 newId

  dateToTimestamp: date => {
    return moment(date).unix();
  },

  timeStampToDate: date => {
    return moment.unix(date).format("DD/MM/YYYY");
  },

  milliTimeStampToDate: date => {
    return moment(date).format("DD MMMM YYYY");
  },
  TimeStampToDateTimeFormat: date => {
    return moment(date).format("DD MMMM YYYY");
  },
  milliTimeStampToDateTimeFormat: date => {
    return moment(date)
      .calendar()
      .split(" at")[0];
  },
  // eg. 20 April 2018
  timeStampToDateFormat: () => {
    return moment().format(" h:mm A,DD MMMM YYYY");
  },
  timeStamp: date => {
    return moment.unix(date).format("MMMM Do YYYY");
  },
  dateFormat: date => {
    return moment.unix(date).format("DD-MM-YYYY    h:mm a");
  },
  timeFormat: date => {
    return moment.unix(date).format("h:mm:a");
  },
  ucFirst: (str, ln) => {
    if (str) {
      let limit = str.length;
      if (limit > 0) {
        return (
          str
            .toLowerCase()
            .split(" ")
            .map(function(word) {
              if (word) {
                return word[0].toUpperCase() + word.substr(1);
              }
            })
            .join(" ")
            .substring(0, ln) + (limit > ln ? "..." : "")
        );
      }
    }
  },

  convertDateTOCalenderFormate: () => {
    return new Date().toISOString().slice(0, 10);
  },

  determineCandidateStage: level => {
    if (level === 1) {
      return "is-1st-step";
    } else if (level === 2) {
      return "is-2nd-step";
    } else if (level === 3) {
      return "is-3rd-step";
    } else if (level === 4) {
      return "is-4th-step";
    } else if (level === 5) {
      return "is-5th-step";
    }
  },

  successResponse: (result, message) => {
    let res = {
      status: true,
      message: message,
      response: result ? result : null,
      type: "Success!"
    };
    return res;
  },

  failedResponse: message => {
    let res = {
      status: false,
      message: message,
      response: null,
      type: "Error!"
    };
    return res;
  },

  responseIntoCatchBlock: () => {
    let res = {
      status: false,
      message: Messages.wentWrong,
      type: "Error!"
    };
    return res;
  },

  timeAgo: time => {
    let seconds = Math.floor((new Date() - new Date(time)) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      let text = interval > 1 ? " years" : " year";
      return interval + text + " ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      let text = interval > 1 ? " months" : " month";
      return interval + text + " ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      let text = interval > 1 ? " days" : " day";
      return interval + text + " ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      let text = interval > 1 ? " hours" : " hour";
      return interval + text + " ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      let text = interval > 1 ? " minutes" : " minute";
      return interval + text + " ago";
    }
    if (seconds === 0 || seconds === 1) {
      return "Now";
    }
    return Math.floor(seconds) + " seconds ago";
  },

  getYears: length => {
    let years = [];
    for (
      let i = new Date().getFullYear();
      i >= new Date().getFullYear() - length;
      i--
    ) {
      years.push(i);
    }
    return years;
  },

  imageExt: ["jpg", "png", "jpeg"],
  videoExt: ["3gp", "mp4", "mov"],
  interviewDoc: ["pdf", "doc", "docx"],

  // Get index by id array should be like [{_id:2,...},{_id:4,...},...]
  getIndexById: (array, _id) => {
    return _.findIndex(array, { _id });
  },

  // local search by on data.
  // work with n time nested keys like 'key.key.key......key'
  nTimeDeepKeyLocalSearch(data, text = "", keys) {
    let result = -1;
    if (!text) {
      return true;
    }
    for (let i = 0; i < keys.length; i++) {
      let subChild = keys[i].split(".");
      if (subChild.length >= 2) {
        let firstElement = subChild.splice(0, 1);
        result = this.nTimeDeepKeyLocalSearch(
          data[firstElement],
          text,
          subChild
        );
      } else {
        result = data[keys[i]].toLowerCase().indexOf(text.toLowerCase());
      }

      if (result === -1 || result === false) {
        continue;
      }
      break;
    }
    return result === -1 || result === false ? false : true;
  },

  createDropdownList: arr => {
    arr = arr ? arr : [];
    let selectOne = [{ value: "", label: "Select One" }];
    let dropdownVal = arr.map(obj => {
      let data = {};
      data.value = obj._id;
      data.label = obj.address1;
      return data;
    });
    return [...selectOne, ...dropdownVal];
  },

  createSkillsTags: skills => {
    let skillTags = skills.map(obj => {
      let data = {};
      data.id = obj._id;
      data.text = obj.text;
      return data;
    });
    return skillTags;
  },

  // slice a string in array if it is longer than 20 chars
  sliceTitleInArray: array =>
    _.map(array, list => {
      list.title =
        list.title.length < 21
          ? list.title.capitalizeEachLetter()
          : list.title.substring(0, 20).capitalizeEachLetter() + "...";
      return list;
    }),
  // slice a string if it is longer than 20 or given chars
  sliceString: (string, limit = 20) =>
    string.length < limit ? string : string.substring(0, limit) + "...",

  owlCorouselSettings: {
    loop: true,
    items: 5,
    autoplay: true,
    dots: true,
    dotsEach: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 3
      },
      1366: {
        items: 5
      }
    }
  },
  hotListOwlCorouselSettings: {
    loop: true,
    items: 5,
    autoplay: false,
    dots: true,
    dotsEach: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 3
      },
      1366: {
        items: 5
      }
    }
  },
  owlCorouselJobStages: {
    loop: false,
    items: 4,
    nav: true,
    navText: ["<", ">"],
    navClass: ["owl-prev", "owl-next"],
    autoplay: false,
    dots: false,
    dotsEach: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      },
      1366: {
        items: 4
      }
    }
  },

  settings: {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1

    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  },
  restApiCatchError: {
    errorMessage: Messages.wentWrong,
    title: "Error",
    isError: true
  }
};

export default CommonMethod;
