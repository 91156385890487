// export const API_URL = 'http://localhost:7000'
// export const API_URL = 'http://localhost/api'
export const API_URL = 'https://animationish.com/api'
// export const API_URL = 'http://ec2-18-212-204-56.compute-1.amazonaws.com/api';


/* no more use */

// export const API_URL = 'https://animationish.com:7000'
// export const API_URL = 'https://fablevision.netsmartz.us:7000'
//  export const API_URL = 'https://fablevisionprod.netsmartz.us:8000'
// export const API_URL = 'http://ec2-18-212-204-56.compute-1.amazonaws.com:7000';

/* no more use */