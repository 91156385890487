import React from "react";
import { Switch } from "react-router-dom";
import AppRoute from "./AppRoute";
import NotFound from "../components/NotFound";
import Home from "../containers/";

import Dashboard from "../containers/dashboard";
import ProjectVault from "../containers/projectVault";
import MyProjects from "../containers/myProjects";
import Login from "../containers/login";
import ForgotPassword from "../containers/forgotPassword";

import WiggleDoodle from "../containers/wiggleDoodle";
import FlipBook from "../containers/flipbook";
import AdvancedDoodle from "../containers/advanced";
import Sound from "../containers/sound";

//  import Dashboard from "../containers/Dashboard";
import { frontLayout, dashboardLayout } from "../components/Layouts";

const Routers = ({ store, history }) => {
  const state = store.getState();


  // authenticate if the user is loggedIn or not and perform action accordingly
  const requireAuth = () => {
    if (!state.user.loggedIn && history.location.pathname !== "/") {
      history.push("/");
    } else if (state.user.loggedIn && history.location.pathname === "/") {
      history.push("/dashboard");
    }
  };

  return (
    <Switch>
      <AppRoute
        exact={true}
        path="/"
        component={Login}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />
      <AppRoute
        path="/forgot-password"
        component={ForgotPassword}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />
      <AppRoute
        path="/dashboard"
        component={Dashboard}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />

      <AppRoute
        path="/project-vault"
        component={ProjectVault}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />

      <AppRoute
        path="/my-projects"
        component={MyProjects}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />

      <AppRoute
        path="/wiggledoodle"
        component={WiggleDoodle}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />

      <AppRoute
        path="/flipbook"
        component={FlipBook}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />

      <AppRoute
        path="/advanced-doodle"
        component={AdvancedDoodle}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />
      {/* <AppRoute
        path="/Sound"
        component={Sound}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      /> */}
      <AppRoute
        path="*"
        component={NotFound}
        requireAuth={requireAuth}
        layout={frontLayout}
        isLogin={state.user.loggedIn}
      />
    </Switch>
  );
};
export default Routers;
