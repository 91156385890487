import React, { Component } from "react";
import { withOrientationChange, isMobile } from "react-device-detect";
import $ from "jquery";

class DeviceAndOrientation extends Component {
  overlayAppeared() {
    // $("#unsavedChanges").trigger("click");
  }

  overlayDisappeared() {
    $("[data-dismiss=modal]").trigger({ type: "click" });
  }

  render() {
    let { isPortrait, isLandscape } = this.props;
    if (isMobile && isPortrait) {
      this.overlayAppeared();
    } else {
      this.overlayDisappeared();
    }
    return null;
    return (
      <div>
        <button
          type="button"
          id="unsavedChanges"
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#myModal"
        >
          Open Modal
        </button>
        <div id="myModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Modal Header</h4>
              </div>
              <div className="modal-body">
                <p>Some text in the modal.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeviceAndOrientation = withOrientationChange(DeviceAndOrientation);

export default DeviceAndOrientation;
