import React, { useEffect, useRef } from "react";
import classnames from "classnames";

const RightTools = props => {
  const svgContainerRef = useRef(null);

  useEffect(() => {
    // Fetch the SVG file
    fetch('https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/splash.svg')
      .then(response => response.text())
      .then(svgContent => {
        // Create a DOM parser to parse the SVG
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');

        // Modify the SVG elements with the class "cls-4"
        svgElement.querySelectorAll('path').forEach((element) => {
          element.style.fill = props.selectedBrushColor;
        });

        // Clear the container and inject the modified SVG
        svgContainerRef.current.innerHTML = '';
        svgContainerRef.current.appendChild(svgElement);
      })
      .catch(error => console.error('Error fetching SVG:', error));
  }, [props.selectedBrushColor]);

  return (
    <div className="wiggle_doddle_main_mid_right col-sm-2">
      <div className="wiggle_doddle_main_inner">
      <div className="splash" ref={svgContainerRef} />
        <div className="color_box">
          {/* <div className="color_text">
            <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/color_text.png" />
          </div> */}
          <div className="color_main_box">
            <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/wiggle-flipbook_palette.png" />
            <div className="overlay_color_box">
              <ul>
                <li
                  onClick={() => props.setBrushColor("color_1")}
                  onDoubleClick={() => props.openColorPicker("color_1")}
                >
                  <div className="color_1" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_2")}
                  onDoubleClick={() => props.openColorPicker("color_2")}
                >
                  <div className="color_2" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_3")}
                  onDoubleClick={() => props.openColorPicker("color_3")}
                >
                  <div className="color_3" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_4")}
                  onDoubleClick={() => props.openColorPicker("color_4")}
                >
                  <div className="color_4" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_5")}
                  onDoubleClick={() => props.openColorPicker("color_5")}
                >
                  <div className="color_5" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_6")}
                  onDoubleClick={() => props.openColorPicker("color_6")}
                >
                  <div className="color_6" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_7")}
                  onDoubleClick={() => props.openColorPicker("color_7")}
                >
                  <div className="color_7" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_8")}
                  onDoubleClick={() => props.openColorPicker("color_8")}
                >
                  <div className="color_8" />
                </li>
              </ul>

              {/* <div
              className="more-color"
                style={{
                  marginTop: "30px",
                  marginLeft: "-8px",
                }}
              >
                <img src="images/more_colors_picker.png"/>

                <ul>
                  <li
                  onClick={() => props.newColorPicker()}
                  style={{position: "absolute", bottom: "10px", right: "36px", cursor: 'pointer'}}
                  title="Select Colors"
                  >
                      <img src="images/more_colors1.svg" height= '22'/>
                  </li>
                  <li
                  onClick={() => props.canvasColorPicker()}
                  style={{position: "absolute", bottom: "10px", right: "8px", cursor: 'pointer'}}
                  title="Eyedropper"
                  >
                      <img src="images/eyedropper.svg" height= '22'/>
                  </li>
                </ul>
              </div> */}

            </div>
          </div>
        </div>
        <div className="color_box brush_size_box" 
        style={{marginTop: '15px'}}
        >
          {/* <div className="color_text">
            <img src="images/wiggle_doddle_main/brush_text.png" />
          </div> */}
          <div className="color_main_box">
            <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/brush_size_box.png" />
            <div className="brush_overlay">
              <div className="top_dot">
                <span
                  style={{
                    height: `${props.brushSize * 1.5}px`,
                    width: `${props.brushSize * 1.5}px`,
                    display: "flex",
                    backgroundColor: props.selectedBrushColor,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto"
                  }}
                />
              </div>
              <div className="bottom_sec">
                <div className="brush_board">
                  <div className="slidecontainer">
                    <input
                      type="range"
                      min="1"
                      max="100"
                      className="slider"
                      id="myRange"
                      value={props.brushSize * 5}
                      onChange={e => props.changeBrushSize(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="color_box next_prev_play_button">
          <div className="next_pre_button">
            <ul>
              <li
                title="Previous frame"
                className={
                  props.playing
                    ? "active_button"
                    : classnames({
                        active_button: props.backBtnActiveInactive()
                      })
                }
                onClick={props.playing ? () => {} : () => props.prevPressed()}
              >
                <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/back.png" />
              </li>
              <li
                title="Next frame"
                className={classnames({
                  active_button: props.nextBtnActiveInactive()
                })}
                onClick={
                  props.nextBtnActiveInactive() && !props.playing
                    ? () => props.nextPressed()
                    : () => {}
                }
              >
                <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/next.png" />
              </li>
            </ul>
          </div>
          <div className="play_pause_button">
            <div
              className="play_button_pause_sec"
              title={props.playing ? "Stop" : "Play"}
            >
              <img
                onClick={() => props.playAnimation()}
                className={classnames({
                  play_button: !props.checkIfPlayButtonIsEnabled()
                })}
                style={
                  props.playing
                    ? { display: "none" }
                    : !props.checkIfPlayButtonIsEnabled()
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
                src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/play.png"
              />

              <img
                onClick={() => props.stopAnimation()}
                className="pause_button"
                style={props.playing ? {} : { display: "none" }}
                src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/pause.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightTools;
