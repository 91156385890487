import React, { Component } from "react";
import { SketchField, Tools } from "../../sketch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hotkeys } from "react-keyboard-shortcuts";
import arrayMove from "array-move";
import {
  newKeyframe,
  updateKeyframe,
  setProjectType,
  deleteKeyframe,
  addNewKeyframeAt,
  duplicateKeyframeAt,
  undoKeyframe,
  generateVideo,
  reorderKeyframePosition,
  purgeCanvas,
  _handlePanTransform,
  removeNameAndID,
} from "../../redux/canvas/actions";
import {
  createNewProject,
  updateProject,
  getProjectDetailsById,
  loadExistingProjectFile,
} from "../../redux/user/actions";
import { hideLoader, showLoader } from "../../redux/app/actions";
import $ from "jquery";
import _ from "underscore";
import { swal } from "react-redux-sweetalert2";
import GIF from "gif.js.optimized";
import Whammy from "react-whammy";
import { saveAs } from "file-saver";
import Topbar from "./topbar";
import LeftTools from "./leftTools";
import RightTools from "./rightTools";
import BottomTools from "./bottomTools";
import ColorPickerModal from "../../components/ColorPickerModal";
import UnsavedChangesModal from "../../components/UnsavedChangesModal";
import ProjectListingModal from "../../components/ProjectListingModal";
import Message from "../../utilities/messages";

import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

const fabric = require("fabric").fabric;
var b64toBlob = require("b64-to-blob");
var JSZip = require("jszip");
const scaleFactor = 2;

var contentType = "image/png";

class FlipBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectType: "flipbook",
      selectedTool: Tools.Pencil,
      canvasHeight: "",
      canvasWidth: "",
      sketchCanvas: {},
      keyframesLimit: 3,
      activeKeyframeIndex: 0,
      colorPick: false,
      pickCanvasColor: false,
      selectedBrushColor: "#300348",
      playing: false,
      looping: false,
      traceEnabled: false,
      sendToBackEnabled: false,
      brushSize: 5,
      playingGif: "",
      animationSpeed: 2,
      selectedKeyframeInTimeline: "",
      undoDisabled: false,
      isObjectSelected: false,
      pressureOpacity: 1,
      isPasteEnabled: false,
      isGroupAllEnabled: true,
      prompt: "",
      promptTowards: "",
      doubleClickedOnColorClass: "",
      blankProjectName: "",
      randomColorState: "",
      recentColors: [],
    };

    this.interval = "";
    this._imageCanvas = new fabric.Canvas();
    this._toVideoCanvas = new fabric.Canvas();
    this._gifCanvas = new fabric.Canvas(); // to create gif with white background
    this._imgSequence = new fabric.Canvas(); // to create image sequence
    this.debouncedAnimationSpeed = this.debouncedAnimationSpeed.bind(this);
    this.debouncedAnimationSpeed = _.debounce(
      this.debouncedAnimationSpeed,
      500
    );
    this.currentProject = {
      keyframes: [],
      projectType: "",
      lastDrawnAt: [],
    };
  }

  hot_keys = {
    del: {
      priority: 1,
      handler: (event) => this.removeSelectedObject(),
    },
    backspace: {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.removeSelectedObject();
        else console.log("cannot delete");
      },
    },
    "ctrl+z": {
      priority: 1,
      handler: (event) => {
        if (this.checkIfUndoIsDisabled()) this.undoAction();
        else console.log("cannot undo more..");
      },
    },
    "meta+z": {
      priority: 1,
      handler: (event) => {
        if (this.checkIfUndoIsDisabled()) this.undoAction();
        else console.log("cannot undo more..");
      },
    },
    "ctrl+c": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.copy();
        else console.log("cannot copy as nothing is selected..");
      },
    },
    "meta+c": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.copy();
        else console.log("cannot copy as nothing is selected..");
      },
    },
    "ctrl+x": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.cut();
        else console.log("cannot cut as nothing is selected..");
      },
    },
    "meta+x": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.cut();
        else console.log("cannot cut as nothing is selected..");
      },
    },
    "ctrl+v": {
      priority: 1,
      handler: (event) => {
        if (this.state.isPasteEnabled) this.paste();
        else console.log("cannot paste as nothing is copied..");
      },
    },
    "meta+v": {
      priority: 1,
      handler: (event) => {
        if (this.state.isPasteEnabled) this.paste();
        else console.log("cannot paste as nothing is copied.....");
      },
    },
  };

  componentDidMount() {
    let context = this;
    context.interval = setInterval(() => {
      let height = document.getElementById("drawing_board").clientHeight;
      let width = document.getElementById("drawing_board").clientWidth;
      if (width > 0 && height > 0) {
        context.setState({
          canvasWidth: width,
          canvasHeight: height,
        });
        context._imageCanvas.setDimensions({
          width: width,
          height: height,
        });
        context._gifCanvas.setDimensions({
          width: width,
          height: height,
        });
        context._imgSequence.setDimensions({
          width: width,
          height: height,
        });
        context._toVideoCanvas.setDimensions({
          width: width,
          height: height,
        });
      }
    }, 1000);

    let { state } = this.props.history.location;
    if (state && state._id) {
      let projectDetailsObj = {
        _id: state._id,
        type: "flipbook",
        fromProjectVault: state.fromProjectVault,
      };
      this.purgeCanvas();
      this.props.getProjectDetailsById(projectDetailsObj, (res) => {
        if (res.success) {
          this.setUp();
        }
      });
    } else {
      this.setUp();
    }
  }

  setUp() {
    let { keyframes } = this.props.canvas.currentProject;
    if (keyframes.length === 0) {
      let newKeyframeObj = {
        keyframeIndex: 0,
      };
      this.props.newKeyframe(newKeyframeObj);
    } else {
      let undoDisabled = keyframes.some((e) => {
        if (e.keyframe) {
          return e.keyframe.objects && e.keyframe.objects.length > 0;
        } else return false;
      });

      this.setState({
        sketchCanvas: keyframes[keyframes.length - 1].keyframe,
        activeKeyframeIndex: keyframes.length - 1,
        undoDisabled: undoDisabled,
      });
    }

    let setProjectTypeObj = {
      type: "flipbook",
    };
    var historyObj = {};
    keyframes.map((item, index) => {
      if (item.keyframe) {
        historyObj = {
          history: [
            {
              version: "3.2.0",
              objects: [],
            },
            item.keyframe,
          ],
          keyframe: item.keyframe,
          index: index,
        };
      } else {
        historyObj = {
          history: [
            {
              version: "3.2.0",
              objects: [],
            },
          ],
          keyframe: item.keyframe,
          index: index,
        };
      }

      this.currentProject.keyframes.push(historyObj);
      if (
        _.isEqual(
          this.currentProject.keyframes[0].history[0],
          this.currentProject.keyframes[0].history[1]
        )
      ) {
        this.currentProject.keyframes[0].history.splice(1, 1); // delete duplicate empty history object
      }
    });

    this.props.setProjectType(setProjectTypeObj);

    setTimeout(() => {
      this.setState({
        traceEnabled: true,
      });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    let { keyframes } = this.currentProject;
    let undoDisabled;

    if (
      (keyframes[0] &&
        keyframes[0].history.length == 1 &&
        _.isEqual(keyframes[0].history[0], {
          version: "3.2.0",
          objects: [],
        })) ||
      (keyframes[0] &&
        keyframes[0].history.length == 2 &&
        _.isEqual(keyframes[0].history[1], {
          version: "3.2.0",
          objects: [],
        }))
    )
      undoDisabled = false;
    else undoDisabled = true;

    if (undoDisabled != this.state.undoDisabled)
      this.setState({
        undoDisabled: undoDisabled,
      });

    if (
      prevState.selectedTool == "circle" ||
      prevState.selectedTool == "triangle" ||
      prevState.selectedTool == "rectangle"
    ) {
      this.state.selectedTool = "select";
    }

    // let activeFrame = document.getElementsByClassName(
    // 	"flipbook_frames_divs selected"
    // )[0];
    // if (activeFrame) {
    // 	activeFrame.scrollIntoView({
    // 		behavior: "smooth",
    // 		block: "nearest"
    // 	});
    // } else {
    // 	document.getElementsByClassName("addnewFrame")[0] &&
    // 		document.getElementsByClassName("addnewFrame")[0].scrollIntoView({
    // 			behavior: "smooth",
    // 			block: "nearest"
    // 		});
    // }
  }

  // setSelectedTool(val) {
  //   this.setState({
  //     selectedTool:
  //       val === "trace" && this.state.selectedTool === "trace" ? "pencil" : val,
  //   });
  // }
  setSelectedTool(val) {
    this.setState({
      selectedTool:
        val === "trace" && this.state.selectedTool === "trace" ? "pencil" : val,
      pickCanvasColor: false,
      selectedBrushColor: this.state.pickCanvasColor
        ? "rgba(48, 3, 72, 1)"
        : this.state.selectedBrushColor,
    });

    if (val == "pan") {
      this.setState({
        draggableIdActive: true,
      });
    } else {
      this.setState({
        draggableIdActive: false,
      });
    }
    // this.sketchCanvas.deselectActiveObjects();
  }

  cut() {
    this.sketchCanvas.cut();
    this.updateSelectedKeyframe(
      "",
      this.state.activeKeyframeIndex,
      "fromCutFunction"
    );
  }

  copy() {
    this.sketchCanvas.copy();
  }

  paste() {
    let context = this;
    context.sketchCanvas.paste();
    this.updateSelectedKeyframe("", this.state.activeKeyframeIndex);
  }

  scaleToFit(type) {
    let context = this,
      zoomFactor;
    if (type === "scale") {
      context.sketchCanvas.setInitialZoomAndPosition();
    }

    context.updateSelectedKeyframe("", context.state.activeKeyframeIndex);
  }

  // set brush color to be used
  setBrushColor(className) {
    let choosen = this.state.selectedTool;
    let e = document.querySelector(`.${className}`);
    let computedStyle = getComputedStyle(e);

    let brushColorInRGB = computedStyle.getPropertyValue("background-color");
    let a = brushColorInRGB
      .substring(brushColorInRGB.indexOf("(") + 1, brushColorInRGB.indexOf(")"))
      .replace(/ /g, "");

    let b = a.split(",");
    let brushHexColor = this.rgbToHex(Number(b[0]), Number(b[1]), Number(b[2]));
    if (choosen != "eyedropper") {
      this.setState({
        selectedBrushColor: brushHexColor,
        randomColorState: Math.random(),
      });
    }
  }

  // handleChangeComplete = (color) => {
  //   // rgba(48, 3, 72, 1)

  //   let hex_color = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
  //   this.setState({
  //     selectedBrushColor: hex_color,
  //   });
  // };
  handleChangeComplete = (color) => {
    let hex_color = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;

    this.setState((prevState) => {
      const recentColors = [...prevState.recentColors];

      // Remove the color if it already exists
      const colorIndex = recentColors.indexOf(hex_color);
      if (colorIndex > -1) {
        recentColors.splice(colorIndex, 1);
      }

      // Add the new color at the beginning of the list
      recentColors.unshift(hex_color);

      // Limit the recent colors list to 5 items
      if (recentColors.length > 8) {
        recentColors.pop();
      }

      return {
        selectedBrushColor: hex_color,
        recentColors: recentColors,
      };
    });
  };

  newColorPicker() {
    this.setState({ colorPick: !this.state.colorPick });
  }
  handleClose = () => {
    this.setState({ colorPick: false });
  };

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  // handle color picker
  handleColorPicker(pickerSelectedColor) {
    let { doubleClickedOnColorClass } = this.state;
    $(`.${doubleClickedOnColorClass}`).css(
      "background-color",
      pickerSelectedColor
    );
    setTimeout(() => {
      this.setBrushColor(doubleClickedOnColorClass);
      $("[data-dismiss=modal]").trigger({ type: "click" });
    }, 300);
  }

  openColorPicker(classname) {
    $("#openColorPickerModal").trigger("click");
    setTimeout(() => {
      this.setState({
        doubleClickedOnColorClass: classname,
      });
    }, 300);
  }

  removeSelectedObject() {
    this.sketchCanvas.removeSelected();
    this.updateSelectedKeyframe(
      "",
      this.state.activeKeyframeIndex,
      "fromRemoveSelected"
    );
  }

  exportState() {
    let { keyframes, _id, name, isModified, panTransform } =
        this.props.canvas.currentProject,
      { userDetails } = this.props.user,
      { bgKeyframes } = this.props.canvas.bgTimeline;

    let saveObj = {
      data: JSON.stringify({
        keyframes,
        bgKeyframes,
        panTransform,
      }),
      // user: userDetails.id,
      // name: this.refs.projectName.value.trim(),
      category: "flipbook",
    };
    let file = new Blob([btoa(JSON.stringify(saveObj, null, 2))], {
      type: "text/plain",
    });
    saveAs(file, "export-flipbook.ish");
  }

  importState(file) {
    let result;
    try {
      // only emply in case of ish file
      document.getElementById("importImg").value = "";
      result = JSON.parse(atob(file));
      const { currentProject } = this.props.canvas;
      const { keyframes } = currentProject;
      const anyNewFrames =
        keyframes[0].keyframe && keyframes[0].keyframe.objects.length;
      let projectDetailsObj = {
        project: {
          data: result.data,
          _id: "",
          name: "",
        },
        isModified: true,
        type: result.category,
        redirect: false,
      };
      let userAlertResponse = new Promise((resolve, reject) => {
        if (result.category !== "flipbook") {
          this.props.swal({
            title: "Info!",
            text: Message.import.redirect[result.category],
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmAlert: () => {
              projectDetailsObj.redirect = result.category.toLowerCase();
              resolve(true);
            },
            cancelCallback: () => resolve(false),
          });
        } else if (currentProject._id && currentProject.name) {
          this.props.swal({
            title: "Info!",
            text: Message.import.savedProjectOverwrite,
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No, Start a New Project",
            confirmAlert: () => {
              projectDetailsObj.project._id = currentProject._id;
              projectDetailsObj.project.name = currentProject.name;
              resolve(true);
            },
            cancelCallback: () => resolve(true),
          });
        } else if (currentProject.isModified && anyNewFrames) {
          this.props.swal({
            title: "Info!",
            text: Message.import.overwrite,
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmAlert: () => resolve(true),
            cancelCallback: () => resolve(false),
          });
        } else {
          resolve(true);
        }
      });
      userAlertResponse.then((result) => {
        if (result) this.loadProjectFile(projectDetailsObj);
      });
    } catch (e) {
      this.props.swal({
        title: "Error",
        text: "Invalid File",
        type: "error",
        confirmAlert: () => false,
      });
    }
  }

  loadProjectFile(file) {
    this.purgeCanvas();
    this.props.loadExistingProjectFile(file, (err, res) => {
      if (file.redirect) {
        this.props.history.push("/" + file.redirect);
      } else if (res) {
        this.setUp();
        setTimeout(() => {
          // window.location.reload()
        }, 1000);
      }
      // Incase of redirect move this to another component
      this.props.swal({
        title: "Success",
        text: Message.import.success,
        type: "success",
        confirmAlert: () => {
          window.location.reload();
        },
      });
    });
  }

  purgeCanvasAndGoToProjectVault() {
    this.props.history.replace("/advanced-doodle", null);
    this.props.purgeCanvas();
    $("[data-dismiss=modal]").trigger({ type: "click" });
    setTimeout(() => {
      this.props.history.push("/project-vault");
    }, 500);
  }

  loadProjectFromPopup(id) {
    this.purgeCanvas();
    let projectDetailsObj = {
      _id: id,
      type: this.state.projectType,
    };
    this.props.getProjectDetailsById(projectDetailsObj, (res) => {
      if (res.success) {
        // setTimeout(() => this.setUp(), 1500);
        this.setUp();
        // not sure but isModified parameter is setting to true, it should be false after purging
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.props.swal({
          title: "Success",
          text: Message.loadFileFromPopupSuccess,
          type: "success",
          confirmAlert: () => window.location.reload(),
          //   confirmAlert: () => false
        });
      }
    });
  }
  // save video in the format specified
  async saveVideo(type) {
    let { keyframes } = this.props.canvas.currentProject,
      context = this,
      loaderShown = false;
    context.encoder = new Whammy.Video();

    context.gif = new GIF({
      workers: 2,
      repeat: 0,
      quality: 10,
      height: context.state.canvasHeight * scaleFactor,
      width: context.state.canvasWidth * scaleFactor,
      workerScript: `${window.location.origin}/gif.worker.js`,
    });

    // keyframes.map((item, index) => {
    // 	if (item.keyframe && item.keyframe.objects.length > 0) {
    // 		if (loaderShown == false) {
    // 			context.props.showLoader();
    // 			loaderShown = true;
    // 		}
    // 		context._gifCanvas.loadFromJSON(item.keyframe, () => {
    // 			context._gifCanvas.backgroundColor = "white";
    // 			var img = new Image();
    // 			img.src = context._gifCanvas.toDataURL({ multiplier: scaleFactor });
    // 			img.height = context.state.canvasHeight *scaleFactor;
    // 			img.width = context.state.canvasWidth * scaleFactor;
    // 			context.gif.addFrame(img, {
    // 				delay: 150
    // 			});
    // 		});
    // 	}
    // });

    for (const [index, item] of keyframes.entries()) {
      if (item.keyframe && item.keyframe.objects.length > 0) {
        if (loaderShown == false) {
          context.props.showLoader();
          loaderShown = true;
        }
        var tempCanvas = new fabric.StaticCanvas();
        tempCanvas.setDimensions({
          width: context.state.canvasWidth,
          height: context.state.canvasHeight,
        });
        await context.loadFromJSONInPromise(tempCanvas, index, item, 150);
      }
    }

    context.gif.on("finished", function (blob) {
      context.props.hideLoader();
      var file = new File([blob], `${+new Date()}.gif`, {
        type: `image/gif`,
        lastModified: Date.now(),
      });

      let videoObj = {
        gif: file,
        format: type,
      };
      if (type !== "gif") {
        context.props.generateVideo(videoObj, (res) => {
          saveAs(res.filename);
          context.gif = null;
        });
      } else {
        saveAs(videoObj.gif, "download.gif");
      }
      // context.props.generateVideo(videoObj, (res) => {
      //   saveAs(res.filename);
      //   context.gif = null;
      // });
    });

    setTimeout(() => {
      let { options } = context.gif;
      if (options.height != null && options.width != null) context.gif.render();
    }, 1000);
  }

  async playAnimation(repeat, playOrLoop) {
    let { keyframes } = this.props.canvas.currentProject,
      context = this,
      { animationSpeed } = this.state,
      delay,
      totalFrames = 0;

    context.props.showLoader();
    context.setState({
      playing: playOrLoop == "play" ? true : false,
      looping: playOrLoop == "loop" ? true : false,
    });
    context.gif = new GIF({
      workers: 2,
      repeat: repeat,
      quality: 10,
      workerScript: `${window.location.origin}/gif.worker.js`,
    });

    if (animationSpeed === 3) {
      delay = 50;
    } else if (animationSpeed === 2) {
      delay = 150;
    } else {
      delay = 500;
    }

    for (const [index, item] of (keyframes[keyframes.length - 1]?.keyframe
      ?.objects != null
      ? keyframes
      : keyframes.slice(0, -1)
    ).entries()) {
      var tempCanvas = new fabric.StaticCanvas();
      tempCanvas.setDimensions({
        width: context.state.canvasWidth,
        height: context.state.canvasHeight,
      });
      totalFrames = await context.loadFromJSONInPromise(
        tempCanvas,
        index,
        item,
        delay,
        totalFrames
      );
    }

    // keyframes.map((item, index) => {
    // 	if (item.keyframe && item.keyframe.objects.length > 0) {
    // 		context._gifCanvas.loadFromJSON(item.keyframe, () => {
    // 			context._gifCanvas.backgroundColor = "white";
    // 			var img = new Image();
    // 			img.src = context._gifCanvas.toDataURL();
    // 			img.height = context.state.canvasHeight;
    // 			img.width = context.state.canvasWidth;
    // 			context.gif.addFrame(img, {
    // 				delay: delay
    // 			});
    // 			totalFrames = totalFrames + 1;
    // 		});
    // 	}
    // });

    context.gif.on("finished", function (blob) {
      context.props.hideLoader();
      context.setState({
        playingGif: URL.createObjectURL(blob),
        traceEnabled: false,
      });
      if (repeat == -1) {
        // for no repeat of animation
        setTimeout(() => {
          context.stopAnimation();
        }, delay * totalFrames);
      }
    });

    setTimeout(() => {
      context.gif.render();
    }, 500);
  }

  loadFromJSONInPromise(tempCanvas, index, item, delay, totalFrames) {
    let context = this;

    return new Promise((resolve) =>
      setTimeout(() => {
        tempCanvas.loadFromJSON(item.keyframe, () => {
          tempCanvas.backgroundColor = "white";
          var img = new Image();
          img.src = tempCanvas.toDataURL({ multiplier: scaleFactor });
          img.height = context.state.canvasHeight * scaleFactor;
          img.width = context.state.canvasWidth * scaleFactor;
          context.gif.addFrame(img, {
            delay: delay,
          });
          totalFrames = totalFrames + 1;
          resolve(totalFrames);
        });
      }, 150)
    );
  }

  stopAnimation(from_ = "") {
    this.gif = null;

    if (from_ == "fromChangeAnimationSpeed") {
      // do not reset the values if coming from range slider debounced value
    } else {
      let { keyframes } = this.props.canvas.currentProject;

      this.setState({
        playing: false,
        looping: false,
        sketchCanvas: keyframes[keyframes.length - 1].keyframe,
        activeKeyframeIndex: keyframes.length - 1,
        playingGif: "",
      });
    }

    this.checkIfPasteIsEnabled(false);
  }

  saveKeyframe(activeKeyframeIndex) {
    let aKeyframe = this.sketchCanvas ? this.sketchCanvas.toJSON() : undefined;
    if (aKeyframe && aKeyframe.objects.length === 0) {
      this.props.swal({
        title: "Error!",
        text: "Draw something in order to move ahead!",
        type: "error",
        confirmAlert: () => false,
      });
      return;
    }

    let newKeyframeObj = {
      keyframeIndex: activeKeyframeIndex + 1,
      saveBlankHistoryObj: true,
    };
    this.props.newKeyframe(newKeyframeObj);
    var historyObj = {
      history: [
        {
          version: "3.2.0",
          objects: [],
        },
      ],
    };
    this.currentProject.keyframes.push(historyObj);
    this.sketchCanvas.clear();
    this.setState({
      activeKeyframeIndex: activeKeyframeIndex + 1,
      traceEnabled: true,
    });
  }

  updateSelectedKeyframe(e, activeKeyframeIndex, fromWhere = "") {
    let currentJSONCanvas = this.sketchCanvas ? this.sketchCanvas.toJSON() : {},
      { keyframes } = this.currentProject,
      context = this,
      currentBlank = _.isEqual(currentJSONCanvas, {
        version: "3.2.0",
        objects: [],
      }),
      alreadyExist =
        keyframes[activeKeyframeIndex] &&
        keyframes[activeKeyframeIndex].hasOwnProperty("history") &&
        keyframes[activeKeyframeIndex].history.some((item) => {
          return _.isEqual(item.keyframe, {
            version: "3.2.0",
            objects: [],
          });
        }),
      blankAlreadyExists;

    if (fromWhere === "fromRemoveSelected" || fromWhere === "fromCutFunction") {
      blankAlreadyExists = false;
    } else {
      blankAlreadyExists = currentBlank && alreadyExist;
    }

    if (
      (keyframes[activeKeyframeIndex] &&
        _.isEqual(
          currentJSONCanvas,
          keyframes[activeKeyframeIndex] &&
            keyframes[activeKeyframeIndex].keyframe
        )) ||
      blankAlreadyExists
    ) {
      console.log("will not update");
    } else {
      let updateKeyframeObj = {
        keyframe: context.sketchCanvas.toJSON(),
        index: activeKeyframeIndex,
        // keyframesBase64: context.sketchCanvas.toDataURL()
      };
      context._imageCanvas.loadFromJSON(currentJSONCanvas, (e) => {
        updateKeyframeObj.keyframesBase64 = context._imageCanvas.toDataURL();

        // let a = context._imageCanvas
        // 	.toDataURL()
        // 	.replace("data:image/png;base64,", "");
        // var blob = b64toBlob(a, contentType);
        // updateKeyframeObj.keyframesBase64 = URL.createObjectURL(blob);

        context.props.updateKeyframe(updateKeyframeObj);
        /******** keep history and current keyframe in component level variable *********/
        if (context.currentProject.keyframes[activeKeyframeIndex]) {
          context.currentProject.keyframes[activeKeyframeIndex].keyframe =
            context.sketchCanvas.toJSON();

          context.currentProject.lastDrawnAt = [
            ...context.currentProject.lastDrawnAt,
            {
              index: activeKeyframeIndex,
              history: context.sketchCanvas.toJSON().objects.length,
            },
          ];

          context.currentProject.keyframes[activeKeyframeIndex].history = [
            ...context.currentProject.keyframes[activeKeyframeIndex].history,
            context.sketchCanvas.toJSON(),
          ];
        }

        /******** keep history and current keyframe in component level variable *********/

        context.setState({
          // sketchCanvas: context.sketchCanvas.toJSON(),
          undoDisabled: true,
        });
      });
    }
  }

  undoFromCurrentProject(index, status = "") {
    let context = this,
      lastHistoryIndex =
        context.currentProject.keyframes[index] &&
        context.currentProject.keyframes[index].history &&
        context.currentProject.keyframes[index].history.length - 1; // last index from history object of a selected keyframe
    if (status != "keyframeDeleted") {
      context.currentProject.keyframes[index].history = [
        ...context.currentProject.keyframes[index].history.slice(0, -1),
      ]; // removed last object from history
    }
    context.currentProject.lastDrawnAt = [
      ...context.currentProject.lastDrawnAt.slice(0, -1),
    ];

    let setFromHistory =
      context.currentProject.keyframes[index] &&
      context.currentProject.keyframes[index].history[
        context.currentProject.keyframes[index].history.length - 1
      ]; // object which will be used to set the current keyframe from history

    context.currentProject.keyframes[index].keyframe = setFromHistory;
    let updateKeyframeObj = {
      keyframe: setFromHistory,
      index: index,
    };
    // this.props.updateKeyframe(updateKeyframeObj);

    context._imageCanvas.loadFromJSON(setFromHistory, (e) => {
      updateKeyframeObj.keyframesBase64 = context._imageCanvas.toDataURL();
      context.props.updateKeyframe(updateKeyframeObj);
    });
  }

  undoAction() {
    let context = this,
      { playing, looping } = this.state;

    let lastCanvasIndex =
        this.currentProject.lastDrawnAt[
          this.currentProject.lastDrawnAt.length - 1
        ].index,
      noOfItemsInHistory =
        this.currentProject.lastDrawnAt[
          this.currentProject.lastDrawnAt.length - 1
        ].history,
      lastIndexOfLastDrawnAT = this.currentProject.lastDrawnAt.length - 1,
      secondLastHistory = context.currentProject.lastDrawnAt[
        lastIndexOfLastDrawnAT - 1
      ]
        ? context.currentProject.lastDrawnAt[lastIndexOfLastDrawnAT - 1].history
        : 0,
      secondLastIndex = context.currentProject.lastDrawnAt[
        lastIndexOfLastDrawnAT - 1
      ]
        ? context.currentProject.lastDrawnAt[lastIndexOfLastDrawnAT - 1].index
        : 0;

    let { keyframes } = context.currentProject;
    if (
      (context.currentProject.keyframes[secondLastIndex].duplicated &&
        (secondLastHistory == 0 || secondLastHistory == 1)) ||
      (noOfItemsInHistory == 0 && context.currentProject.keyframes.length != 1)
    ) {
      let deleteKeyframeObj = {
        index: lastCanvasIndex,
      };
      this.undoFromCurrentProject(lastCanvasIndex, "keyframeDeleted");
      this.handleLastDrawnArrOnDeleteKeyframe(lastCanvasIndex);

      context.currentProject.keyframes.splice(lastCanvasIndex, 1); // delete keyrframe
      context.props.deleteKeyframe(deleteKeyframeObj);
      lastCanvasIndex =
        lastCanvasIndex - 1 == -1 ? lastCanvasIndex : lastCanvasIndex - 1;
      context._imgSequence.loadFromJSON(
        keyframes[lastCanvasIndex].keyframe,
        () => {
          context.setState(
            {
              sketchCanvas: context._imgSequence.toJSON(),
              activeKeyframeIndex: lastCanvasIndex,
            },
            () => {
              // context.updateSelectedKeyframe("e", lastCanvasIndex, true);
            }
          );
        }
      );
    } else {
      this.undoFromCurrentProject(lastCanvasIndex);
      context._imgSequence.loadFromJSON(
        keyframes[lastCanvasIndex].keyframe,
        () => {
          context.setState(
            {
              sketchCanvas: context._imgSequence.toJSON(),
              activeKeyframeIndex: lastCanvasIndex,
            },
            () => {
              // context.updateSelectedKeyframe("e", lastCanvasIndex, true);
            }
          );
        }
      );
    }
    if (
      keyframes.length - 1 === 0 &&
      keyframes[0].history &&
      keyframes[0].history.length === 1 &&
      keyframes[0].history[0].keyframe &&
      keyframes[0].history[0].keyframe.objects.length === 0
    )
      context.setState({
        undoDisabled: false,
      });

    if (playing || looping) {
      this.stopAnimation();
    }
  }

  nextPressed() {
    let { keyframes } = this.props.canvas.currentProject;
    let { activeKeyframeIndex } = this.state;

    if (activeKeyframeIndex === keyframes.length - 1) {
      this.saveKeyframe(activeKeyframeIndex);
    } else {
      let keyframeToBeDrawn = keyframes[activeKeyframeIndex + 1].keyframe
        ? keyframes[activeKeyframeIndex + 1].keyframe
        : "";

      if (keyframeToBeDrawn === "") {
        this.sketchCanvas.clear();
      }
      this.setState(
        {
          sketchCanvas: keyframeToBeDrawn,
          activeKeyframeIndex: activeKeyframeIndex + 1,
          // traceEnabled: !this.state.traceEnabled
        },
        () => {
          // setTimeout(() => {
          //   this.setState({
          //     traceEnabled: !this.state.traceEnabled
          //   });
          // }, 5);
        }
      );
    }
  }

  prevPressed() {
    let context = this,
      { keyframes } = context.props.canvas.currentProject,
      { activeKeyframeIndex } = context.state;
    if (activeKeyframeIndex === 0) {
      return;
    } else {
      context.setState(
        {
          sketchCanvas: "",
          activeKeyframeIndex: activeKeyframeIndex - 1,
          // traceEnabled: !context.state.traceEnabled
        },
        () => {
          context.setState({
            sketchCanvas: keyframes[activeKeyframeIndex - 1].keyframe,
          });
        }
      );
    }

    // remove last keyframe if it doesnt have any object drawn
    let localKeyframes = context.currentProject.keyframes,
      lastKeyframe = localKeyframes[localKeyframes.length - 1];
    if (
      !lastKeyframe.hasOwnProperty("keyframe") ||
      (lastKeyframe.keyframe && lastKeyframe.keyframe.objects.length === 0)
    ) {
      context.handleLastDrawnArrOnDeleteKeyframe(localKeyframes.length - 1);
      let deleteKeyframeObj = {
        index: localKeyframes.length - 1,
      };
      context.currentProject.keyframes.splice(localKeyframes.length - 1, 1);

      context.props.deleteKeyframe(deleteKeyframeObj);
    }
  }

  addNewPressedFromTimeline() {
    let { keyframes } = this.props.canvas.currentProject;
    let lastKeyframe = keyframes.length - 1;
    keyframes[lastKeyframe].index = lastKeyframe;
    if (
      keyframes[keyframes.length - 1].hasOwnProperty("keyframe") &&
      keyframes[keyframes.length - 1].keyframe.objects.length > 0
    ) {
      this.saveKeyframe(lastKeyframe);
    } else {
      this.setState({
        sketchCanvas: keyframes[keyframes.length - 1],
        activeKeyframeIndex: lastKeyframe,
      });
    }
  }

  backBtnActiveInactive() {
    let { activeKeyframeIndex } = this.state;
    if (activeKeyframeIndex === 0) return false;
    else return true;
  }

  nextBtnActiveInactive() {
    let { keyframes } = this.currentProject;
    let { activeKeyframeIndex } = this.state;
    let haveObjectsInKeyframe =
      keyframes.length > 0 &&
      keyframes[activeKeyframeIndex] &&
      keyframes[activeKeyframeIndex].hasOwnProperty("keyframe") &&
      keyframes[activeKeyframeIndex].keyframe != undefined &&
      keyframes[activeKeyframeIndex].hasOwnProperty("history") &&
      keyframes[activeKeyframeIndex].keyframe.objects &&
      keyframes[activeKeyframeIndex].keyframe.objects.length > 0;

    if (
      !haveObjectsInKeyframe &&
      keyframes.length > 0 &&
      activeKeyframeIndex === keyframes.length - 1
    )
      return false;
    else return true;
  }

  checkIfPlayButtonIsEnabled() {
    let context = this,
      { keyframes } = context.currentProject;
    if (keyframes.length > 0) {
      let count = _.countBy(keyframes, function (frame) {
        return frame.keyframe &&
          frame.keyframe.objects &&
          frame.keyframe.objects.length > 0
          ? "exists"
          : "doesntExist";
      });
      if (count.exists > 1) return true;
      else return false;
    } else return false;

    return false;
  }

  // importImg(e) {
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   reader.onloadend = () => {
  //     this.sketchCanvas.addImg(reader.result);
  //   };
  //   if (file) reader.readAsDataURL(file);
  // }

  // importImg(e) {
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   reader.onloadend = () => {
  //     if (file.type) {
  //       this.setState({ import_img: true });
  //       this.sketchCanvas.addImg(reader.result);
  //     } else {
  //       this.importState(reader.result);
  //     }
  //   };
  //   if (file && file.type) reader.readAsDataURL(file);
  //   else if (
  //     file.name.substr(file.name.lastIndexOf("\\") + 1).split(".")[1] == "ish"
  //   )
  //     reader.readAsText(file);
  // }

  importImg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      if (file.type && !file.name.endsWith(".txt")) {
        this.setState({ import_img: true });
        this.sketchCanvas.addImg(reader.result);
      } else {
        this.importState(reader.result);
      }
    };
    if (file && file.name.endsWith(".txt")) reader.readAsText(file);
    else if (file && file.type) reader.readAsDataURL(file);
    else if (
      file.name.substr(file.name.lastIndexOf("\\") + 1).split(".")[1] == "ish"
    )
      reader.readAsText(file);
  }

  newProjectOrGoToHomeOrAdvanced(type) {
    let { keyframes } = this.currentProject,
      { isModified, _id, name } = this.props.canvas.currentProject;
    let shallAsk = keyframes.some((e) => {
      if (e.keyframe) {
        return (
          e.keyframe.objects &&
          e.keyframe.objects.length > 0 &&
          e.history.length > 1
        );
      } else return false;
    });

    if (shallAsk && isModified) {
      // this.props.swal({
      // 	title: "You have unsaved changes!",
      // 	text:
      // 		"Do you want to save the current project before proceeding?",
      // 	type: "warning",
      // 	showConfirmButton: true,
      // 	showCancelButton: true,
      // 	confirmButtonText: "Yes, Save!",
      // 	cancelButtonText: "Don't Save & Proceed!",
      // 	confirmButtonColor: "green",
      // 	showCloseButton: true,
      // 	confirmAlert: () => {
      // 		if (!_id && !name) {
      // 			$("#openModal").trigger("click");
      // 			this.setState({
      // 				prompt: "alertBox",
      // 				promptTowards: type
      // 			});
      // 		} else {
      // 			this.saveOrUpdateProject("alertBox", type);
      // 		}
      // 	},
      // 	cancelCallback: () => {
      // 		this.purgeCanvas();
      // 		setTimeout(() => {
      // 			if (type === "goToHome") {
      // 				this.props.history.push("/dashboard");
      // 			} else if (type === "openIcon") {
      // 				this.purgeCanvas();
      // 				this.props.history.push("/project-vault");
      // 			} else window.location.reload();
      // 		}, 500);
      // 	}
      // });
      this.setState({
        prompt: "alertBox",
        promptTowards: type,
      });
      $("#unsavedChanges").trigger("click");
    } else {
      if (type === "goToAdvanced") {
        this.props.history.push("/advanced-doodle");
        // this.props.purgeCanvas();
        // this.props.dispatch(removeNameAndID());
      } else {
        this.purgeCanvas();
        setTimeout(() => {
          if (type === "goToHome") {
            this.props.history.push("/dashboard");
          } else if (type === "openIcon") {
            this.purgeCanvas();
            this.props.history.push("/my-projects");
          } else window.location.reload();
        }, 500);
      }
    }
  }

  changeBrushSize(e) {
    this.setState({
      brushSize: Number(e.target.value) < 2 ? 1 : Number(e.target.value) / 5,
    });
  }

  changeAnimationSpeed(e) {
    this.setState({
      animationSpeed: Number(e.target.value),
    });
    this.debouncedAnimationSpeed();
  }

  debouncedAnimationSpeed() {
    let { playing, looping } = this.state;

    if (looping) {
      this.stopAnimation("fromChangeAnimationSpeed");
      this.playAnimation(0, "loop");
    } else if (playing) {
      this.stopAnimation("fromChangeAnimationSpeed");
      this.playAnimation(-1, "play");
    }
  }

  enableDisableSendToBack(sendToBackEnabled) {
    this.setState({
      sendToBackEnabled: sendToBackEnabled,
    });
  }

  enableDisableTrace(traceEnabled) {
    this.setState({
      traceEnabled: traceEnabled,
    });
  }

  setSelectedKeyframeFromTimeline(index, calledFrom = "") {
    let { keyframes } = this.currentProject,
      context = this,
      lastDrawnAt = [],
      keyframeAdded = false,
      keyframeToBeDrawn =
        keyframes[index] && keyframes[index].keyframe
          ? keyframes[index].keyframe
          : {};

    if (index === keyframes.length && _.isEmpty(keyframeToBeDrawn)) {
      context.addKeyframeAt(index);
      keyframeAdded = false;
    }
    context.setState(
      {
        sketchCanvas: "",
        activeKeyframeIndex: index,
      },
      () => {
        context.setState({
          sketchCanvas: keyframeToBeDrawn,
        });
      }
    );

    // remove last keyframe if it doesnt have any object drawn
    let lastKeyframe = keyframes[keyframes.length - 1];

    if (keyframes.length > 1) {
      if (
        (calledFrom === "" &&
          !keyframeAdded &&
          !lastKeyframe.hasOwnProperty("keyframe")) ||
        (lastKeyframe.keyframe && lastKeyframe.keyframe.objects.length === 0)
      ) {
        context.handleLastDrawnArrOnDeleteKeyframe(keyframes.length - 1);

        let deleteKeyframeObj = {
          index: keyframes.length - 1,
        };
        context.currentProject.keyframes.splice(keyframes.length - 1, 1);
        context.props.deleteKeyframe(deleteKeyframeObj);
      }
    }

    if (
      keyframes.length == 2 &&
      keyframes[1].keyframe &&
      keyframes[1].keyframe.objects &&
      keyframes[1].keyframe.objects.length === 0
    ) {
      context.handleLastDrawnArrOnDeleteKeyframe(keyframes.length - 1);

      let deleteKeyframeObj = {
        index: keyframes.length - 1,
      };
      context.currentProject.keyframes.splice(keyframes.length - 1, 1);

      context.props.deleteKeyframe(deleteKeyframeObj);
    }

    keyframeAdded = false;
  }

  deleteKeyframeFromTimeline(index) {
    let deleteKeyframeObj = {
      index,
    };
    this.props.deleteKeyframe(deleteKeyframeObj);
    this.currentProject.keyframes.splice(index, 1);
    this.setSelectedKeyframeFromTimeline(index, "deleteKeyframe");

    this.handleLastDrawnArrOnDeleteKeyframe(index);
  }

  onPanStop(val) {
    this.props.dispatch(_handlePanTransform(val));
  }

  canvasColorPicker() {
    this.setState({
      pickCanvasColor: true,
      selectedTool: Tools.Eyedropper,
      selectedBrushColor: "#ffffff00",
    });
  }

  setCanvasColor(rgba) {
    if (rgba === "rgba(0,0,0,0)") {
      this.setState({
        pickCanvasColor: true,
        selectedTool: Tools.Eyedropper,
        selectedBrushColor: "#ffffff00",
      });
    } else {
      this.setState({
        pickCanvasColor: false,
        selectedTool: Tools.Pencil,
        selectedBrushColor: rgba,
      });
    }
  }

  handleLastDrawnArrOnDeleteKeyframe(index) {
    let lastDrawnAt = [];
    _.filter(this.currentProject.lastDrawnAt, (e) => {
      if (e.index != index) {
        lastDrawnAt = [...lastDrawnAt, e];
      }
    });
    lastDrawnAt.map((data, key) => {
      if (index < data.index) {
        data.index = data.index - 1;
      }
    });

    this.currentProject.lastDrawnAt = lastDrawnAt;
  }

  addKeyframeAt(index) {
    let addNewKeyframeObj = {
        index,
      },
      lastDrawnAt = [];
    this.props.showLoader();
    this.props.addNewKeyframeAt(addNewKeyframeObj);
    this.currentProject.keyframes.splice(index, 0, {
      history: [],
    }); // added a new keyframe on the index specified
    this.setSelectedKeyframeFromTimeline(index, "addKeyframe");

    setTimeout(() => {
      _.filter(this.currentProject.lastDrawnAt, (e) => {
        lastDrawnAt = [...lastDrawnAt, e];
      });

      let addedAtIndex = index;

      lastDrawnAt[lastDrawnAt.length - 1].index = addedAtIndex;

      lastDrawnAt.map((data, key) => {
        if (key == lastDrawnAt.length - 1) {
        } else if (addedAtIndex <= data.index) {
          data.index = data.index + 1;
        }
        if (lastDrawnAt.length - 1 === key) {
          setTimeout(() => {
            this.props.hideLoader();
          }, 500);
        }
      });
      this.currentProject.lastDrawnAt = lastDrawnAt;
    }, 500);
  }

  duplicateKeyframe(index) {
    let duplicateKeyframeObj = {
        index,
      },
      lastDrawnAt = [];
    this.props.showLoader();
    this.props.duplicateKeyframeAt(duplicateKeyframeObj);
    this.currentProject.keyframes.splice(index, 0, {
      history: [
        {
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          keyframesBase64: "",
        },
      ],
      duplicated: true,
      keyframe: this.currentProject.keyframes[index - 1].keyframe,
    }); // added a new keyframe on the index specified
    this.setSelectedKeyframeFromTimeline(index, "duplicateKeyframe");
    this.currentProject.lastDrawnAt.push({ index: index, history: 1 });

    setTimeout(() => {
      _.filter(this.currentProject.lastDrawnAt, (e) => {
        lastDrawnAt = [...lastDrawnAt, e];
      });

      let addedAtIndex = index;

      lastDrawnAt[lastDrawnAt.length - 1].index = addedAtIndex;
      lastDrawnAt[lastDrawnAt.length - 1].history = 1;

      lastDrawnAt.map((data, key) => {
        if (key == lastDrawnAt.length - 1) {
        } else if (addedAtIndex <= data.index) {
          data.index = data.index + 1;
        }

        if (lastDrawnAt.length - 1 === key) {
          setTimeout(() => {
            this.props.hideLoader();
          }, 500);
        }
      });
      this.currentProject.lastDrawnAt = lastDrawnAt;
    }, 500);
  }

  checkIfUndoIsDisabled() {
    // let { undoDisabled } = this.state;
    // if (undoDisabled) {
    // 	return true;
    // }
    // return false;
    // // return true;
    let { lastDrawnAt } = this.currentProject;

    if (
      lastDrawnAt.length == 0 ||
      (lastDrawnAt.length == 1 &&
        lastDrawnAt[0].index == 0 &&
        lastDrawnAt[0].history == 0)
    ) {
      return false;
    }

    return true;
  }

  checkIfObjectIsSelected(status) {
    let { isObjectSelected } = this.state;
    if (!status) {
      if (isObjectSelected) {
        this.setState({
          isObjectSelected: false,
        });
      }
    } else {
      if (!isObjectSelected) {
        this.setState({
          isObjectSelected: true,
        });
      }
    }
  }

  saveAsImgSequence() {
    let { keyframes } = this.props.canvas.currentProject,
      context = this,
      zip = new JSZip();

    context.props.showLoader();

    keyframes.map((item, index) => {
      if (item.keyframe && item.keyframe.objects.length > 0) {
        context._imgSequence.loadFromJSON(item.keyframe, () => {
          context._imgSequence.backgroundColor = "white";
          context._imgSequence.renderAll();
          // saveAs(context._imgSequence.toDataURL(), "download.png");
          var img = zip.folder("images");
          img.file(
            `image${index}.png`,
            context._imgSequence
              .toDataURL({ multiplier: scaleFactor })
              .replace("data:image/png;base64,", ""),
            {
              base64: true,
            }
          );
        });
      }
    });

    setTimeout(() => {
      zip
        .generateAsync({ type: "blob" })
        .then(function (content) {
          context.props.hideLoader();
          saveAs(content, "example.zip");
        })
        .catch((e) => console.log(e, "e"));
    }, 3000);
  }

  setPressureOpacity(e) {
    this.setState({
      pressureOpacity: Number(e.target.value),
    });
  }

  checkIfPasteIsEnabled(status) {
    this.setState({
      isPasteEnabled: status,
    });
  }

  checkIfGroupAllEnabled(status) {
    this.setState({
      isGroupAllEnabled: status,
    });
  }

  onSortEnd(oldIndex, newIndex) {
    if (oldIndex != newIndex) {
      let sortObj = {
          oldIndex,
          newIndex,
          selectedSpace: "foreground",
        },
        lastDrawnAt = [],
        reorderedKeyframes = arrayMove(
          this.currentProject.keyframes,
          oldIndex,
          newIndex
        );
      this.currentProject.keyframes = reorderedKeyframes;
      this.props.reorderKeyframePosition(sortObj);
      this.setSelectedKeyframeFromTimeline(newIndex, "onSortEnd");

      this.props.showLoader();

      setTimeout(() => {
        lastDrawnAt = [...this.currentProject.lastDrawnAt];

        if (oldIndex > newIndex) {
          lastDrawnAt = _.filter(lastDrawnAt, (e) => {
            if (e.index == oldIndex) {
              e.index = newIndex;
              return e;
            } else if (e.index >= newIndex && e.index <= oldIndex) {
              e.index = e.index + 1;
              return e;
            } else return e;
          });
        } else if (oldIndex < newIndex) {
          lastDrawnAt = _.filter(lastDrawnAt, (e) => {
            if (e.index == oldIndex) {
              e.index = newIndex;
              return e;
            } else if (e.index <= newIndex && e.index >= oldIndex) {
              e.index = e.index - 1;
              return e;
            } else return e;
          });
        }
        this.currentProject.lastDrawnAt = lastDrawnAt;
        this.props.hideLoader();
      }, 500);
    }
  }

  saveFromModal(e) {
    e.preventDefault();

    let { prompt, promptTowards } = this.state;
    if (this.refs.projectName.value.trim() !== "") {
      // $("[data-dismiss=modal]").trigger({ type: "click" });
      const modalElement = document.getElementById('save_modal');
      modalElement.classList.remove('in');
      modalElement.setAttribute('aria-hidden', 'true');
      modalElement.style.display = 'none';
      document.querySelector('.modal-backdrop').remove();
      if (prompt === "alertBox") {
        this.saveOrUpdateProject("alertBox", promptTowards);
      } else {
        this.saveOrUpdateProject();
      }
    } else {
      this.setState({
        blankProjectName: "* This is a required field.",
      });
    }
  }

  saveOrUpdateProjectFromSaveIcon() {
    let shallAsk = this.currentProject.keyframes.some((e) => {
        if (e.keyframe) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      }),
      { _id, name, isModified } = this.props.canvas.currentProject;
    if (shallAsk && isModified) {
      if (_id && name) {
        this.saveOrUpdateProject();
      } else {
        $("#openModal").trigger("click");
      }
    }
  }

  saveOrUpdateProject(calledFrom = "", type = "") {
    let shallAsk = this.currentProject.keyframes.some((e) => {
        if (e.keyframe) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      }),
      {
        keyframes,
        _id,
        name,
        isModified,
        projectType: category,
      } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      { userDetails } = this.props.user;
    if (shallAsk && isModified) {
      if (_id && name) {
        let updateObj = {
          _id,
          data: JSON.stringify({ keyframes, bgKeyframes }),
          category,
        };
        this.props.updateProject(updateObj, (res) => {
          if (res.success) {
            if (calledFrom == "alertBox") {
              if (type !== "goToAdvanced") this.purgeCanvas();
              setTimeout(() => {
                if (type === "goToHome") {
                  this.props.history.push("/dashboard");
                } else if (type === "goToAdvanced") {
                  this.props.history.push("/advanced-doodle");
                  this.props.dispatch(removeNameAndID());
                } else if (type === "openIcon") {
                  this.purgeCanvas();
                  this.props.history.push("/project-vault");
                } else window.location.reload();
              }, 500);
            }
          }
        });
      } else {
        let saveObj = {
          data: JSON.stringify({ keyframes, bgKeyframes }),
          user: userDetails.id,
          name: this.refs.projectName.value.trim(),
          category: "flipbook",
        };

        this.props.createNewProject(saveObj, (res) => {
          if (res.success) {
            if (calledFrom == "alertBox") {
              if (type !== "goToAdvanced") this.purgeCanvas();
              setTimeout(() => {
                if (type === "goToHome") {
                  this.props.history.push("/dashboard");
                } else if (type === "goToAdvanced") {
                  this.props.history.push("/advanced-doodle");
                  this.props.dispatch(removeNameAndID());
                } else if (type === "openIcon") {
                  this.purgeCanvas();
                  this.props.history.push("/project-vault");
                } else window.location.reload();
              }, 500);
            }
          }
        });
        this.refs.projectName.value = "";
      }
    }
  }

  purgeCanvas() {
    this.props.history.replace("/flipbook", null);
    this.props.purgeCanvas();
  }

  openProjectsVault() {
    this.newProjectOrGoToHomeOrAdvanced("openIcon");
  }

  saveBtnInUnsavedChangesModal() {
    let { promptTowards } = this.state;
    let { isModified, _id, name } = this.props.canvas.currentProject;
    $("[data-dismiss=modal]").trigger({ type: "click" });
    if (!_id && !name) {
      setTimeout(() => {
        $("#openModal").trigger("click");
      }, 200);
    } else {
      this.saveOrUpdateProject("alertBox", promptTowards);
    }
  }

  noAndProceedBtn() {
    $("[data-dismiss=modal]").trigger({ type: "click" });
    let { promptTowards } = this.state;
    if (promptTowards === "goToAdvanced") {
      this.props.history.push("/advanced-doodle");
      // this.props.purgeCanvas();
      // this.props.dispatch(removeNameAndID());
    } else {
      this.purgeCanvas();

      if (promptTowards === "goToHome") {
        this.props.history.push("/dashboard");
      } else if (promptTowards === "openIcon") {
        this.purgeCanvas();
        this.props.history.push("/project-vault");
      } else window.location.reload();
    }
  }

  render() {
    const styles = reactCSS({
      default: {
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    let {
      selectedTool,
      activeKeyframeIndex,
      playing,
      looping,
      traceEnabled,
      sendToBackEnabled,
      brushSize,
      selectedBrushColor,
      animationSpeed,
      selectedKeyframeInTimeline,
      canvasWidth,
      canvasHeight,
      isObjectSelected,
      pressureOpacity,
      isPasteEnabled,
      isGroupAllEnabled,
    } = this.state;
    let { keyframes } = this.props.canvas.currentProject;
    if (canvasHeight > 0 && canvasWidth > 0) {
      clearInterval(this.interval);
    }
    return (
      <div className="body_wrap">
        <div className="conta_iner">
          <div className="animation_ish_box advanced_outer_box wiggle_outer_div flipbook_outer_box">
            <Topbar
              newProjectOrGoToHomeOrAdvanced={this.newProjectOrGoToHomeOrAdvanced.bind(
                this
              )}
              saveVideo={this.saveVideo.bind(this)}
              loadProjectFile={this.loadProjectFile.bind(this)}
              saveAsImgSequence={this.saveAsImgSequence.bind(this)}
              exportState={this.exportState.bind(this)}
              undoAction={this.undoAction.bind(this)}
              removeSelectedObject={this.removeSelectedObject.bind(this)}
              enableDisableSendToBack={this.enableDisableSendToBack.bind(this)}
              sendToBackEnabled={sendToBackEnabled}
              enableDisableTrace={this.enableDisableTrace.bind(this)}
              checkIfUndoIsDisabled={this.checkIfUndoIsDisabled.bind(this)}
              checkIfPasteIsEnabled={this.checkIfPasteIsEnabled.bind(this)}
              isPasteEnabled={isPasteEnabled}
              isGroupAllEnabled={isGroupAllEnabled}
              isObjectSelected={isObjectSelected}
              traceEnabled={traceEnabled}
              setPressureOpacity={this.setPressureOpacity.bind(this)}
              pressureOpacity={pressureOpacity}
              selectedBrushColor={selectedBrushColor}
              importImg={this.importImg.bind(this)}
              copy={this.copy.bind(this)}
              paste={this.paste.bind(this)}
              scaleToFit={this.scaleToFit.bind(this)}
              cut={this.cut.bind(this)}
              saveOrUpdateProjectFromSaveIcon={this.saveOrUpdateProjectFromSaveIcon.bind(
                this
              )}
              history={this.props.history}
              selectedTool={selectedTool}
              openProjectsVault={this.openProjectsVault.bind(this)}
            />
            <div className="tabLandscapeLayout">
              <div className="wiggle_doddle_main_mid_sec flipbook_mian_sec row">
                <LeftTools
                  selectedTool={selectedTool}
                  checkIfGroupAllEnabled={this.checkIfGroupAllEnabled.bind(
                    this
                  )}
                  setSelectedTool={this.setSelectedTool.bind(this)}
                />
                <div className="wiggle_doddle_main_mid_center col-sm-8">
                  <div className="doddle_board_and_text">
                    <div className="bg_img">
                      <div
                        className="make_drawing_board"
                        style={
                          playing
                            ? {
                                pointerEvents: "none",
                                userSelect: "none",
                              }
                            : {}
                        }
                      >
                        <div className="drawing_board" id="drawing_board">
                          {this.state.canvasHeight &&
                            !this.state.playingGif && (
                              <SketchField
                                ref={(sketchDraw) =>
                                  (this.sketchCanvas = sketchDraw)
                                }
                                height={this.state.canvasHeight}
                                width={this.state.canvasWidth}
                                tool={this.state.selectedTool}
                                traceEnabled={this.state.traceEnabled}
                                sendToBackEnabled={sendToBackEnabled}
                                lineColor={this.state.selectedBrushColor}
                                lineWidth={this.state.brushSize}
                                value={this.state.sketchCanvas}
                                rgbToHex={this.rgbToHex.bind(this)}
                                hexToRgb={this.hexToRgb.bind(this)}
                                onPanStop={this.onPanStop.bind(this)}
                                setCanvasColor={this.setCanvasColor.bind(this)}
                                pickCanvasColor={this.state.pickCanvasColor}
                                keyframes={
                                  this.props.canvas.currentProject.keyframes
                                }
                                bgKeyframes={
                                  this.props.canvas.bgTimeline.bgKeyframes
                                }
                                activeKeyframeIndex={activeKeyframeIndex}
                                checkIfObjectIsSelected={this.checkIfObjectIsSelected.bind(
                                  this
                                )}
                                checkIfPasteIsEnabled={this.checkIfPasteIsEnabled.bind(
                                  this
                                )}
                                opacity={pressureOpacity}
                                randomColorState={this.state.randomColorState}
                                onChange={
                                  playing
                                    ? (e) => {}
                                    : (e) =>
                                        this.updateSelectedKeyframe(
                                          e,
                                          activeKeyframeIndex
                                        )
                                }
                              />
                            )}

                          {(playing || looping) && (
                            <img
                              src={this.state.playingGif}
                              onChange={(e) => console.log(e)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  <div>
                    {this.state.colorPick ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker
                          color={this.state.selectedBrushColor}
                          onChangeComplete={this.handleChangeComplete}
                          presetColors={this.state.recentColors}
                        />
                      </div>
                    ) : null}
                  </div>
                }

                <RightTools
                  setBrushColor={this.setBrushColor.bind(this)}
                  openColorPicker={this.openColorPicker.bind(this)}
                  brushSize={brushSize}
                  selectedBrushColor={selectedBrushColor}
                  changeBrushSize={this.changeBrushSize.bind(this)}
                  changeAnimationSpeed={this.changeAnimationSpeed.bind(this)}
                  animationSpeed={animationSpeed}
                  backBtnActiveInactive={this.backBtnActiveInactive.bind(this)}
                  prevPressed={this.prevPressed.bind(this)}
                  nextPressed={this.nextPressed.bind(this)}
                  playAnimation={this.playAnimation.bind(this)}
                  keyframes={keyframes}
                  activeKeyframeIndex={activeKeyframeIndex}
                  stopAnimation={this.stopAnimation.bind(this)}
                  nextBtnActiveInactive={this.nextBtnActiveInactive.bind(this)}
                  canvasColorPicker={this.canvasColorPicker.bind(this)}
                  checkIfPlayButtonIsEnabled={this.checkIfPlayButtonIsEnabled.bind(
                    this
                  )}
                  newColorPicker={this.newColorPicker.bind(this)}
                  playing={playing}
                  looping={looping}
                />
              </div>
            </div>
            <BottomTools
              isLoading={this.props.isLoading}
              keyframes={keyframes}
              currentProject={this.currentProject.keyframes}
              activeKeyframeIndex={activeKeyframeIndex}
              selectedKeyframeInTimeline={selectedKeyframeInTimeline}
              setSelectedKeyframeFromTimeline={this.setSelectedKeyframeFromTimeline.bind(
                this
              )}
              addNewPressedFromTimeline={this.addNewPressedFromTimeline.bind(
                this
              )}
              deleteKeyframeFromTimeline={this.deleteKeyframeFromTimeline.bind(
                this
              )}
              addKeyframeAt={this.addKeyframeAt.bind(this)}
              nextBtnActiveInactive={this.nextBtnActiveInactive.bind(this)}
              playing={playing}
              looping={looping}
              duplicateKeyframe={this.duplicateKeyframe.bind(this)}
              canvasHeight={this.state.canvasHeight}
              canvasWidth={this.state.canvasWidth}
              prevPressed={this.prevPressed.bind(this)}
              nextPressed={this.nextPressed.bind(this)}
              onSortEnd={this.onSortEnd.bind(this)}
            />
          </div>
        </div>
        <button
          id="openModal"
          type="button"
          data-toggle="modal"
          data-target="#save_modal"
          style={{ display: "none" }}
        >
          Open Modal
        </button>
        <div className="modal fade" id="save_modal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-header">
              <button type="button" className="close" >
                &times;
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-body">
                <div className="havnot_picker_popup">
                  <div className="have_not_box">
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/havent_saved_background.png" />
                    <div className="project_file_save">
                      <h2>Project Name</h2>
                    </div>
                    <div className="saved_save_popup">
                      <div className="type_name">
                        <img src="https://d3rc7k2n78msdl.cloudfront.net/input_background.png" alt="image" />
                        <div className="typing_input_name">
                          <input
                            type="text"
                            name="name"
                            placeholder="File Name"
                            ref="projectName"
                            autofocus
                            onChange={() =>
                              this.setState({
                                blankProjectName: "",
                              })
                            }
                          />
                          <p className="errorMsg blankProjectName">
                            {this.state.blankProjectName}
                          </p>
                        </div>
                      </div>
                      <div className="yes_save">
                        <img
                          src="https://d3rc7k2n78msdl.cloudfront.net/yes_sve.png"
                          onClick={(e) => this.saveFromModal(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColorPickerModal
          handleColorPicker={this.handleColorPicker.bind(this)}
          selectedBrushColor={selectedBrushColor}
        />
        <UnsavedChangesModal
          noAndProceedBtn={this.noAndProceedBtn.bind(this)}
          saveBtnInUnsavedChangesModal={this.saveBtnInUnsavedChangesModal.bind(
            this
          )}
        />
        <ProjectListingModal
          ref={this.projectListingComponentRef}
          category={this.state.projectType}
          loadProjectFromPopup={this.loadProjectFromPopup.bind(this)}
          purgeCanvasAndGoToProjectVault={this.purgeCanvasAndGoToProjectVault.bind(
            this
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  canvas: state.canvas,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  newKeyframe: bindActionCreators(newKeyframe, dispatch),
  addNewKeyframeAt: bindActionCreators(addNewKeyframeAt, dispatch),
  updateKeyframe: bindActionCreators(updateKeyframe, dispatch),
  deleteKeyframe: bindActionCreators(deleteKeyframe, dispatch),
  setProjectType: bindActionCreators(setProjectType, dispatch),
  swal: bindActionCreators(swal.showAlert, dispatch),
  loadExistingProjectFile: bindActionCreators(
    loadExistingProjectFile,
    dispatch
  ),
  hideLoader: bindActionCreators(hideLoader, dispatch),
  showLoader: bindActionCreators(showLoader, dispatch),
  duplicateKeyframeAt: bindActionCreators(duplicateKeyframeAt, dispatch),
  undoKeyframe: bindActionCreators(undoKeyframe, dispatch),
  generateVideo: bindActionCreators(generateVideo, dispatch),
  reorderKeyframePosition: bindActionCreators(
    reorderKeyframePosition,
    dispatch
  ),
  createNewProject: bindActionCreators(createNewProject, dispatch),
  updateProject: bindActionCreators(updateProject, dispatch),
  getProjectDetailsById: bindActionCreators(getProjectDetailsById, dispatch),
  purgeCanvas: bindActionCreators(purgeCanvas, dispatch),
});

let flipBook = hotkeys(FlipBook);

export default connect(mapStateToProps, mapDispatchToProps)(flipBook);
