import React, { useState, useEffect } from "react";
import ShapeSelectorModal from "../../components/ShapeSelectorModal";

const LeftTools = (props) => {
  const [showShapeModal, setShowShapeModal] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  
  const handleShapeSelection = (shape) => {
    props.setSelectedTool(shape);
    setSelectedShape(shape);
    setShowShapeModal(false);
  };

  return (
    <div className="wiggle_doddle_main_mid_left col-sm-2">
      <div className="tool_bar">
        <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/tools-outline-long-vertical.svg" />
        <div className="overlay_classes">
          <ul>
            <li
              className={props.selectedTool === "select" ? "active_tools" : ""}
              onClick={() => {
                props.setSelectedTool("select");
              }}
              title="Select"
            >
              <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/pointer.png" />
            </li>
            <li
              className={props.selectedTool === "pencil" ? "active_tools" : ""}
              title="Brush"
            >
              <img
                src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/brush.png"
                onClick={() => {
                  props.setSelectedTool("pencil");
                }}
              />
            </li>
            <li
              className={
                props.selectedTool === "paintBucket" ? "active_tools" : ""
              }
              title="Paint"
            >
              <img
                src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/color.png"
                onClick={() => {
                  props.setSelectedTool("paintBucket");
                }}
              />
            </li>
            <li
              className={props.selectedTool === "eraser" ? "active_tools" : ""}
              title="Eraser"
            >
              <img
                src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/rubber.png"
                onClick={() => {
                  props.setSelectedTool("eraser");
                }}
              />
            </li>
            <li
              id="flip_Shapes"
              title="Shapes"
              className={
                props.selectedTool === "circle" ||
                props.selectedTool === "rectangle" ||
                props.selectedTool === "line" ||
                props.selectedTool === "triangle"
                  ? "active_tools"
                  : ""
              }
              onClick={() => setShowShapeModal(true)}
            >
              <img
                src="https://d3rc7k2n78msdl.cloudfront.net/advanced/shapes.png"
                onClick={() => {
                  // props.setSelectedTool("pan");
                }}
                alt="Shapes"
              />
            </li>
          </ul>
        </div>
      </div>
      <ShapeSelectorModal
        showShapeModal={showShapeModal}
        setShowShapeModal={setShowShapeModal}
        handleShapeSelection={handleShapeSelection}
        selectedShape={selectedShape}
      />
      {/*<div className="inspire_me_image">
        <img
          className="normal_show"
          src="images/wiggle_doddle_main/inspireme_large.png"
        />
        <img
          style={{ display: "none" }}
          className="hover_show"
          src="images/wiggle_doddle_main/inspireme_large_over.gif"
        />
        <img
          style={{ display: "none" }}
          className="click_show"
          src="images/wiggle_doddle_main/inspireme_large_click.png"
        />
      </div>*/}
    </div>
  );
};

export default LeftTools;
