/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

class Transform extends FabricCanvasTool {
  configureCanvas(props) {
    let data = [];
    var i = 1;
    let isDrawingMode = true;
    let isTranform = true;


	
    /* var canvas = new fabric.Canvas("canvas", {
      isDrawingMode: true,
      selection: true,
      freeDrawingBrush: new fabric.PencilBrush({ decimate: 8 }),
    }); */

    this._canvas.isDrawingMode = true;
    this._canvas.selection = true;
		this._canvas.freeDrawingBrush = new fabric.PencilBrush(this._canvas);
		// this._canvas.freeDrawingBrush.width = props.lineWidth;
		let canvas = this._canvas;

    /* function update() {
      const activeItem = canvas.getActiveObject();
      if (activeItem) {
        activeItem.set({
          top: data[i].y,
          left: data[i].x,
        });
        i++;

        // render all shapes
        canvas.renderAll();
        // queue next update
        if (i < data.length) {
          //  if the counter < 10, call the loop function
          requestAnimationFrame(update);
        } else {
          i = 1;
          const activeItem = canvas.getActiveObject();
          activeItem.set({
            cornerSize: 12,
            hasBorders: true,
            transparentCorners: false,
          });
        }
      }
    }; */

    /* function startAnimationTransform() {
      const activeItem = canvas.getActiveObject();

      if (activeItem) {
        activeItem.set({
          cornerSize: 0,
          hasBorders: false,
          transparentCorners: false
        });
        requestAnimationFrame(update);

      }
    }; */

    // after drawing done
    this._canvas.on("before:path:created", function (opt) {
      var pathInfo = fabric.util.getPathSegmentsInfo(opt.path.path);
      canvas._objects = canvas._objects.filter((i) => !i.isTransform);
      if (isTranform) {
        data = pathInfo;
        canvas.set({
          transformPath: pathInfo,
        });
      }
    });

	  this._canvas.on("path:created", function (opt) {
      canvas.remove(opt);
      if (isTranform) {
        // canvas._objects = canvas._objects.filter((i) => !i.isTransform);
        // canvas._objects = canvas._objects.filter((i) => i.visible);
        opt.path.set({ strokeWidth: 0, opacity: 1, selectable: false, isTransform: true, visible: false, borderColor: 'red', stroke: 'red' });
        canvas.set({
          isDrawingMode: false,
        });
        isTranform = false;
      }
      // opt.path.set({ strokeWidth: 1, selectable: false})

    });


    function changeDrawMode() {
      isDrawingMode = !isDrawingMode;

      canvas.set({
        isDrawingMode: isDrawingMode,
      });
    }

    // document.getElementById("stl").addEventListener("click", function () {
    //   changeDrawMode();
    // });

    // document.getElementById("trans").addEventListener("click", function () {
    //   canvas.set({
    //     isDrawingMode: true,
    //   });
    //   isTranform = true;
    // });

    // document.getElementById("play").addEventListener("click", function () {
    //   const activeItem = canvas.getActiveObject();

    //   if (activeItem) {
    //     activeItem.set({
    //       cornerSize: 0,
    //       hasBorders: false,
    //       transparentCorners: false,
    //     });
    //     requestAnimationFrame(update);
    //   }
    // });
  }
}

export default Transform;