import React from "react";
import Header from "./Header";
import DashboardHeader from "./DashboardHeader";

// Front Layout goes here
export const frontLayout = props => {
  let { location, history, isLogin } = props.children.props;
  return (
    <div>
      <Header history={history} isLogin={isLogin} />
      {props.children}
    </div>
  );
};

// Dashboard Layout goes here
export const dashboardLayout = props => {
  let { location, history } = props.children.props;
  return (
    <div>
      <DashboardHeader history={history} />
      {props.children}
    </div>
  );
};
