/* 
      *                                                            *
    *****                                                        *****                             
      *                                                            *
        ==========================================================
        ==========                                      ==========
        ==========        Page for Not-Found            ==========
        ==========                                      ==========
        ==========================================================
      *                                                            *
    *****                                                        *****   
      *                                                            *
*/

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Link to="/">
      <img src="/assets/imgs/not-found.png" width="100%" />
    </Link>
  );
};

export default NotFound;
