import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

class Pencil extends FabricCanvasTool {
	configureCanvas(props) {
		this._canvas.isDrawingMode = true;

		this._canvas.freeDrawingBrush = new fabric.PencilBrush(this._canvas);
		this._canvas.freeDrawingBrush.width = props.lineWidth;
		// let hex = props.lineColor.replace("#", "");
		// let arrBuff = new ArrayBuffer(4);
		// let vw = new DataView(arrBuff);
		// vw.setUint32(0, parseInt(hex, 16), false);
		// let arrByte = new Uint8Array(arrBuff);
		// let color = `rgba(${arrByte[1]},${arrByte[2]},${arrByte[3]},${props.opacity})`;
		// this._canvas.freeDrawingBrush.color = color;
		
		
		let color;

		if(props.lineColor.includes('rgba')){
			let values = props.lineColor
				.replace(/rgba?\(/, "")
				.replace(/\)/, "")
				.replace(/[\s+]/g, "")
				.split(",");
			
			if(values[3] == 1){
				color = `rgba(${values[0]},${values[1]},${values[2]},${props.opacity})`;
			}
			else {
				color = props.lineColor;
			}
		}
		else {
			let rgb_color = props.hexToRgb(props.lineColor);
	
			if(rgb_color){
				color = `rgba(${rgb_color.r},${rgb_color.g},${rgb_color.b},${props.opacity})`;
			}
			else {
				color = props.lineColor;
			}	
		}

		
		// this._canvas.freeDrawingBrush.color = props.lineColor;
		this._canvas.freeDrawingBrush.color = color;
		this._canvas.discardActiveObject();
		this._canvas.requestRenderAll();

		// this._canvas.on("path:created", function(opt) {
		// 	opt.path.globalCompositeOperation = props.selectedSpace;
		// });
	}

	doMouseDown() {}
}

export default Pencil;
