import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import compressor from "./compressor";
import user from "./user";
import app from "./app";
import canvas from "./canvas";
import { reducers as swalReducers } from "react-redux-sweetalert2";
import { routerReducer } from "react-router-redux";

// user state to be stored in storage, but lets not persist someEmphemeralKey
const storeConfig = {
	key: "primary",
	storage: storage,
	blacklist: ["app", "swal"],
	transforms: [compressor]
};

// Combine all the reducers into one
const AnimationIsh = persistCombineReducers(storeConfig, {
	user,
	app,
	canvas,
	routerReducer,
	swal: swalReducers
});

export default AnimationIsh;
