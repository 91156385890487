/*************** Rest api methods class ***********/
import axios from "axios";

//  const baseUrl = "http://localhost:7000/v1";
// const baseUrl = 'http://localhost/api/v1';
const baseUrl = "https://animationish.com/api/v1";
// const baseUrl = "http://ec2-18-212-204-56.compute-1.amazonaws.com/api/v1";

/* no more use */

// const baseUrl = "http://ec2-18-212-204-56.compute-1.amazonaws.com:7000/v1";
// const baseUrl = "https://animationish.com:7000/v1"; 
// const baseUrl = "https://fablevisionprod.netsmartz.us:8000/v1";
// const baseUrl = "https://fablevision.netsmartz.us:7000/v1";
// https://animationish.com:7000
// https://fablevisionprod.netsmartz.us:7000
// https://fablevision.netsmartz.us:7000

/* no more use */

class RestClient {
  
  /*************** POST Method ***********/
  static post(url, params) {
    return new Promise(function(resolve, reject) {
      fetch(`${baseUrl}${url}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      })
        .then(response => {
          return response.json();
        })
        .then(responseText => {
          resolve(responseText);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static login(url, params) {
    return new Promise(function(resolve, reject) {
      let body = new FormData();
      body.append("username", params.username);
      body.append("password", params.password);
      body.append("sku", params.sku);

      fetch(`${url}`, {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: body
      })
        .then(response => {
          return response.json();
        })
        .then(responseText => {
          resolve(responseText);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /*************** PUT Method ***********/
  static put(url, params) {
    return new Promise(function(resolve, reject) {
      fetch(`${baseUrl}${url}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      })
        .then(response => {
          return response.json();
        })
        .then(responseText => {
          resolve(responseText);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  /*************** DELETE Method ***********/
  static delete(url, params) {
    return new Promise(function(resolve, reject) {
      fetch(`${baseUrl}${url}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          return response.json();
        })
        .then(responseText => {
          resolve(responseText);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /*************** GET Method ***********/
  static get(url, params) {
    return new Promise(function(resolve, reject) {
      fetch(`${baseUrl}${url}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          return response.json();
        })
        .then(responseText => {
          resolve(responseText);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /*************** Form-Data Method ***********/
  static formData(url, params) {
    return new Promise(function(resolve, reject) {
      let { gif, format } = params;
      let body = new FormData();
      body.append("gif", gif);
      body.append("format", format);
      fetch(`${baseUrl}${url}`, {
        method: "POST",
        body: body
      })
        .then(response => {
          return response.json();
        })
        .then(responseText => {
          resolve(responseText);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default RestClient;