import RestClient from "../../utilities/RestClient";
import * as TYPE from "./types";
import * as CANVAS_TYPE from "../canvas/types";
import axios from "axios";


import Messages from "../../utilities/messages";
import * as appActions from "../app/actions";
import { push } from "react-router-redux";
import { swal } from "react-redux-sweetalert2";
import {API_URL} from '../../constants'

//Action Creator For Reducers
export const loginSuccess = data => ({ type: TYPE.LOGIN_SUCCESS, data: data });
export const newProjectCreated = data => ({
  type: CANVAS_TYPE.NEW_PROJECT_CREATED,
  data: data
});

export const projectDetailsFetched = data => ({
  type: CANVAS_TYPE.PROJECT_DETAILS_FETCHED,
  data: data
});

export const projectUpdated = () => ({
  type: CANVAS_TYPE.PROJECT_UPDATED
});

export const _logOut = () => ({
  type: TYPE.LOGOUT
});

// Thunk Action Creators For Api //

// action creator for login
export const attemptLogin = (params, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.login(
      "https://products.fablevisionlearning.com/validateuser.php",
      params
    )
      .then(result => {
        if (result.errorField) {
          dispatch(
            swal.showAlert({
              title: "Error!",
              text: result.errorField,
              type: "error",
              confirmAlert: () => false
            })
          );
        } else {
          dispatch(loginSuccess(result));
          dispatch(appActions.hideLoader());
          dispatch(push("/dashboard"));
        }
      })
      .catch(e => {
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: Messages.wentWrong,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};
// changes done for token redirection case

export const attemptLoginfromToken = (token, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    // let token = (new URLSearchParams(window.location.search)).get("token");
    localStorage.setItem("redirectToken",token);
    // let token =  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2MzM2MTI5NTQsImlzcyI6ImZ2Z2FtZXMiLCJhdWQiOlsiYW5pbWF0aW9uLWlzaCJdLCJzdWIiOiI5ODMzOSIsImZuIjoiTmV0c21hcnR6IiwibG4iOiJUZXN0T25lIiwicnQiOiJhNTk5OTY2NS0xZTJlLTQ2MDMtYWFjNS0yZGFlN2ZhZmNjNjQiLCJmdWxsIjoiTmV0c21hcnR6IFRlc3RPbmUiLCJlbSI6ImZ2Z2FtZXMtdXNlci02MTM5MTdkZTI5Yjc4QGRldi5mYWJsZXZpc2lvbmdhbWVzLmNvbSIsImdpZCI6ImFuaW1hdGlvbi1pc2giLCJjaWQiOjEzNCwiaWF0IjoxNjMzNjkyNjEzLCJleHAiOjE2Mzg4NzY2MTN9.uYgu_LLNO_WeUMubsgwWUbbs4InEnvuz2sJIG8oyICk'
    const AuthStr = 'Bearer '+token; 
axios.get('https://animationish.com/api/v1/forAuth', { headers: { Authorization: AuthStr } })
      .then(result => {
        if (result.errorField) {
          dispatch(
            swal.showAlert({
              title: "Error!",
              text: result.errorField,
              type: "error",
              confirmAlert: () => false
            })
          );
        } else {
          dispatch(loginSuccess(result.data));
          dispatch(appActions.hideLoader());
          dispatch(push("/dashboard"));
        }
      })
      .catch(e => {
        document.cookie = 'token=;'
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: Messages.wentWrong,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

export const logOutUser = (params, cb) => {
  let tokenLocal = localStorage.getItem('redirectToken');
  return dispatch => {
    dispatch(_logOut());
    if(tokenLocal){
      window.location = "https://www.fablevisiongames.com/homepage"
      // history.push("https://dev.fablevisiongames.com/homepage");
      localStorage.clear('redirectToken');
    }
    dispatch(push("/"));
  };
};

// action creator to create new project
export const createNewProject = (params, cb) => {
  return (dispatch, getState) => {
    dispatch(appActions.showLoader());
    RestClient.post("/project", params)
      .then(result => {
        if (result.status == 200) {
          dispatch(newProjectCreated(result.project));
          dispatch(appActions.hideLoader());
          dispatch(
            swal.showAlert({
              title: "Success!",
              text: "Project Successfully Saved!",
              type: "success",
              confirmAlert: () => false
            })
          );
          cb({ success: true, result: result });
        } else if (result.status == 409) {
          dispatch(appActions.hideLoader());
          dispatch(
            swal.showAlert({
              title: "Error!",
              text: result.message,
              type: "error",
              confirmAlert: () => false
            })
          );
          return;
        }
      })
      .catch(e => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: e,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

// action creator to create new project
export const updateProject = (params, cb) => {
  let _id = params._id;
  delete params._id;
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.put(`/project/${_id}`, params)
      .then(result => {
        dispatch(projectUpdated());
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Success!",
            text: "Project Successfully Updated!",
            type: "success",
            confirmAlert: () => false
          })
        );
        cb({ success: true, result: result });
        // dispatch(logUserOut());
        // dispatch(push("/"));
        // return;
      })
      .catch(e => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: e,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

// action creator to create new project
export const getProjectsInVault = (params, cb) => {
  return dispatch => {
    RestClient.get(`/project/${params.userId}/${params.category}`, {})
      .then(result => {
        cb(JSON.parse(result.projectList));
      })
      .catch(e => {
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: e,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

export const getProjectDetailsById = (params, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.get(`/project/${params._id}`, {})
      .then(result => {
        result.type = params.type;
        if (params.fromProjectVault) {
          result.project._id = "";
          result.project.name = "";
        }
        dispatch(projectDetailsFetched(result));
        
        dispatch(appActions.hideLoader());
        cb({ success: true });
      })
      .catch(e => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: 'Data not available!',
            type: "error",
            confirmAlert: () => false
            
          })
        );
      });
  };
};

export const getProjectDetailsByIdAndCategory = (params, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.get(`/project/fetch/${params._id}/${params.category}`, {})
      .then(result => {
        result.type = params.category;
        if (params.fromProjectVault) {
          result.project._id = "";
          result.project.name = "";
        }
        dispatch(projectDetailsFetched(result));
        dispatch(appActions.hideLoader());
        cb(result);
      })
      .catch(e => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: e,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

export const loadExistingProjectFile = (params, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    dispatch(projectDetailsFetched(params));
    dispatch(appActions.hideLoader());
    cb(null, true);
  };
};

// delete file
export const deleteProjectInVault = (params, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.delete(`/project/${params._id}`, {})
      .then(result => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Success!",
            text: "Project Deleted Successfully!",
            type: "success",
            confirmAlert: () => false
          })
        );
        cb({ success: true, result: result });
      })
      .catch(e => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: e,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

// rename project
export const renameProject = (params, cb) => {
  let _id = params._id;
  delete params._id;
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.put(`/project/rename/${_id}`, params)
      .then(result => {
        if (result.status == 200) {
          dispatch(appActions.hideLoader());
          dispatch(
            swal.showAlert({
              title: "Success!",
              text: "Project Renamed Successfully!",
              type: "success",
              confirmAlert: () => false
            })
          );
          cb({ success: true, result: result });
        } else if (result.status == 409) {
          dispatch(appActions.hideLoader());
          dispatch(
            swal.showAlert({
              title: "Error!",
              text: result.message,
              type: "error",
              confirmAlert: () => false
            })
          );
          return;
        }
      })
      .catch(e => {
        dispatch(appActions.hideLoader());
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: e,
            type: "error",
            confirmAlert: () => false
          })
        );
      });
  };
};

// get ketframedata
export const getkeyFrameData = (params, cb) => {

  return dispatch => {
    fetch(`${API_URL}/v1/project/`+params['_id'])
      .then(response => response.json())
      .then((jsonData) => {
        cb(jsonData);
      })
      .catch((error) => {
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: 'Something Went Wrong!!!',
            type: "error",
            confirmAlert: () => false
          })
        );
      })
  };
};