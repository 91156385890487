import * as CANVAS_TYPE from "./types";
import * as USER_TYPE from "../user/types";

import arrayMove from "array-move";
import _ from "underscore";

// initial state of the reducer
const initialState = {
	currentProject: {
		keyframes: [],
		projectType: "",
		_id: "",
		name: "",
		isModified: false,
		lastDrawnOnKeyframeIndex: [],
		panTransform: ""
	},
	bgTimeline: {
		bgKeyframes: []
	}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case CANVAS_TYPE.NEW_PROJECT_CREATED:
			state.currentProject._id = action.data._id;
			state.currentProject.name = action.data.name;
			state.currentProject.isModified = false;
			return { ...state };

		case CANVAS_TYPE.PROJECT_UPDATED:
			state.currentProject.isModified = false;

			return { ...state };

		case CANVAS_TYPE.PAN_TRANSFORM:
			state.currentProject.panTransform = action.data;
			state.currentProject.isModified = true;
			return { ...state };

		case CANVAS_TYPE.CLEAR_PAN_TRANSFORM:
			state.currentProject.panTransform = "";
			return { ...state };

		case CANVAS_TYPE.PROJECT_DETAILS_FETCHED:
			let { project:a, type } = action.data;
			let project = Array.isArray(a)?a[0]:a;
			if (type == "advanced-doodle") {

				state.currentProject._id = project._id;
				state.currentProject.name = project.name;
				state.currentProject.keyframes = JSON.parse(
					project.data
				).keyframes;
				state.currentProject.projectType = type;
				// state.currentProject.isModified = false;
				state.currentProject.panTransform = JSON.parse(
					project.data
				).panTransform;
				
				state.bgTimeline.bgKeyframes = JSON.parse(project.data).bgKeyframes;
				
			} else {

				let data = (typeof project.data == 'object')? project.data : JSON.parse(project.data);
				state.currentProject._id = project._id;
				state.currentProject.name = project.name;
				state.currentProject.keyframes = data.keyframes?data.keyframes:data;
				state.currentProject.projectType = type;
				state.currentProject.isModified = false;
				state.bgTimeline = { bgKeyframes: [{
                    history: [],
                    isBackground: true,
                    keyframe: {
                        version: "3.2.0",
                        objects: []
                    }
                }] };
				// state.bgTimeline.bgKeyframes = data.bgKeyframes?data.bgKeyframes:data;
			}

			return { ...state };

		case CANVAS_TYPE.SAVE_NEW_KEYFRAME:
			var historyObj = {
				history: [],
				background: {
					keyframe: {
						version: "3.2.0",
						objects: []
					}
				},
				index: action.data.keyframeIndex

			}; // blank history object when we land on the page
			if (action.data.saveBlankHistoryObj) {
				historyObj = {
					history: [
						{
							keyframe: {
								version: "3.2.0",
								objects: []
							},
							keyframesBase64: ""
						}
					],
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: action.data.keyframeIndex
				};
			} // if condition to update hostory object with blank keyframe and base64 when pressed from next button.

			state.currentProject.keyframes.push(historyObj); //pushed the history object to current project.

			state.bgTimeline.bgKeyframes.push({
				history: [],
				isBackground: true,
				keyframe: {
					version: "3.2.0",
					objects: []
				}
			});
			return { ...state };

		case CANVAS_TYPE.ADD_NEW_KEYFRAME_AT:
			var { advanced } = action.data;

			if (!advanced) {
				// added a new keyframe on the index specified from wiggle/flipbook
				state.currentProject.keyframes.splice(action.data.index, 0, {
					history: [
						{
							keyframe: {
								version: "3.2.0",
								objects: []
							},
							keyframesBase64: ""
						}
					],
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: action.data.index
				});
			} else if (advanced) {
				// added a new keyframe on foreground index specified from advanced section
				state.currentProject.keyframes.splice(action.data.index, 0, {
					history: [
						{
							keyframe: {
								version: "3.2.0",
								objects: []
							},
							keyframesBase64: ""
						}
					],
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: action.data.index
				});

				// added a new keyframe on background index specified from advanced section
				state.bgTimeline.bgKeyframes.splice(action.data.index, 0, {
					history: [],
					isBackground: true,
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					index: action.data.index
				});
			}
			return { ...state };

		case CANVAS_TYPE.HANDLE_BG_ON_DUPLICATE_KEYFRAME:
		case CANVAS_TYPE.HANDLE_BG_ON_ADD_EXPOSURE:
			var { atEnd, index } = action.data;

			if (atEnd) {
				state.bgTimeline.bgKeyframes.push({
					history: [],
					isBackground: true,
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					index: index
				});
			} else {
				state.bgTimeline.bgKeyframes.splice(index, 0, {
					history: [],
					isBackground: true,
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					index: index
				});
			}

			return { ...state };

		case CANVAS_TYPE.HANDLE_FG_ON_DUPLICATE_KEYFRAME:
		case CANVAS_TYPE.HANDLE_FG_ON_ADD_EXPOSURE:
			var { atEnd, index } = action.data;

			if (atEnd) {
				state.currentProject.keyframes.push({
					history: [],
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					index: index
				});
			} else {
				state.currentProject.keyframes.splice(index, 0, {
					history: [],
					keyframe: {
						version: "3.2.0",
						objects: []
					},
					index: index
				});
			}

			return { ...state };

		case CANVAS_TYPE.SET_PROJECT_TYPE:
			state.currentProject.projectType = action.data.type; // set project either wiggledoodle/ flipbook/advanced
			return { ...state };

		case CANVAS_TYPE.UPDATE_KEYFRAME:
			var {
				index,
				keyframe,
				keyframesBase64,
				selectedSpace
			} = action.data;
			if (state.currentProject.keyframes[index] && !selectedSpace) {
				state.currentProject.keyframes[index].keyframe = keyframe;
				state.currentProject.keyframes[
					index
				].keyframesBase64 = keyframesBase64;
				state.currentProject.isModified = true;
				state.currentProject.keyframes[index].index = index;
			} else if (
				state.currentProject.keyframes[index] &&
				selectedSpace &&
				selectedSpace == "foreground"
			) {
				state.currentProject.keyframes[index].keyframe = keyframe;
				state.currentProject.keyframes[
					index
				].keyframesBase64 = keyframesBase64;
				state.currentProject.isModified = true;
				state.currentProject.keyframes[index].index = index;
			} else if (
				state.bgTimeline.bgKeyframes[index] &&
				selectedSpace &&
				selectedSpace == "background"
			) {
				state.currentProject.isModified = true;
				state.bgTimeline.bgKeyframes[index].keyframe = keyframe;
				
			} 
			// else if (
			// 	state.currentProject.keyframes[index] &&
			// 	selectedSpace &&
			// 	selectedSpace == "background"
			// ) {
			// 	// state.bgTimeline.isModified = true;
			// 	// state.bgTimeline.bgKeyframes[index].keyframe = keyframe;
			// 	state.currentProject.isModified = true;
			// 	state.bgTimeline.bgKeyframes[index].keyframe = keyframe;
			// 	state.bgTimeline.bgKeyframes.push({
			// 		history: [],
			// 		isBackground: true,
			// 		keyframe: {
			// 			version: "3.2.0",
			// 			objects: []
			// 		}
			// 	});
			// }
			// state.currentProject.keyframes[index].history = [
			// 	...state.currentProject.keyframes[index].history,
			// 	{ keyframe, keyframesBase64 }
			// ];

			// state.currentProject.lastDrawnOnKeyframeIndex = [
			// 	...state.currentProject.lastDrawnOnKeyframeIndex,
			// 	index
			// ];
			return { ...state };


		case CANVAS_TYPE.DELETE_KEYFRAME:
			var { advanced, index, whichSpace } = action.data;
			// state.currentProject.keyframes.splice(index, 1);
			if (advanced && whichSpace == "background") {
				state.bgTimeline.bgKeyframes.splice(index, 1);
			}else{
			state.currentProject.keyframes.splice(index, 1);
			}
			// _.filter(state.currentProject.keyframes, (e, index) => {
			// });

			return { ...state };

		case CANVAS_TYPE.EMPTY_KEYFRAME:
			var { index, selectedSpace } = action.data;

			if (selectedSpace == "foreground") {
				state.currentProject.keyframes[index].keyframe = {
					version: "3.2.0",
					objects: []
				};

				state.currentProject.keyframes[index].history = [
					{
						keyframe: {
							version: "3.2.0",
							objects: []
						},
						keyframesBase64: ""
					}
				];

				if (state.currentProject.keyframes[index].addedExposure) {
					state.currentProject.keyframes[
						index
					].addedExposure = undefined;
				}

				if (state.currentProject.keyframes[index].duplicated) {
					delete state.currentProject.keyframes[index].duplicated;
				}

				if (
					state.currentProject.keyframes[index]
						.addedExposureOfIndex != undefined
				) {
					state.currentProject.keyframes[
						index
					].addedExposureOfIndex = undefined;
				}
			} else {
				state.bgTimeline.bgKeyframes[index].keyframe = {
					version: "3.2.0",
					objects: []
				};

				state.bgTimeline.bgKeyframes[index].history = [
					{
						keyframe: {
							version: "3.2.0",
							objects: []
						},
						keyframesBase64: ""
					}
				];

				if (state.bgTimeline.bgKeyframes[index].addedExposure) {
					state.bgTimeline.bgKeyframes[
						index
					].addedExposure = undefined;
				}

				if (state.bgTimeline.bgKeyframes[index].duplicated) {
					delete state.bgTimeline.bgKeyframes[index].duplicated;
				}

				if (
					state.bgTimeline.bgKeyframes[index].addedExposureOfIndex !=
					undefined
				) {
					state.bgTimeline.bgKeyframes[
						index
					].addedExposureOfIndex = undefined;
				}
			}

			return { ...state };

		case CANVAS_TYPE.RESET_ALL_EXPOSURED_OF_SPECIFIC_EXPOSURED_INDEX:
			var { val, selectedSpace } = action.data;

			if (selectedSpace == "foreground") {
				let newKeyframes = [];
				_.filter(state.currentProject.keyframes, e => {
					if (
						e.addedExposure &&
						e.addedExposureOfIndex == val &&
						e.dntReset == undefined
					) {
						e.addedExposure = undefined;
						e.addedExposureOfIndex = undefined;
						e.keyframe = {
							version: "3.2.0",
							objects: []
						};
						e.history = [
							{
								keyframe: {
									version: "3.2.0",
									objects: []
								},
								keyframesBase64: ""
							}
						];
						newKeyframes = [...newKeyframes, e];
					} else {
						newKeyframes = [...newKeyframes, e];
					}
				});

				state.currentProject.keyframes = newKeyframes;
			} else {
				let newKeyframes = [];
				_.filter(state.bgTimeline.bgKeyframes, e => {
					if (
						e.addedExposure &&
						e.addedExposureOfIndex == val &&
						e.dntReset == undefined
					) {
						e.addedExposure = undefined;
						e.addedExposureOfIndex = undefined;
						e.keyframe = {
							version: "3.2.0",
							objects: []
						};
						e.history = [
							{
								keyframe: {
									version: "3.2.0",
									objects: []
								},
								keyframesBase64: ""
							}
						];
						newKeyframes = [...newKeyframes, e];
					} else {
						newKeyframes = [...newKeyframes, e];
					}
				});

				state.bgTimeline.bgKeyframes = newKeyframes;
			}

			return { ...state };

		case CANVAS_TYPE.DUPLICATE_KEYFRAME_AT:
			var { index, selectedSpace } = action.data;

			if (!selectedSpace || selectedSpace == "foreground") {
				state.currentProject.keyframes.splice(action.data.index, 0, {
					history: [
						{
							keyframe: {
								version: "3.2.0",
								objects: []
							},
							keyframesBase64: ""
						}
					],
					duplicated: true,
					keyframe:
						state.currentProject.keyframes[index - 1].keyframe,
					keyframesBase64:
						state.currentProject.keyframes[index - 1]
							.keyframesBase64
				}); // added a new keyframe on the index specified
			} else if (selectedSpace == "background") {
				state.bgTimeline.bgKeyframes.splice(index, 0, {
					history: [
						{
							keyframe: {
								version: "3.2.0",
								objects: []
							},
							keyframesBase64: ""
						}
					],
					isBackground: true,
					duplicated: true,
					keyframe: state.bgTimeline.bgKeyframes[index - 1].keyframe,
					keyframesBase64:
						state.bgTimeline.bgKeyframes[index - 1].keyframesBase64
				}); // added a new keyframe on the index specified
			}

			return { ...state };

		case CANVAS_TYPE.ADD_EXPOSURE_AT:
			var { selectedSpace, activeKeyframeIndex, index } = action.data,
				prevIndex;
			if (selectedSpace == "foreground") {
				prevIndex = state.currentProject.keyframes[index - 1].index;

				state.currentProject.keyframes[index - 1] = {
					history: [],
					addedExposure: true,
					addedExposureOfIndex: index - 1,
					keyframe:
						state.currentProject.keyframes[index - 1].keyframe,
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: prevIndex,
					dntReset: true
				};

				state.currentProject.keyframes.splice(index, 0, {
					addedExposure: true,
					addedExposureOfIndex: activeKeyframeIndex,
					keyframe:
						state.currentProject.keyframes[index - 1].keyframe,
					keyframesBase64:
						state.currentProject.keyframes[index - 1]
							.keyframesBase64
				}); // added a new keyframe as an exposure on the index specified (foreground)
			} else if (selectedSpace == "background") {
				prevIndex = state.bgTimeline.bgKeyframes[index - 1].index;

				state.bgTimeline.bgKeyframes[index - 1] = {
					history: [],
					addedExposure: true,
					addedExposureOfIndex: index - 1,
					keyframe: state.bgTimeline.bgKeyframes[index - 1].keyframe,
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: prevIndex,
					dntReset: true
				};

				state.bgTimeline.bgKeyframes.splice(index, 0, {
					addedExposure: true,
					isBackground: true,
					addedExposureOfIndex: activeKeyframeIndex,
					keyframe: state.bgTimeline.bgKeyframes[index - 1].keyframe,
					keyframesBase64:
						state.bgTimeline.bgKeyframes[index - 1].keyframesBase64
				}); // added a new keyframe as an exposure on the index specified (background)
			}

			return { ...state };

		case CANVAS_TYPE.UPDATE_KEYFRAME_AS_EXPOSURED:
			var { keyframeofIndex, at, selectedSpace } = action.data,
				prevIndex;

			if (selectedSpace == "foreground") {
				prevIndex = state.currentProject.keyframes[at].index;
				state.currentProject.keyframes[at] = {
					history: [],
					addedExposure: true,
					addedExposureOfIndex: keyframeofIndex,
					keyframe:
						state.currentProject.keyframes[keyframeofIndex]
							.keyframe,
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: prevIndex,
					dntReset: keyframeofIndex == at ? true : undefined
				};
			} else {
				prevIndex = state.bgTimeline.bgKeyframes[at].index;
				state.bgTimeline.bgKeyframes[at] = {
					history: [],
					addedExposure: true,
					addedExposureOfIndex: keyframeofIndex,
					keyframe:
						state.bgTimeline.bgKeyframes[keyframeofIndex].keyframe,
					background: {
						keyframe: {
							version: "3.2.0",
							objects: []
						}
					},
					index: prevIndex,
					dntReset: keyframeofIndex == at ? true : undefined
				};
			}

			return { ...state };
		case CANVAS_TYPE.UPDATE_EXPOSURED_FRAME:
			var {
				addedExposureOfIndex,
				currentJSONCanvas,
				selectedSpace
			} = action.data;

			if (selectedSpace == "foreground") {
				state.currentProject.keyframes.map((data, index) => {
					if (data.addedExposureOfIndex == addedExposureOfIndex) {
						data.keyframe = currentJSONCanvas;
					}
				});
			} else {
				state.bgTimeline.bgKeyframes.map((data, index) => {
					if (data.addedExposureOfIndex == addedExposureOfIndex) {
						data.keyframe = currentJSONCanvas;
					}
				});
			}

			return { ...state };
		case CANVAS_TYPE.UNDO:
			let selectedKeyframeIndex = action.data.index; // selected keyframe index
			let lastHistoryIndex =
				state.currentProject.keyframes[selectedKeyframeIndex].history
					.length - 1; // last index from history object of a selected keyframe
			state.currentProject.keyframes[
				selectedKeyframeIndex
			].history.splice(lastHistoryIndex, 1); // removed last object from history

			// state.currentProject.lastDrawnOnKeyframeIndex = [
			// 	...state.currentProject.lastDrawnOnKeyframeIndex.slice(0, -1)
			// ];

			let setFromHistory =
				state.currentProject.keyframes[selectedKeyframeIndex].history[
					state.currentProject.keyframes[selectedKeyframeIndex]
						.history.length - 1
				]; // object which will be used to set the current keyframe from history

			state.currentProject.keyframes[selectedKeyframeIndex].keyframe =
				setFromHistory.keyframe;
			state.currentProject.keyframes[
				selectedKeyframeIndex
			].keyframesBase64 = setFromHistory.keyframesBase64; // updated current keyframe from the object from history.

			return { ...state };

		case CANVAS_TYPE.REORDER_KEYFRAME_POSITION:
			let oldIndex = action.data.oldIndex,
				newIndex = action.data.newIndex,

				whichGround = action.data.selectedSpace;
			if(whichGround == 'foreground'){
				let reorderedKeyframes = arrayMove(
							state.currentProject.keyframes,
							oldIndex,
							newIndex
						);
			state.currentProject.keyframes = reorderedKeyframes;
			

			} else if(whichGround == 'background'){
				let reorderedBGKeyframes = arrayMove(
							state.bgTimeline.bgKeyframes,
							oldIndex,
							newIndex
						);
			state.bgTimeline.bgKeyframes = reorderedBGKeyframes;

			}

			return { ...state };
		
			

		case CANVAS_TYPE.PURGE_CANVAS:
			state.currentProject = {
				keyframes: [],
				projectType: "",
				_id: "",
				name: "",
				isModified: false
			};

			state.bgTimeline = {
				bgKeyframes: []
			};

			return { ...state }; // Return the ini

		case CANVAS_TYPE.REMOVE_NAME_AND_ID:
			state.currentProject._id = "";
			state.currentProject.name = "";
			state.currentProject.isModified = true;

			return { ...state };

		case USER_TYPE.LOGOUT:
			return initialState;

		case CANVAS_TYPE.UPDATE_MOVE_PATH:
			var {
				activeKeyframeIndex,
				transformPath
			} = action.data;
			if (state.currentProject.keyframes[activeKeyframeIndex]){
				state.currentProject.keyframes[activeKeyframeIndex].transformPath = transformPath;
			}
			return { ...state };
	
		default:
			return state;
	}
}