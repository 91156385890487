/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
// const fabric = require("fabric").fabric;

class Group extends FabricCanvasTool {
	configureCanvas(props) {
		let canvas = this._canvas;
		canvas.isDrawingMode = false;
		canvas.selection = true;
		canvas.forEachObject(o => {
			o.selectable = o.evented = o.hasControls = o.hasBorders = true;
			// if (props.advancedSection) {
			// 	o.hasRotatingPoint = false;
			// }
            if (!canvas.getActiveObject()) {
                      return;
                    }
                    if (canvas.getActiveObject().type !== 'activeSelection') {
                      return;
                    }
                    canvas.getActiveObject().toGroup();
                    canvas.requestRenderAll();
                  
		});
		
	}
}

export default Group;
