import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

const RightTools = (props) => {
  const svgContainerRef = useRef(null);
  const {
    selectedTool,
    playTransform,
    checkIfPlayButtonIsEnabled,
    playing,
    looping,
    stopAnimation,
    animationSpeed,
    changeAnimationSpeed,
    playAnimation,
    setSelectedTool,
  } = props;


  useEffect(() => {
    // Fetch the SVG file
    fetch('https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/splash.svg')
      .then(response => response.text())
      .then(svgContent => {
        // Create a DOM parser to parse the SVG
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');

        // Modify the SVG elements with the class "cls-4"
        svgElement.querySelectorAll('path').forEach((element) => {
          element.style.fill = props.selectedBrushColor;
        });

        // Clear the container and inject the modified SVG
        svgContainerRef.current.innerHTML = '';
        svgContainerRef.current.appendChild(svgElement);
      })
      .catch(error => console.error('Error fetching SVG:', error));
  }, [props.selectedBrushColor]);


  return (
    <div className="wiggle_doddle_main_mid_right advanced_right_sec col-sm-2">
      <div className="wiggle_doddle_main_inner">
      <div className="splash" ref={svgContainerRef} />
        {/* <div className="color_text" style={{textAlign: 'center'}}>
          <img src="images/wiggle_doddle_main/color_text.png" />
        </div>   */}
        <div className="color_box">
          <div className="color_main_box advance_color_box">
            <img src="https://d3rc7k2n78msdl.cloudfront.net/advanced/advanced_palette.png" style={{width: "170px", height: '90px'}}/>
            <div className="overlay_color_box">
              <ul>
                <li
                  onClick={() => props.setBrushColor("color_1")}
                  // onDoubleClick={() => props.openColorPicker("color_1")}
                >
                  <div className="color_1" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_2")}
                  // onDoubleClick={() => props.openColorPicker("color_2")}
                >
                  <div className="color_2" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_3")}
                  // onDoubleClick={() => props.openColorPicker("color_3")}
                >
                  <div className="color_3" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_4")}
                  // onDoubleClick={() => props.openColorPicker("color_4")}
                >
                  <div className="color_4" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_5")}
                  // onDoubleClick={() => props.openColorPicker("color_5")}
                >
                  <div className="color_5" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_6")}
                  // onDoubleClick={() => props.openColorPicker("color_6")}
                >
                  <div className="color_6" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_7")}
                  // onDoubleClick={() => props.openColorPicker("color_7")}
                >
                  <div className="color_7" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_8")}
                  // onDoubleClick={() => props.openColorPicker("color_8")}
                >
                  <div className="color_8" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_9")}
                  // onDoubleClick={() => props.openColorPicker("color_9")}
                >
                  <div className="color_9" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_10")}
                  // onDoubleClick={() => props.openColorPicker("color_10")}
                >
                  <div className="color_10" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_11")}
                  // onDoubleClick={() => props.openColorPicker("color_11")}
                >
                  <div className="color_11" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_12")}
                  // onDoubleClick={() => props.openColorPicker("color_12")}
                >
                  <div className="color_12" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_13")}
                  // onDoubleClick={() => props.openColorPicker("color_13")}
                >
                  <div className="color_13" />
                </li>
                <li
                  onClick={() => props.setBrushColor("color_14")}
                  // onDoubleClick={() => props.openColorPicker("color_14")}
                >
                  <div className="color_14" />
                </li>
                {/* <li
                  onClick={() => props.setBrushColor("color_15")}
                  // onDoubleClick={() => props.openColorPicker("color_15")}
                >
                  <div className="color_15" />
                </li> */}
                {/* <li
                  onClick={() => props.setBrushColor("color_16")}
                  // onDoubleClick={() => props.openColorPicker("color_16")}
                >
                  <div className="color_16" />
                </li> */}
              </ul>

              <div
                // onClick={() => props.newColorPicker()}
                style={{
                  marginTop: "25px",
                  // color: "#152155",
                  // fontSize: "13px",
                  // fontWeight: "500",
                  // textAlign: "left",
                  marginLeft: "-8px",
                  // cursor: 'pointer'
                }}
              >
                {/* More Colors */}
                <img src="https://d3rc7k2n78msdl.cloudfront.net/more_colors_picker.png"/>

                <ul className="more_colrs">
                  <li
                  onClick={() => props.newColorPicker()}
                  style={{position: "absolute", bottom: "10px", right: "43px", cursor: 'pointer'}}
                  title="Select Colors"
                  >
                      <img src="https://d3rc7k2n78msdl.cloudfront.net/more_colors1.svg" height= '22'/>
                  </li>
                  <li
                  onClick={() => props.canvasColorPicker()}
                  style={{position: "absolute", bottom: "10px", right: "14px", cursor: 'pointer'}}
                  title="Eyedropper"
                  >
                      <img src="https://d3rc7k2n78msdl.cloudfront.net/eyedropper.svg" height= '22'/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="color_box brush_size_box" style={{marginTop: '65px', marginBottom: '10px'}}>
          {/* <div className="color_text righbrushsize" >
            <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/brush_text.png" />
          </div> */}
          <div className="color_main_box">
            <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/brush_size_box.png" />
            <div className="brush_overlay">
              <div className="top_dot">
                <span
                  style={{
                    height: `${props.brushSize * 1.5}px`,
                    width: `${props.brushSize * 1.5}px`,
                    display: "flex",
                    backgroundColor: props.selectedBrushColor,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto"
                  }}
                />
              </div>
              <div className="bottom_sec">
                <div className="brush_board">
                  <div className="slidecontainer">
                    <input
                      type="range"
                      min="1"
                      max="100"
                      className="slider"
                      id="myRange"
                      value={props.brushSize * 5}
                      onChange={e => props.changeBrushSize(e)}
                      autofocus
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adv_rightSide_play">
          <ul>
          {!playing && (
                  <li
                    onClick={
                      !checkIfPlayButtonIsEnabled() || looping
                        ? () => {}
                        : () => playAnimation(-1, "play")
                    }
                    className={classnames({
                      active_button: checkIfPlayButtonIsEnabled() && !looping,
                    })}
                    title="Play"
                  >
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/play.png" />
                  </li>
                )}
                {playing && (
                  <li
                    onClick={() => stopAnimation()}
                    className="active_button"
                    title="Stop"
                  >
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/pause.png" />
                  </li>
                )}
                
                {!looping && (
                  <li
                    onClick={
                      !checkIfPlayButtonIsEnabled() || playing
                        ? () => {}
                        : () => playAnimation(0, "loop")
                    }
                    className={classnames({
                      active_button: checkIfPlayButtonIsEnabled() && !playing,
                    })}
                    title="Loop"
                  >
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/loop.png" />
                  </li>
                )}

                {looping && (
                  <li
                    onClick={() => stopAnimation()}
                    className="active_button"
                    title="Stop"
                  >
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/pause.png" />
                  </li>
                )}
                <li>
                <div className="slw_fast_icons">
                <div className="slidecontainer">
                <input
                  type="range"
                  min="1"
                  max="3"
                  className="speedSlider"
                  id="myRange"
                  value={animationSpeed}
                  onChange={(e) => changeAnimationSpeed(e)}
                  autofocus
                />
              </div>
              </div>
                </li>
          </ul>
        </div>

        {/*<div class="art_liberary_sec">
          <img src="images/advanced/art.png" />
          <div class="art_list">
            <ul>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li class="select_art_div">
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
              <li>
                <div class="art_inner_col">
                  <span>
                    <img src="images/advanced/icon1.png" />
                  </span>
                  <span>
                    <img src="images/advanced/icon1text.png" />
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="inner_list">
            <div class="organize_image">
              <Link to="/project-vault">
                <img src="images/advanced/organize.png" />
              </Link>
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default RightTools;
