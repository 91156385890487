import React, { useEffect } from "react";
import Swal from "sweetalert2";
import DeviceAndOrientation from "./deviceAndOrientationDetection";

const WelcomeModal = () => {
  useEffect(() => {
    const hasSeenModal = localStorage.getItem("hasSeenWelcomeModal") === 'true';

    if (!hasSeenModal) {
      // Show the SweetAlert modal when the component mounts
      Swal.fire({
        html:  `
        <p>Things are bigger and better! Watch the tutorial to learn how to scale your old animations to the new size.</p>
        <label>
          <input type="checkbox" id="doNotShowAgain" autofocus> Do not show this again
        </label>
      `,
        showCancelButton: true,
        confirmButtonText: "View Tutorial",
        cancelButtonText: "Close",
        imageUrl: "https://d3rc7k2n78msdl.cloudfront.net/update-popup.png",
        imageWidth: 250,
        imageHeight: 80,
        confirmButtonColor: "#1b75d0",
        customClass: {
          htmlContainer: "custom_swal_pop_title",
        },
    }).then((result) => {
        const doNotShowAgain = document.getElementById('doNotShowAgain')?.checked;
        if (result.isConfirmed) {
          // Redirect the user to the tutorial page
        //   window.location.href = "https://www.youtube.com/watch?v=I_vV-zb7apw";
        window.open('https://www.youtube.com/watch?v=I_vV-zb7apw');
        localStorage.setItem("hasSeenWelcomeModal", "true");
          localStorage.removeItem("hasNotSeenWelcomeModal", "false");
        } else if(doNotShowAgain){
            localStorage.setItem("hasSeenWelcomeModal", "true");
            localStorage.removeItem("hasNotSeenWelcomeModal", "false");
        }
        else if (result.isDismissed) {
          localStorage.setItem("hasNotSeenWelcomeModal", "false");
        }
      });
    }
  }, []);

  return <div />;
};

export default WelcomeModal;
