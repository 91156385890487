const Message = {
	error: "Error message here.",
	succes: "Success message here.",
	loadFileFromPopupSuccess: "Project Loaded Successfully",
	import: {
		success: "The file has been imported successfully. Please make sure you save the project after making your changes.",
		overwrite: "Current work done will be lost. Do you wish to overwrite this file? If yes, press OK. Else, save this file and import in a new canvas.",
		savedProjectOverwrite: "Current work done in the project will be lost. Do you wish to overwrite this file? If yes, press OK. Else, import in a new canvas.",
		redirect: {
			"wiggledoodle": "This file is compatible with Wiggledoodle-ish level. Do you wish to redirect and open the file in that level instead?",
			"flipbook": "This file is compatible with FlipBook-ish level. Do you wish to redirect and open the file in that level instead?",
			'advanced-doodle': "This file is compatible with Advanced-ish level. Do you wish to redirect and open the file in that level instead?"
		}
	}
};

module.exports = Message;
