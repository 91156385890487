import "babel-polyfill";
import React from "react";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider, connect } from "react-redux";
import createHistory from "history/createBrowserHistory";
// import { createBrowserHistory } from "history";
import { ConnectedRouter } from "react-router-redux";
import Routers from "./Routers";
import configureStore from "./configureStore";
import ScrollToTop from "../components/ScrollToTop";
import Loader from "../components/Loader";
import DeviceAndOrientation from "../components/deviceAndOrientationDetection";

import SweetAlert from "react-redux-sweetalert2";

export const history = createHistory();

// store configration
const { persistor, store } = configureStore(history);
const ReduxLoader = connect(state => ({ loading: state.app.isLoading }))(
  Loader
);

export default () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReduxLoader />
        <DeviceAndOrientation />
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <Routers store={store} history={history} />
          </ScrollToTop>
        </ConnectedRouter>
        <SweetAlert />
      </PersistGate>
    </Provider>
  );
};
