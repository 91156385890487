import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SketchField, Tools } from "../../sketch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hotkeys } from "react-keyboard-shortcuts";
import arrayMove from "array-move";
import Draggable from "react-draggable";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import {
  newKeyframe,
  updateKeyframe,
  setProjectType,
  deleteKeyframe,
  addNewKeyframeAt,
  duplicateKeyframeAt,
  addExposureAt,
  undoKeyframe,
  generateVideo,
  reorderKeyframePosition,
  purgeCanvas,
  updateKeyframeAsExposureWithPreviousDrawnObject,
  resetAllExposuredFramesOfSpecificAddedExposureOfIndex,
  updateExposuredFrame,
  emptyKeyframe,
  handleBgOnDuplicateKeyframe,
  handleFgOnDuplicateKeyframe,
  handleBgOnAddExposure,
  handleFgOnAddExposure,
  _handlePanTransform,
  _clearPanTransform,
  updateMovePath,
} from "../../redux/canvas/actions";
import {
  createNewProject,
  updateProject,
  getProjectDetailsById,
  getProjectDetailsByIdAndCategory,
  loadExistingProjectFile,
} from "../../redux/user/actions";
import { hideLoader, showLoader } from "../../redux/app/actions";
import $ from "jquery";
import _ from "underscore";
import classnames from "classnames";
import { swal } from "react-redux-sweetalert2";
import GIF from "gif.js.optimized";
import Whammy from "react-whammy";
import { saveAs } from "file-saver";
import Topbar from "./topbar";
import LeftTools from "./leftTools";
import RightTools from "./rightTools";
import BottomTools from "./bottomTools";
import ColorPickerModal from "../../components/ColorPickerModal";
import UnsavedChangesModal from "../../components/UnsavedChangesModal";
import ProjectListingModal from "../../components/ProjectListingModal";
import Message from "../../utilities/messages";
import ReactGA from "react-ga";

const fabric = require("fabric").fabric;
var JSZip = require("jszip");
// const scaleFactor = 2;

class AdvancedDoodle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectType: "advanced-doodle",
      selectedTool: Tools.Pencil,
      colorPick: false,
      pickCanvasColor: false,
      canvasHeight: "",
      canvasWidth: "",
      sketchCanvas: {},
      keyframesLimit: 3,
      activeKeyframeIndex: 0,
      selectedBrushColor: "#300348",
      playing: false,
      looping: false,
      traceEnabled: false,
      sendToBackEnabled: false,
      brushSize: 5,
      playingGif: "",
      animationSpeed: 2,
      selectedKeyframeInTimeline: "",
      undoDisabled: false,
      isObjectSelected: false,
      pressureOpacity: 1,
      isPasteEnabled: false,
      isGroupAllEnabled: true,
      prompt: "",
      promptTowards: "",
      doubleClickedOnColorClass: "",
      blankProjectName: "",
      selectedSpace: "foreground",
      randomColorState: "",
      draggableIdActive: false,
      stopping: false,
      rand: "",
      isFirstInExposured: null,
      import_img: false,
      set_date: "",
      recentColors: [],
    };

    this.interval = "";
    this._imageCanvas = new fabric.Canvas();
    this._toVideoCanvas = new fabric.Canvas();
    this._gifCanvas = new fabric.Canvas(); // to create gif with white background
    this._bgGifCanvas = new fabric.Canvas(); // to create gif with white background

    this._imgSequence = new fabric.Canvas(); // to create image sequence
    this.debouncedAnimationSpeed = this.debouncedAnimationSpeed.bind(this);
    this.debouncedAnimationSpeed = _.debounce(
      this.debouncedAnimationSpeed,
      500
    );
    this.currentProject = {
      keyframes: [],
      projectType: "",
      lastDrawnAt: [],
    };
    this.bgTimeline = {
      bgKeyframes: [],
      bgLastDrawnAt: [],
    };
    this.projectListingComponentRef = React.createRef();
  }

  hot_keys = {
    del: {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.removeSelectedObject();
        else console.log("cannot delete");
      },
    },
    backspace: {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.removeSelectedObject();
        else console.log("cannot delete");
      },
    },
    "ctrl+z": {
      priority: 1,
      handler: (event) => {
        if (this.checkIfUndoIsDisabled()) this.undoAction();
        else console.log("cannot undo more..");
      },
    },
    "meta+z": {
      priority: 1,
      handler: (event) => {
        if (this.checkIfUndoIsDisabled()) this.undoAction();
        else console.log("cannot undo more..");
      },
    },
    "ctrl+c": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.copy();
        else console.log("cannot copy as nothing is selected..");
      },
    },
    "meta+c": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.copy();
        else console.log("cannot copy as nothing is selected..");
      },
    },
    "ctrl+x": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.cut();
        else console.log("cannot cut as nothing is selected..");
      },
    },
    "meta+x": {
      priority: 1,
      handler: (event) => {
        if (this.state.isObjectSelected) this.cut();
        else console.log("cannot cut as nothing is selected..");
      },
    },
    "ctrl+v": {
      priority: 1,
      handler: (event) => {
        if (this.state.isPasteEnabled) this.paste();
        else console.log("cannot paste as nothing is copied.....");
      },
    },
    "meta+v": {
      priority: 1,
      handler: (event) => {
        if (this.state.isPasteEnabled) this.paste();
        else console.log("cannot paste as nothing is copied.....");
      },
    },
  };
  componentDidMount() {
    // google analytics code
    ReactGA.pageview(window.location.pathname + window.location.search);

    let context = this;
    let { state } = this.props.history.location;

    if (state) this.setState({ set_date: state.date });

    context.interval = setInterval(() => {
      let height = document.getElementById("drawing_board").clientHeight;
      let width = document.getElementById("drawing_board").clientWidth;

      // to make the import image in the ClientRect, I removed *2 factor for advanced doodle

      // let height = document.getElementById("drawing_board").clientHeight;
      // let width = document.getElementById("drawing_board").clientWidth;

      let proj_date =
        context.state.set_date || context.props.canvas.currentProject.date;

      if (proj_date) {
        var project_date = new Date(parseInt(proj_date));
        var new_date = new Date("Wed May 05 2021 08:00:00");

        if (new_date > project_date) {
          height = document.getElementById("drawing_board").clientHeight;
          width = document.getElementById("drawing_board").clientWidth;
        }
      }

      // let height = 500,
      // width = 800;

      if (width > 0 && height > 0) {
        context.setState({
          canvasWidth: width,
          canvasHeight: height,
        });
        context._imageCanvas.setDimensions({
          width: width,
          height: height,
        });
        context._gifCanvas.setDimensions({
          width: width,
          height: height,
        });
        context._bgGifCanvas.setDimensions({
          width: width,
          height: height,
        });
        context._imgSequence.setDimensions({
          width: width,
          height: height,
        });
        context._toVideoCanvas.setDimensions({
          width: width,
          height: height,
        });
      }
    }, 1000);

    if (state && state._id) {
      let projectDetailsObj = {
        _id: state._id,
        type: "advanced-doodle",
        fromProjectVault: state.fromProjectVault,
      };

      this.purgeCanvas();
      this.props.getProjectDetailsById(projectDetailsObj, (res) => {
        if (res.success) {
          this.setUp();
        }
      });
    } else {
      this.setUp();
    }
  }

  setUp() {
    let { keyframes, panTransform } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      lastIndexWhereObjectExists;
    if (keyframes.length === 0) {
      let newKeyframeObj = {
        keyframeIndex: 0,
      };
      this.props.newKeyframe(newKeyframeObj);
    } else {
      let undoDisabled = keyframes.some((e) => {
        if (e.keyframe) {
          return e.keyframe.objects.length > 0;
        } else return false;
      });

      lastIndexWhereObjectExists = this.lastIndexWhereObjectExists();
      if (lastIndexWhereObjectExists == -1) {
        lastIndexWhereObjectExists = 0;
      }

      this.setState({
        sketchCanvas: keyframes[lastIndexWhereObjectExists].keyframe,
        activeKeyframeIndex: lastIndexWhereObjectExists,
        undoDisabled: undoDisabled,
      });
    }

    let setProjectTypeObj = {
      type: "advanced-doodle",
    };

    keyframes.map((item, index) => {
      // to fix issue with project over another project
      // if(index === 0){
      // this.currentProject.keyframes = [];
      // }
      if (item.keyframe) {
        var historyObj = {
          history: [
            {
              version: "3.2.0",
              objects: [],
            },
            item.keyframe,
          ],
          keyframe: item.keyframe,
          index: index,
          addedExposureOfIndex: item.addedExposureOfIndex,
          addedExposure: item.addedExposure,
          background: {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
          },
        };
      } else {
        var historyObj = {
          history: [
            {
              version: "3.2.0",
              objects: [],
            },
          ],
          keyframe: item.keyframe,
          index: index,
          addedExposureOfIndex: item.addedExposureOfIndex,
          addedExposure: item.addedExposure,
          background: {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
          },
        };
      }

      this.currentProject.keyframes.push(historyObj);
      if (
        _.isEqual(
          this.currentProject.keyframes[0].history[0],
          this.currentProject.keyframes[0].history[1]
        )
      ) {
        this.currentProject.keyframes[0].history.splice(1, 1); // delete duplicate empty history object
      }

      //checking and fixing is record present on same index
      let record_on_same_index = this.currentProject.keyframes.filter(
        (e) => e.index === index
      );
      let records_with_no_index = this.currentProject.keyframes.filter(
        (e) => e.index === undefined
      );

      if (record_on_same_index.length > 1) {
        this.currentProject.keyframes.splice(
          this.currentProject.keyframes.indexOf(record_on_same_index[0]),
          1
        );
      }

      if (records_with_no_index.length > 0) {
        var frames_to_remove = Object.keys(records_with_no_index);
        this.currentProject.keyframes.splice(frames_to_remove, 1);
      }
    });

    bgKeyframes.map((item, index) => {
      if (item.keyframe) {
        var historyObj = {
          history: [
            {
              version: "3.2.0",
              objects: [],
            },
            item.keyframe,
          ],
          keyframe: item.keyframe,
          index: index,
          addedExposureOfIndex: item.addedExposureOfIndex,
          addedExposure: item.addedExposure,
        };
      } else {
        var historyObj = {
          history: [
            {
              version: "3.2.0",
              objects: [],
            },
          ],
          keyframe: item.keyframe,
          index: index,
          addedExposureOfIndex: item.addedExposureOfIndex,
          addedExposure: item.addedExposure,
        };
      }

      this.bgTimeline.bgKeyframes.push(historyObj);
      if (
        _.isEqual(
          this.bgTimeline.bgKeyframes[0].history[0],
          this.bgTimeline.bgKeyframes[0].history[1]
        )
      ) {
        this.bgTimeline.bgKeyframes[0].history.splice(1, 1); // delete duplicate empty history object
      }

      //checking and fixing is record present on same index
      let record_on_same_index1 = this.bgTimeline.bgKeyframes.filter(
        (e) => e.index === index
      );
      let records_with_no_index1 = this.bgTimeline.bgKeyframes.filter(
        (e) => e.index === undefined
      );

      if (record_on_same_index1.length > 1) {
        this.bgTimeline.bgKeyframes.splice(
          this.bgTimeline.bgKeyframes.indexOf(record_on_same_index1[0]),
          1
        );
      }

      if (records_with_no_index1.length > 0) {
        var frames_to_remove1 = Object.keys(records_with_no_index1);
        this.bgTimeline.bgKeyframes.splice(frames_to_remove1, 1);
      }
    });

    this.props.setProjectType(setProjectTypeObj);

    setTimeout(() => {
      this.setState({
        traceEnabled: true,
      });
      if (bgKeyframes[lastIndexWhereObjectExists])
        this.sketchCanvas.toggleBackground(
          "setBackgroundFromBackgroundKey",
          bgKeyframes[lastIndexWhereObjectExists].keyframe
        );
    }, 1000);
  }

  lastIndexWhereObjectExists() {
    let { keyframes } = this.props.canvas.currentProject,
      lastIndexWhereObjectExists = _.findLastIndex(keyframes, (e) => {
        return (
          e.keyframe && e.keyframe.objects && e.keyframe.objects.length > 0
        );
      });
    return lastIndexWhereObjectExists;
  }

  lastIndexWhereObjectExistsInBg() {
    let { bgKeyframes } = this.props.canvas.bgTimeline,
      lastIndexWhereObjectExistsInBg = _.findLastIndex(bgKeyframes, (e) => {
        return (
          e.keyframe && e.keyframe.objects && e.keyframe.objects.length > 0
        );
      });
    return lastIndexWhereObjectExistsInBg;
  }

  firstIndexWhereObjectExists() {
    let { keyframes } = this.props.canvas.currentProject,
      firstIndexWhereObjectExists = _.findIndex(keyframes, (e) => {
        return (
          e.keyframe && e.keyframe.objects && e.keyframe.objects.length > 0
        );
      });

    return firstIndexWhereObjectExists;
  }

  firstIndexWhereObjectExistsInBg() {
    let { bgKeyframes } = this.props.canvas.bgTimeline,
      firstIndexWhereObjectExistsInBg = _.findIndex(bgKeyframes, (e) => {
        return (
          e.keyframe && e.keyframe.objects && e.keyframe.objects.length > 0
        );
      });

    return firstIndexWhereObjectExistsInBg;
  }

  componentDidUpdate(prevProps, prevState) {
    let { keyframes } = this.currentProject,
      undoDisabled,
      { panTransform } = this.props.canvas.currentProject;

    if (
      (keyframes[0] &&
        keyframes[0].history.length == 1 &&
        _.isEqual(keyframes[0].history[0], {
          version: "3.2.0",
          objects: [],
        })) ||
      (keyframes[0] &&
        keyframes[0].history.length == 2 &&
        _.isEqual(keyframes[0].history[1], {
          version: "3.2.0",
          objects: [],
        }))
    )
      undoDisabled = false;
    else undoDisabled = true;

    if (undoDisabled != this.state.undoDisabled)
      this.setState({
        undoDisabled: undoDisabled,
      });

    if (
      prevState.selectedTool == "circle" ||
      prevState.selectedTool == "triangle" ||
      prevState.selectedTool == "rectangle"
    ) {
      this.state.selectedTool = "select";
    }
  }

  // handleChangeComplete = (color) => {
  //   // rgba(48, 3, 72, 1)

  //   let hex_color = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
  //   this.setState({
  //     selectedBrushColor: hex_color,
  //   });
  // };

  handleChangeComplete = (color) => {
    let hex_color = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;

    this.setState((prevState) => {
      const recentColors = [...prevState.recentColors];

      // Remove the color if it already exists
      const colorIndex = recentColors.indexOf(hex_color);
      if (colorIndex > -1) {
        recentColors.splice(colorIndex, 1);
      }

      // Add the new color at the beginning of the list
      recentColors.unshift(hex_color);

      // Limit the recent colors list to 5 items
      if (recentColors.length > 8) {
        recentColors.pop();
      }

      return {
        selectedBrushColor: hex_color,
        recentColors: recentColors,
      };
    });
  };

  newColorPicker() {
    this.setState({ colorPick: !this.state.colorPick });
  }

  handleClose = () => {
    this.setState({ colorPick: false });
  };

  setSelectedTool(val, cb = () => {}) {
    this.setState({
      selectedTool:
        val === "trace" && this.state.selectedTool === "trace" ? "pencil" : val,
      pickCanvasColor: false,
      selectedBrushColor: this.state.pickCanvasColor
        ? "rgba(48, 3, 72, 1)"
        : this.state.selectedBrushColor,
    });

    if (val == "pan") {
      this.setState({
        draggableIdActive: true,
      });
    } else {
      this.setState({
        draggableIdActive: false,
      });
    }
    cb();
    // this.sketchCanvas.deselectActiveObjects();
  }

  cut() {
    this.sketchCanvas.cut();
    this.updateSelectedKeyframe(
      "",
      this.state.activeKeyframeIndex,
      "fromCutFunction"
    );
  }

  copy() {
    this.sketchCanvas.copy();
  }

  paste() {
    let context = this;
    context.sketchCanvas.paste();
    context.updateSelectedKeyframe("", context.state.activeKeyframeIndex);
  }

  zoomCanvas(type) {
    let context = this,
      zoomFactor;
    if (type === "zoomIn") {
      context.sketchCanvas.zoomIn(1.1);
    } else if (type === "zoomOut") {
      context.sketchCanvas.zoomOut(1.1);
    } else if (type === "scale") {
      context.sketchCanvas.setInitialZoomAndPosition();
    } else {
      context.props.dispatch(_clearPanTransform());
      context.sketchCanvas.resetZoom(1.1);
    }

    context.updateSelectedKeyframe("", context.state.activeKeyframeIndex);
  }

  // set brush color to be used
  setBrushColor(className) {
    let choosen = this.state.selectedTool;
    let e = document.querySelector(`.${className}`);
    let computedStyle = getComputedStyle(e);

    let brushColorInRGB = computedStyle.getPropertyValue("background-color");
    let a = brushColorInRGB
      .substring(brushColorInRGB.indexOf("(") + 1, brushColorInRGB.indexOf(")"))
      .replace(/ /g, "");

    let b = a.split(",");
    let brushHexColor = this.rgbToHex(Number(b[0]), Number(b[1]), Number(b[2]));
    if (choosen != "eyedropper") {
      this.setState({
        selectedBrushColor: brushHexColor,
        randomColorState: Math.random(),
      });
    }
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  // handle color picker
  handleColorPicker(pickerSelectedColor) {
    let { doubleClickedOnColorClass } = this.state;
    $(`.${doubleClickedOnColorClass}`).css(
      "background-color",
      pickerSelectedColor
    );
    setTimeout(() => {
      this.setBrushColor(doubleClickedOnColorClass);
      $("[data-dismiss=modal]").trigger({ type: "click" });
    }, 300);
  }

  openColorPicker(classname) {
    $("#openColorPickerModal").trigger("click");
    setTimeout(() => {
      this.setState({
        doubleClickedOnColorClass: classname,
      });
    }, 300);
  }

  removeSelectedObject() {
    this.sketchCanvas.removeSelected();

    this.updateSelectedKeyframe(
      "",
      this.state.activeKeyframeIndex,
      "fromRemoveSelected"
    );
  }

  exportState() {
    let { keyframes, _id, name, isModified, panTransform } =
        this.props.canvas.currentProject,
      { userDetails } = this.props.user,
      { bgKeyframes } = this.props.canvas.bgTimeline;

    let saveObj = {
      data: JSON.stringify({
        keyframes,
        bgKeyframes,
        panTransform,
      }),
      // user: userDetails.id,
      // name: this.refs.projectName.value.trim(),
      category: "advanced-doodle",
    };
    let file = new Blob([btoa(JSON.stringify(saveObj, null, 2))], {
      type: "text/plain",
    });
    saveAs(file, "export-advanced-doodle.ish");
  }

  importState(file) {
    let result;
    try {
      // only emply in case of ish file
      document.getElementById("importImg").value = "";
      result = JSON.parse(atob(file));
      const { currentProject } = this.props.canvas;
      const { keyframes: obj } = currentProject;
      let keyframes = Array.isArray(obj) ? obj : obj.keyframes;
      const anyNewFrames =
        keyframes[0].keyframe && keyframes[0].keyframe.objects.length;
      let projectDetailsObj = {
        project: {
          data: result.data,
          _id: "",
          name: "",
        },
        isModified: true,
        type: result.category,
        redirect: false,
      };
      let userAlertResponse = new Promise((resolve, reject) => {
        if (result.category !== "advanced-doodle") {
          this.props.swal({
            title: "Info!",
            text: Message.import.redirect[result.category],
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmAlert: () => {
              projectDetailsObj.redirect = result.category.toLowerCase();
              resolve(true);
            },
            cancelCallback: () => resolve(false),
          });
        } else if (currentProject._id && currentProject.name) {
          this.props.swal({
            title: "Info!",
            text: Message.import.savedProjectOverwrite,
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No, Start a New Project",
            confirmAlert: () => {
              projectDetailsObj.project._id = currentProject._id;
              projectDetailsObj.project.name = currentProject.name;
              resolve(true);
            },
            cancelCallback: () => resolve(true),
          });
        } else if (currentProject.isModified && anyNewFrames) {
          this.props.swal({
            title: "Info!",
            text: Message.import.overwrite,
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmAlert: () => resolve(true),
            cancelCallback: () => resolve(false),
          });
        } else {
          resolve(true);
        }
      });
      userAlertResponse.then((result) => {
        if (result) {
          this.loadProjectFile(projectDetailsObj);
          // this.context.sketchCanvas.generateImageURLFromObject(projectDetailsObj)
        }
      });
    } catch (e) {
      this.props.swal({
        title: "Error",
        text: "Invalid File",
        type: "error",
        confirmAlert: () => false,
      });
    }
  }

  loadProjectFile(file) {
    this.purgeCanvas();
    this.props.loadExistingProjectFile(file, (err, res) => {
      if (file.redirect) {
        this.props.history.push("/" + file.redirect);
      } else if (res) {
        this.setUp();
      }
      // Incase of redirect move this to another component
      this.props.swal({
        title: "Success",
        text: Message.import.success,
        type: "success",
        confirmAlert: () => false,
      });
    });
  }

  openProjectListModal() {
    // ref hook to pass data down to child - reset active color
    // Not an ideal way - Do it through Redux
    // pass current activeId
    this.projectListingComponentRef.current
      .getWrappedInstance()
      .activeProject(this.props.canvas.currentProject._id, "ref");

    // change this to react later
  }

  loadProjectFromPopup(id) {
    this.purgeCanvas();
    let projectDetailsObj = {
      _id: id,
      type: this.state.projectType,
    };
    this.props.getProjectDetailsById(projectDetailsObj, (res) => {
      if (res.success) {
        // setTimeout(() => this.setUp(), 1500);
        this.setUp();
        // not sure but isModified parameter is setting to true, it should be false after purging
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.props.swal({
          title: "Success",
          text: Message.loadFileFromPopupSuccess,
          type: "success",
          confirmAlert: () => window.location.reload(),
          // confirmAlert: () => false
        });
      }
    });
  }

  // save video in the format specified
  async saveVideo(type) {
    let { keyframes } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      context = this,
      loaderShown = false,
      delay = type && type === "gif" ? 150 : 200;

    // only check for first frame
    if (
      (!keyframes[0].keyframe || !keyframes[0].keyframe.objects.length) &&
      type === "gif" &&
      keyframes.length == 1
    ) {
      this.props.swal({
        title: "Error!",
        text: "Please draw something in order to move ahead!",
        type: "error",
        confirmAlert: () => false,
      });
      return;
    }

    context.encoder = new Whammy.Video();

    context.gif = new GIF({
      workers: 2,
      repeat: 0,
      quality: 10,
      height: context.state.canvasHeight,
      width: context.state.canvasWidth,
      // height: context.state.canvasHeight * scaleFactor,
      // width: context.state.canvasWidth * scaleFactor,
      workerScript: `${window.location.origin}/gif.worker.js`,
    });

    let bgs = [];

    // bgKeyframes.map((item, index) => {
    // context._bgGifCanvas.loadFromJSON(item.keyframe, () => {
    // bgs = [...bgs, context._bgGifCanvas.toDataURL()];
    // });
    // });
    bgs = await this.waitLoadFromJSON(context._bgGifCanvas, bgKeyframes);

    // this is added to fix the whitespace issue. Sometimes there is extra whitespace at the end in the video & GIF.
    let lastIndexWhereObjectExists = this.lastIndexWhereObjectExists(),
      lastIndexWhereObjectExistsInBg = this.lastIndexWhereObjectExistsInBg(),
      firstAt;

    let firstIndexWhereObjectExists = this.firstIndexWhereObjectExists(),
      firstIndexWhereObjectExistsInBg = this.firstIndexWhereObjectExistsInBg(),
      lastAt;

    if (firstIndexWhereObjectExistsInBg < firstIndexWhereObjectExists) {
      firstAt = firstIndexWhereObjectExistsInBg;
    } else if (firstIndexWhereObjectExistsInBg > firstIndexWhereObjectExists) {
      firstAt = firstIndexWhereObjectExists;
    } else if (firstIndexWhereObjectExists == firstIndexWhereObjectExistsInBg) {
      firstAt = firstIndexWhereObjectExists;
    }

    if (lastIndexWhereObjectExistsInBg < lastIndexWhereObjectExists) {
      lastAt = lastIndexWhereObjectExists;
    } else if (lastIndexWhereObjectExistsInBg > lastIndexWhereObjectExists) {
      lastAt = lastIndexWhereObjectExistsInBg;
    } else if (lastIndexWhereObjectExists == lastIndexWhereObjectExistsInBg) {
      lastAt = lastIndexWhereObjectExists;
    }

    for (const [index, item] of keyframes.entries()) {
      if (loaderShown == false) {
        context.props.showLoader();
        loaderShown = true;
      }

      if (index <= lastAt && index >= firstAt) {
        var tempCanvas = new fabric.StaticCanvas();
        tempCanvas.setDimensions({
          width: this.state.canvasWidth,
          height: this.state.canvasHeight,
        });
        await context.loadFromJSONInPromise(
          tempCanvas,
          index,
          item,
          delay,
          bgs[index]
        );
      }
    }

    context.gif.on("finished", function (blob) {
      context.props.hideLoader();
      var file;
      // for IE
      if (type === "gif" && navigator.msSaveBlob) {
        file = new Blob([blob], { type: "image/gif" });
        file.lastModifiedDate = Date.now();
        file.name = `${+new Date()}.gif`;
      } else {
        file = new File([blob], `${+new Date()}.gif`, {
          type: `image/gif`,
          lastModified: Date.now(),
        });
      }

      let videoObj = {
        gif: file,
        format: type,
      };
      if (type !== "gif") {
        context.props.generateVideo(videoObj, (res) => {
          saveAs(res.filename);
          context.gif = null;
        });
      } else {
        saveAs(videoObj.gif, "download.gif");
      }
    });

    setTimeout(() => {
      let { options } = context.gif;
      if (options.height != null && options.width != null) context.gif.render();
    }, 1000);
  }

  async playAnimation(repeat, playOrLoop) {
    let { keyframes } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      context = this,
      { animationSpeed } = this.state,
      delay,
      totalFrames = 0;
    var img;
    context.props.showLoader();
    context.setState({
      playing: playOrLoop == "play" ? true : false,
      looping: playOrLoop == "loop" ? true : false,
    });
    context.gif = new GIF({
      workers: 2,
      repeat: repeat,
      quality: 10,
      workerScript: `${window.location.origin}/gif.worker.js`,
    });

    if (animationSpeed === 3) {
      delay = 50;
    } else if (animationSpeed === 2) {
      delay = 150;
    } else {
      delay = 500;
    }

    let bgs = [],
      fgs = [];

    // bgKeyframes.map((item, index) => {
    // context._bgGifCanvas.loadFromJSON(item.keyframe, () => {
    // bgs = [...bgs, context._bgGifCanvas.toDataURL()];
    // });
    // });

    bgs = await this.waitLoadFromJSON(context._bgGifCanvas, bgKeyframes);

    keyframes.map((item, index) => {
      context._gifCanvas.loadFromJSON(item.keyframe, () => {
        fgs = [...fgs, context._gifCanvas.toDataURL()];
      });
    });

    let lastIndexWhereObjectExists = this.lastIndexWhereObjectExists(),
      lastIndexWhereObjectExistsInBg = this.lastIndexWhereObjectExistsInBg(),
      firstAt;

    let firstIndexWhereObjectExists = this.firstIndexWhereObjectExists(),
      firstIndexWhereObjectExistsInBg = this.firstIndexWhereObjectExistsInBg(),
      lastAt;

    if (firstIndexWhereObjectExistsInBg < firstIndexWhereObjectExists) {
      firstAt = firstIndexWhereObjectExistsInBg;
    } else if (firstIndexWhereObjectExistsInBg > firstIndexWhereObjectExists) {
      firstAt = firstIndexWhereObjectExists;
    } else if (firstIndexWhereObjectExists == firstIndexWhereObjectExistsInBg) {
      firstAt = firstIndexWhereObjectExists;
    }

    if (lastIndexWhereObjectExistsInBg < lastIndexWhereObjectExists) {
      lastAt = lastIndexWhereObjectExists;
    } else if (lastIndexWhereObjectExistsInBg > lastIndexWhereObjectExists) {
      lastAt = lastIndexWhereObjectExistsInBg;
    } else if (lastIndexWhereObjectExists == lastIndexWhereObjectExistsInBg) {
      lastAt = lastIndexWhereObjectExists;
    }

    for (const [index, item] of keyframes.entries()) {
      if (index <= lastAt && index >= firstAt) {
        var tempCanvas = new fabric.StaticCanvas();
        tempCanvas.setDimensions({
          width: context.state.canvasWidth,
          height: context.state.canvasHeight,
        });
        totalFrames = await context.loadFromJSONInPromise(
          tempCanvas,
          index,
          item,
          delay,
          bgs[index],
          totalFrames
        );
      }
    }
    // keyframes.map(async (item, index) => {
    // var tempCanvas = new fabric.StaticCanvas();
    // tempCanvas.setDimensions({
    // width: context.state.canvasWidth,
    // height: context.state.canvasHeight
    // });

    // await context.loadFromJSON(tempCanvas, index, item, delay, bgs[index]);

    // // tempCanvas.loadFromJSON(item.keyframe, () => {
    // // tempCanvas.backgroundColor = "white";
    // // fabric.Image.fromURL(bgs[index], function(oImg) {
    // // tempCanvas.setBackgroundImage(oImg);
    // // var img = new Image();
    // // img.src = tempCanvas.toDataURL();
    // // img.height = context.state.canvasHeight;
    // // img.width = context.state.canvasWidth;
    // // context.gif.addFrame(img, {
    // // delay: delay
    // // });
    // // totalFrames = totalFrames + 1;
    // // context._gifCanvas.renderAll();
    // // });
    // // });
    // });

    context.gif.on("finished", function (blob) {
      context.props.hideLoader();
      context.setState({
        playingGif: URL.createObjectURL(blob),
        traceEnabled: false,
      });
      if (repeat == -1) {
        // for no repeat of animation
        setTimeout(() => {
          context.stopAnimation();
        }, delay * totalFrames);
      }
    });

    setTimeout(() => {
      context.gif.render();
    }, 500);
  }

  update(canvas, transformPath, i) {
    const activeItem = canvas.getActiveObject();
    if (activeItem) {
      activeItem.set({
        top: transformPath[i].y,
        left: transformPath[i].x,
      });
      i++;

      // render all shapes
      canvas.renderAll();
      // queue next update
      if (i < transformPath.length) {
        //  if the counter < 10, call the loop function
        requestAnimationFrame(this.update(canvas, transformPath, i));
      } else {
        i = 1;
        const activeItem = canvas.getActiveObject();
        activeItem.set({
          cornerSize: 12,
          hasBorders: true,
          transparentCorners: false,
        });
      }
    }
  }

  async playTransform() {
    this.sketchCanvas.animatePath();
    /* _onMouseUp */
  }

  async handleMovePathChange(transformPath, activeKeyframeIndex) {
    this.props.updateMovePath({ transformPath, activeKeyframeIndex });
  }

  async waitLoadFromJSON(canvas, bgKeyframes) {
    return await Promise.all(
      bgKeyframes.map(async (item, index) => {
        return new Promise((resolve) =>
          canvas.loadFromJSON(item.keyframe, () => resolve(canvas.toDataURL()))
        );
      })
    );
  }

  loadFromJSONInPromise(tempCanvas, index, item, delay, bg, totalFrames) {
    let context = this;

    return new Promise((resolve) =>
      setTimeout(() => {
        tempCanvas.loadFromJSON(item.keyframe, () => {
          tempCanvas.backgroundColor = "white";
          fabric.Image.fromURL(bg, function (oImg) {
            tempCanvas.setBackgroundImage(oImg);
            var img = new Image();
            // img.src = tempCanvas.toDataURL({ multiplier: scaleFactor });
            img.src = tempCanvas.toDataURL();
            img.height = context.state.canvasHeight;
            img.width = context.state.canvasWidth;
            // img.height = context.state.canvasHeight * scaleFactor;
            // img.width = context.state.canvasWidth * scaleFactor;
            context.gif.addFrame(img, {
              delay: delay,
            });
            totalFrames = totalFrames + 1;
            context._gifCanvas.renderAll();
            resolve(totalFrames);
          });
        });
      }, 150)
    );
  }

  stopAnimation(from_ = "") {
    this.gif = null;

    if (from_ == "fromChangeAnimationSpeed") {
      // do not reset the values if coming from range slider debounced value
    } else {
      let { keyframes } = this.props.canvas.currentProject,
        { bgKeyframes } = this.props.canvas.bgTimeline;

      let lastIndexWhereObjectExists = this.lastIndexWhereObjectExists();

      this.setState(
        {
          playing: false,
          looping: false,
          sketchCanvas:
            keyframes[lastIndexWhereObjectExists] &&
            keyframes[lastIndexWhereObjectExists].keyframe,
          activeKeyframeIndex: lastIndexWhereObjectExists,
          playingGif: "",
          stopping: true,
          selectedSpace: "foreground",
        },
        () => {
          // if (this.state.selectedSpace == "foreground")
          this.sketchCanvas.toggleBackground(
            "setBackgroundFromBackgroundKey",
            bgKeyframes[lastIndexWhereObjectExists] &&
              bgKeyframes[lastIndexWhereObjectExists].keyframe
          );

          setTimeout(() => {
            this.setState({
              stopping: false,
            });
          }, 3000);

          // else
          // this.sketchCanvas.toggleBackground(
          // "setBackgroundOuterKeyframeKey",
          // keyframes[keyframes.length - 1].keyframe
          // );
        }
      );
    }

    this.checkIfPasteIsEnabled(false);
  }

  saveKeyframe(activeKeyframeIndex) {
    let aKeyframe = this.sketchCanvas ? this.sketchCanvas.toJSON() : undefined;
    if (aKeyframe && aKeyframe.objects.length === 0) {
      this.props.swal({
        title: "Error!",
        text: "Draw something in order to move ahead!",
        type: "error",
        confirmAlert: () => false,
      });
      return;
    }

    let newKeyframeObj = {
      keyframeIndex: activeKeyframeIndex + 1,
      saveBlankHistoryObj: true,
    };
    this.props.newKeyframe(newKeyframeObj);
    var historyObj = {
      history: [
        {
          version: "3.2.0",
          objects: [],
        },
      ],
    };
    this.currentProject.keyframes.push(historyObj);
    this.sketchCanvas.clear();

    this.setState({
      activeKeyframeIndex: activeKeyframeIndex + 1,
      traceEnabled: true,
    });
  }

  updateSelectedKeyframe(e, activeKeyframeIndex, fromWhere = "") {
    let currentJSONCanvas = this.sketchCanvas ? this.sketchCanvas.toJSON() : {},
      { keyframes } = this.currentProject,
      { bgKeyframes } = this.bgTimeline,
      { selectedSpace } = this.state,
      context = this,
      currentBlank = _.isEqual(currentJSONCanvas, {
        version: "3.2.0",
        objects: [],
      }),
      blankAlreadyExists,
      alreadyExist,
      isCurrentJSONequalToStoredJSON,
      lastIndexWhereObjectExists;

    if (selectedSpace == "foreground") {
      alreadyExist =
        keyframes[activeKeyframeIndex] &&
        keyframes[activeKeyframeIndex].hasOwnProperty("history") &&
        keyframes[activeKeyframeIndex].history.some((item) => {
          return _.isEqual(item.keyframe, {
            version: "3.2.0",
            objects: [],
          });
        });

      lastIndexWhereObjectExists = _.findLastIndex(keyframes, (e) => {
        return (
          e.keyframe && e.keyframe.objects && e.keyframe.objects.length > 0
        );
      });

      isCurrentJSONequalToStoredJSON =
        keyframes[activeKeyframeIndex] &&
        _.isEqual(
          currentJSONCanvas,
          keyframes[activeKeyframeIndex] &&
            keyframes[activeKeyframeIndex].keyframe
        );
    } else {
      alreadyExist =
        bgKeyframes[activeKeyframeIndex] &&
        bgKeyframes[activeKeyframeIndex].hasOwnProperty("history") &&
        bgKeyframes[activeKeyframeIndex].history.some((item) => {
          return _.isEqual(item.keyframe, {
            version: "3.2.0",
            objects: [],
          });
        });

      lastIndexWhereObjectExists = _.findLastIndex(bgKeyframes, (e) => {
        return (
          e.keyframe && e.keyframe.objects && e.keyframe.objects.length > 0
        );
      });

      isCurrentJSONequalToStoredJSON =
        bgKeyframes[activeKeyframeIndex] &&
        _.isEqual(
          currentJSONCanvas,
          bgKeyframes[activeKeyframeIndex] &&
            bgKeyframes[activeKeyframeIndex].keyframe
        );
    }

    if (fromWhere === "fromRemoveSelected" || fromWhere === "fromCutFunction") {
      blankAlreadyExists = false;
    } else {
      blankAlreadyExists = currentBlank && alreadyExist;
    }

    if (isCurrentJSONequalToStoredJSON || blankAlreadyExists) {
    } else {
      let updateKeyframeObj = {
        keyframe: context.sketchCanvas.toJSON(),
        index: activeKeyframeIndex,
        selectedSpace: selectedSpace,
        // keyframesBase64: context.sketchCanvas.toDataURL()
      };

      context._imageCanvas.loadFromJSON(currentJSONCanvas, (e) => {
        updateKeyframeObj.keyframesBase64 = context._imageCanvas.toDataURL();
        context.props.updateKeyframe(updateKeyframeObj);

        /******** keep history and current keyframe in component level variable *********/
        if (
          context.currentProject.keyframes[activeKeyframeIndex] &&
          selectedSpace == "foreground"
        ) {
          context.currentProject.keyframes[activeKeyframeIndex].keyframe =
            context.sketchCanvas.toJSON();
          context.currentProject.keyframes[activeKeyframeIndex].history = [
            ...context.currentProject.keyframes[activeKeyframeIndex].history,
            context.sketchCanvas.toJSON(),
          ];

          var lastDrawnObj = {
            index: activeKeyframeIndex,
            history: context.sketchCanvas.toJSON().objects.length,
          };
          var lastDrawnObjAlreadyExists = _.findIndex(
            context.currentProject.lastDrawnAt,
            (e) => {
              return (
                e.index == lastDrawnObj.index &&
                e.history == lastDrawnObj.history
              );
            }
          );
          // if (lastDrawnObjAlreadyExists < 0) {
          context.currentProject.lastDrawnAt = [
            ...context.currentProject.lastDrawnAt,
            lastDrawnObj,
          ];
          // }
        } else if (
          context.bgTimeline.bgKeyframes[activeKeyframeIndex] &&
          selectedSpace == "background"
        ) {
          context.bgTimeline.bgKeyframes[activeKeyframeIndex].keyframe =
            context.sketchCanvas.toJSON();
          context.bgTimeline.bgKeyframes[activeKeyframeIndex].history = [
            ...context.bgTimeline.bgKeyframes[activeKeyframeIndex].history,
            context.sketchCanvas.toJSON(),
          ];
          var lastDrawnObj = {
            index: activeKeyframeIndex,
            history: context.sketchCanvas.toJSON().objects.length,
            background: true,
          };

          var lastDrawnObjAlreadyExists = _.findIndex(
            context.bgTimeline.bgLastDrawnAt,
            (e) => {
              return (
                e.index == lastDrawnObj.index &&
                e.history == lastDrawnObj.history &&
                e.background == true
              );
            }
          );

          // if (lastDrawnObjAlreadyExists < 0) {
          context.bgTimeline.bgLastDrawnAt = [
            ...context.bgTimeline.bgLastDrawnAt,
            lastDrawnObj,
          ];
          // }
        }
        /******** keep history and current keyframe in component level variable *********/

        context.setState({
          // sketchCanvas: context.sketchCanvas.toJSON(),
          undoDisabled: true,
        });
      });

      if (
        keyframes[activeKeyframeIndex] &&
        keyframes[activeKeyframeIndex].addedExposure &&
        selectedSpace == "foreground"
      ) {
        context.updateAllExposuredFrames(
          keyframes[activeKeyframeIndex].addedExposureOfIndex,
          selectedSpace,
          activeKeyframeIndex
        );
      } else if (
        bgKeyframes[activeKeyframeIndex] &&
        bgKeyframes[activeKeyframeIndex].addedExposure &&
        selectedSpace == "background"
      ) {
        context.updateAllExposuredFrames(
          bgKeyframes[activeKeyframeIndex].addedExposureOfIndex,
          selectedSpace,
          activeKeyframeIndex
        );
      }

      if (
        lastIndexWhereObjectExists != -1 &&
        context.currentProject.keyframes[activeKeyframeIndex] &&
        context.currentProject.keyframes[activeKeyframeIndex].keyframe &&
        context.currentProject.keyframes[activeKeyframeIndex].keyframe
          .objects &&
        context.currentProject.keyframes[activeKeyframeIndex].keyframe.objects
          .length == 1 &&
        lastIndexWhereObjectExists + 1 != activeKeyframeIndex &&
        lastIndexWhereObjectExists < activeKeyframeIndex &&
        selectedSpace == "foreground"
      ) {
        context.updateKeyframeAsExposureWithPreviousDrawnObject(
          lastIndexWhereObjectExists,
          lastIndexWhereObjectExists,
          activeKeyframeIndex - 1,
          selectedSpace
        );
      } else if (
        lastIndexWhereObjectExists != -1 &&
        context.bgTimeline.bgKeyframes[activeKeyframeIndex] &&
        context.bgTimeline.bgKeyframes[activeKeyframeIndex].keyframe &&
        context.bgTimeline.bgKeyframes[activeKeyframeIndex].keyframe.objects &&
        context.bgTimeline.bgKeyframes[activeKeyframeIndex].keyframe.objects
          .length == 1 &&
        lastIndexWhereObjectExists + 1 != activeKeyframeIndex &&
        lastIndexWhereObjectExists < activeKeyframeIndex &&
        selectedSpace == "background"
      ) {
        context.updateKeyframeAsExposureWithPreviousDrawnObject(
          lastIndexWhereObjectExists,
          lastIndexWhereObjectExists,
          activeKeyframeIndex - 1,
          selectedSpace
        );
      }
    }
  }

  undoFromCurrentProject(index, status = "", secondLastHistory) {
    let context = this,
      { selectedSpace, activeKeyframeIndex } = this.state,
      lastHistoryIndex;

    if (selectedSpace == "foreground") {
      lastHistoryIndex =
        context.currentProject.keyframes[index] &&
        context.currentProject.keyframes[index].history &&
        context.currentProject.keyframes[index].history.length - 1; // last index from history object of a selected keyframe

      context.currentProject.keyframes[index].history = [
        ...context.currentProject.keyframes[index].history.slice(0, -1),
      ]; // removed last object from history

      if (status == "keyframeDeleted") {
        context.currentProject.keyframes[index].keyframe = {
          version: "3.2.0",
          objects: [],
        };

        context.currentProject.keyframes[index].history = [
          {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
            keyframesBase64: "",
          },
        ];

        if (context.currentProject.keyframes[index].addedExposure) {
          context.currentProject.keyframes[index].addedExposure = undefined;
        }

        if (context.currentProject.keyframes[index].duplicated) {
          delete context.currentProject.keyframes[index].duplicated;
        }
        if (
          context.currentProject.keyframes[index].addedExposureOfIndex !=
          undefined
        ) {
          context.currentProject.keyframes[index].addedExposureOfIndex =
            undefined;
        }
      }

      context.currentProject.lastDrawnAt = [
        ...context.currentProject.lastDrawnAt.slice(0, -1),
      ];

      if (secondLastHistory == 0 && index != 0) {
        context.currentProject.lastDrawnAt = [
          ...context.currentProject.lastDrawnAt.slice(0, -1),
        ];
      }

      let setFromHistory =
        context.currentProject.keyframes[index] &&
        context.currentProject.keyframes[index].history[
          context.currentProject.keyframes[index].history.length - 1
        ]; // object which will be used to set the current keyframe from history

      context.currentProject.keyframes[index].keyframe = setFromHistory;
      let updateKeyframeObj = {
        keyframe: setFromHistory,
        index: index,
      };

      if (setFromHistory == undefined) {
        let fromStore = Object.assign({}, context.props.canvas.currentProject);
        fromStore.keyframes[index].keyframe.objects.pop();
        let keyframeAfterPopping = fromStore;
        let exposuredKeyframeToBeReplacedWith =
          keyframeAfterPopping.keyframes[index].keyframe;

        setFromHistory = exposuredKeyframeToBeReplacedWith;

        updateKeyframeObj.keyframe = setFromHistory;
      }

      context._imageCanvas.loadFromJSON(setFromHistory, (e) => {
        updateKeyframeObj.keyframesBase64 = context._imageCanvas.toDataURL();
        context.props.updateKeyframe(updateKeyframeObj);
      });

      if (context.currentProject.keyframes[index].addedExposure) {
        let exposuredKeyframeToBeReplacedWith;

        exposuredKeyframeToBeReplacedWith = setFromHistory;

        context.updateAllExposuredFrames(
          context.currentProject.keyframes[index].addedExposureOfIndex,
          selectedSpace,
          activeKeyframeIndex,
          exposuredKeyframeToBeReplacedWith
        );
      }
    } else if (selectedSpace == "background") {
      lastHistoryIndex =
        context.bgTimeline.bgKeyframes[index] &&
        context.bgTimeline.bgKeyframes[index].history &&
        context.bgTimeline.bgKeyframes[index].history.length - 1; // last index from history object of a selected keyframe

      context.bgTimeline.bgKeyframes[index].history = [
        ...context.bgTimeline.bgKeyframes[index].history.slice(0, -1),
      ]; // removed last object from history

      if (status == "keyframeDeleted") {
        context.bgTimeline.bgKeyframes[index].keyframe = {
          version: "3.2.0",
          objects: [],
        };

        context.bgTimeline.bgKeyframes[index].history = [
          {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
            keyframesBase64: "",
          },
        ];

        if (context.bgTimeline.bgKeyframes[index].addedExposure) {
          context.bgTimeline.bgKeyframes[index].addedExposure = undefined;
        }

        if (context.bgTimeline.bgKeyframes[index].duplicated) {
          delete context.bgTimeline.bgKeyframes[index].duplicated;
        }
        if (
          context.bgTimeline.bgKeyframes[index].addedExposureOfIndex !=
          undefined
        ) {
          context.bgTimeline.bgKeyframes[index].addedExposureOfIndex =
            undefined;
        }
      }
      context.bgTimeline.bgLastDrawnAt = [
        ...context.bgTimeline.bgLastDrawnAt.slice(0, -1),
      ];

      if (secondLastHistory == 0 && index != 0) {
        context.bgTimeline.bgLastDrawnAt = [
          ...context.bgTimeline.bgLastDrawnAt.slice(0, -1),
        ];
      }

      let setFromHistory =
        context.bgTimeline.bgKeyframes[index] &&
        context.bgTimeline.bgKeyframes[index].history[
          context.bgTimeline.bgKeyframes[index].history.length - 1
        ]; // object which will be used to set the current keyframe from history

      context.bgTimeline.bgKeyframes[index].keyframe = setFromHistory;
      let updateKeyframeObj = {
        keyframe: setFromHistory,
        index: index,
      };
      if (setFromHistory == undefined) {
        let fromStore = Object.assign({}, context.props.canvas.bgTimeline);
        fromStore.bgKeyframes[index].keyframe.objects.pop();
        let keyframeAfterPopping = fromStore;
        let exposuredKeyframeToBeReplacedWith =
          keyframeAfterPopping.bgKeyframes[index].keyframe;

        setFromHistory = exposuredKeyframeToBeReplacedWith;

        updateKeyframeObj.keyframe = setFromHistory;
      }

      context._imageCanvas.loadFromJSON(setFromHistory, (e) => {
        updateKeyframeObj.keyframesBase64 = context._imageCanvas.toDataURL();
        updateKeyframeObj.selectedSpace = selectedSpace;
        context.props.updateKeyframe(updateKeyframeObj);
      });

      if (context.bgTimeline.bgKeyframes[index].addedExposure) {
        let exposuredKeyframeToBeReplacedWith = setFromHistory;

        context.updateAllExposuredFrames(
          context.bgTimeline.bgKeyframes[index].addedExposureOfIndex,
          selectedSpace,
          activeKeyframeIndex,
          exposuredKeyframeToBeReplacedWith
        );
      }
    }
  }

  undoAction() {
    let context = this,
      { playing, looping, selectedSpace } = this.state,
      { keyframes } = context.currentProject,
      { bgKeyframes } = context.bgTimeline,
      lastCanvasIndex,
      noOfItemsInHistory,
      lastIndexOfLastDrawnAT,
      isBackground,
      secondLastHistory,
      secondLastIndex;
    context.props.showLoader();

    if (selectedSpace == "foreground") {
      lastCanvasIndex =
        context.currentProject.lastDrawnAt[
          context.currentProject.lastDrawnAt.length - 1
        ].index;
      noOfItemsInHistory =
        context.currentProject.lastDrawnAt[
          context.currentProject.lastDrawnAt.length - 1
        ].history;
      lastIndexOfLastDrawnAT = context.currentProject.lastDrawnAt.length - 1;
      isBackground =
        context.currentProject.lastDrawnAt[
          context.currentProject.lastDrawnAt.length - 1
        ].background;
      secondLastHistory = context.currentProject.lastDrawnAt[
        lastIndexOfLastDrawnAT - 1
      ]
        ? context.currentProject.lastDrawnAt[lastIndexOfLastDrawnAT - 1].history
        : 0;
      secondLastIndex = context.currentProject.lastDrawnAt[
        lastIndexOfLastDrawnAT - 1
      ]
        ? context.currentProject.lastDrawnAt[lastIndexOfLastDrawnAT - 1].index
        : 0;

      if (
        this.currentProject.keyframes[lastCanvasIndex].addedExposure &&
        this.state.activeKeyframeIndex != lastCanvasIndex
      ) {
        this.setState(
          {
            sketchCanvas: keyframes[lastCanvasIndex].keyframe,
            activeKeyframeIndex: lastCanvasIndex,
          },
          () => {
            this.sketchCanvas.toggleBackground(
              "setBackgroundFromBackgroundKey",
              bgKeyframes[lastCanvasIndex].keyframe
            );
            context.props.hideLoader();
          }
        );
        return;
      }
    } else {
      lastCanvasIndex =
        context.bgTimeline.bgLastDrawnAt[
          context.bgTimeline.bgLastDrawnAt.length - 1
        ].index;
      noOfItemsInHistory =
        context.bgTimeline.bgLastDrawnAt[
          context.bgTimeline.bgLastDrawnAt.length - 1
        ].history;
      lastIndexOfLastDrawnAT = context.bgTimeline.bgLastDrawnAt.length - 1;
      isBackground =
        context.bgTimeline.bgLastDrawnAt[
          context.bgTimeline.bgLastDrawnAt.length - 1
        ].background;
      secondLastHistory = context.bgTimeline.bgLastDrawnAt[
        lastIndexOfLastDrawnAT - 1
      ]
        ? context.bgTimeline.bgLastDrawnAt[lastIndexOfLastDrawnAT - 1].history
        : 0;
      secondLastIndex = context.bgTimeline.bgLastDrawnAt[
        lastIndexOfLastDrawnAT - 1
      ]
        ? context.bgTimeline.bgLastDrawnAt[lastIndexOfLastDrawnAT - 1].index
        : 0;

      if (
        context.bgTimeline.bgKeyframes[lastCanvasIndex] &&
        context.bgTimeline.bgKeyframes[lastCanvasIndex].addedExposure &&
        this.state.activeKeyframeIndex != lastCanvasIndex
      ) {
        this.setState(
          {
            sketchCanvas: bgKeyframes[lastCanvasIndex].keyframe,
            activeKeyframeIndex: lastCanvasIndex,
          },
          () => {
            this.sketchCanvas.toggleBackground(
              "setBackgroundOuterKeyframeKey",
              keyframes[lastCanvasIndex].keyframe
            );
            context.props.hideLoader();
          }
        );
        return;
      }
    }

    if (selectedSpace == "foreground") {
      if (
        (context.currentProject.keyframes[secondLastIndex].duplicated &&
          (secondLastHistory == 0 || secondLastHistory == 1)) ||
        (noOfItemsInHistory == 0 &&
          context.currentProject.keyframes.length != 1)
      ) {
        let deleteKeyframeObj = {
          index: lastCanvasIndex,
          selectedSpace,
        };

        if (
          context.currentProject.keyframes[lastCanvasIndex].addedExposure &&
          context.currentProject.keyframes[lastCanvasIndex].singleExposure ==
            undefined &&
          context.currentProject.keyframes[lastCanvasIndex].dntReset ==
            undefined
        ) {
          let { addedExposureOfIndex } =
            context.currentProject.keyframes[lastCanvasIndex];

          context.resetAllExposuredFrames(addedExposureOfIndex);
          context.props.hideLoader();
          return;
        }
        this.undoFromCurrentProject(
          lastCanvasIndex,
          "keyframeDeleted",
          secondLastHistory
        );

        context.props.emptyKeyframe(deleteKeyframeObj);
        lastCanvasIndex =
          lastCanvasIndex - 1 == -1 ? lastCanvasIndex : lastCanvasIndex - 1;
        context._imgSequence.loadFromJSON(
          keyframes[lastCanvasIndex].keyframe,
          () => {
            context.setState(
              {
                sketchCanvas: context._imgSequence.toJSON(),
                activeKeyframeIndex: lastCanvasIndex,
              },
              () => {
                context.sketchCanvas.toggleBackground(
                  "setBackgroundFromBackgroundKey",
                  bgKeyframes[lastCanvasIndex] != undefined
                    ? bgKeyframes[lastCanvasIndex].keyframe
                    : []
                );
              }
            );
          }
        );
      } else {
        this.undoFromCurrentProject(lastCanvasIndex);
        context._imgSequence.loadFromJSON(
          keyframes[lastCanvasIndex].keyframe,
          () => {
            context.setState(
              {
                sketchCanvas: context._imgSequence.toJSON(),
                activeKeyframeIndex: lastCanvasIndex,
              },
              () => {
                context.sketchCanvas.toggleBackground(
                  "setBackgroundFromBackgroundKey",
                  bgKeyframes[lastCanvasIndex] != undefined
                    ? bgKeyframes[lastCanvasIndex].keyframe
                    : []
                );
              }
            );
          }
        );
      }

      if (
        keyframes.length - 1 === 0 &&
        keyframes[0].history &&
        keyframes[0].history.length === 1 &&
        keyframes[0].history[0].keyframe &&
        keyframes[0].history[0].keyframe.objects.length === 0
      )
        context.setState({
          undoDisabled: false,
        });
    } else {
      if (
        (context.bgTimeline.bgKeyframes[secondLastIndex].duplicated &&
          (secondLastHistory == 0 || secondLastHistory == 1)) ||
        (noOfItemsInHistory == 0 && context.bgTimeline.bgKeyframes.length != 1)
      ) {
        let deleteKeyframeObj = {
          index: lastCanvasIndex,
          selectedSpace,
        };

        if (
          context.bgTimeline.bgKeyframes[lastCanvasIndex].addedExposure &&
          context.bgTimeline.bgKeyframes[lastCanvasIndex].singleExposure ==
            undefined &&
          context.bgTimeline.bgKeyframes[lastCanvasIndex].dntReset == undefined
        ) {
          let { addedExposureOfIndex } =
            context.bgTimeline.bgKeyframes[lastCanvasIndex];

          context.resetAllExposuredFrames(addedExposureOfIndex);
          context.props.hideLoader();
          return;
        }

        this.undoFromCurrentProject(
          lastCanvasIndex,
          "keyframeDeleted",
          secondLastHistory
        );
        // this.handleLastDrawnArrOnDeleteKeyframe(lastCanvasIndex);

        // context.currentProject.keyframes.splice(lastCanvasIndex, 1); // delete keyrframe
        // context.props.deleteKeyframe(deleteKeyframeObj);
        context.props.emptyKeyframe(deleteKeyframeObj);
        lastCanvasIndex =
          lastCanvasIndex - 1 == -1 ? lastCanvasIndex : lastCanvasIndex - 1;
        context._imgSequence.loadFromJSON(
          bgKeyframes[lastCanvasIndex].keyframe,
          () => {
            context.setState(
              {
                sketchCanvas: context._imgSequence.toJSON(),
                activeKeyframeIndex: lastCanvasIndex,
              },
              () => {
                context.sketchCanvas.toggleBackground(
                  "setBackgroundOuterKeyframeKey",
                  keyframes[lastCanvasIndex].keyframe
                );
              }
            );
          }
        );
      } else {
        this.undoFromCurrentProject(lastCanvasIndex);
        context._imgSequence.loadFromJSON(
          bgKeyframes[lastCanvasIndex].keyframe,
          () => {
            context.setState(
              {
                sketchCanvas: context._imgSequence.toJSON(),
                activeKeyframeIndex: lastCanvasIndex,
              },
              () => {
                context.sketchCanvas.toggleBackground(
                  "setBackgroundOuterKeyframeKey",
                  keyframes[lastCanvasIndex].keyframe
                );
              }
            );
          }
        );
      }

      if (
        bgKeyframes.length - 1 === 0 &&
        bgKeyframes[0].history &&
        bgKeyframes[0].history.length === 1 &&
        bgKeyframes[0].history[0].keyframe &&
        bgKeyframes[0].history[0].keyframe.objects.length === 0
      )
        context.setState({
          undoDisabled: false,
        });
    }

    if (playing || looping) {
      this.stopAnimation();
    }
    context.props.hideLoader();
  }

  resetAllExposuredFrames(val) {
    let context = this,
      newLastDrawnAt = [],
      newBgLastDrawnAt = [],
      { selectedSpace } = this.state,
      { lastDrawnAt } = context.currentProject,
      { bgLastDrawnAt } = context.bgTimeline,
      resetObj = {
        val,
        selectedSpace,
      };
    if (selectedSpace == "foreground") {
      _.filter(lastDrawnAt, (e) => {
        if (e.exposured && e.exposuredFrom == val) {
        } else {
          newLastDrawnAt = [...newLastDrawnAt, e];
        }
      });

      context.props.resetAllExposuredFramesOfSpecificAddedExposureOfIndex(
        resetObj
      );
      context.currentProject.lastDrawnAt = newLastDrawnAt;

      let newKeyframes = [];
      _.filter(context.currentProject.keyframes, (e) => {
        if (
          e.addedExposure &&
          e.addedExposureOfIndex == val &&
          e.dntReset == undefined
        ) {
          e.addedExposure = undefined;
          e.addedExposureOfIndex = undefined;
          e.keyframe = {
            version: "3.2.0",
            objects: [],
          };
          e.history = [
            {
              keyframe: {
                version: "3.2.0",
                objects: [],
              },
              keyframesBase64: "",
            },
          ];
          newKeyframes = [...newKeyframes, e];
        } else {
          newKeyframes = [...newKeyframes, e];
        }
      });

      context.currentProject.keyframes = newKeyframes;

      context.setState({
        sketchCanvas:
          context.currentProject.keyframes[
            context.currentProject.lastDrawnAt[
              context.currentProject.lastDrawnAt.length - 1
            ].index
          ].keyframe,
        activeKeyframeIndex:
          context.currentProject.lastDrawnAt[
            context.currentProject.lastDrawnAt.length - 1
          ].index,
      });
    } else {
      _.filter(bgLastDrawnAt, (e) => {
        if (e.exposured && e.exposuredFrom == val) {
        } else {
          newBgLastDrawnAt = [...newBgLastDrawnAt, e];
        }
      });

      context.props.resetAllExposuredFramesOfSpecificAddedExposureOfIndex(
        resetObj
      );
      context.bgTimeline.bgLastDrawnAt = newBgLastDrawnAt;

      let newBgKeyframes = [];
      _.filter(context.bgTimeline.bgKeyframes, (e) => {
        if (
          e.addedExposure &&
          e.addedExposureOfIndex == val &&
          e.dntReset == undefined
        ) {
          e.addedExposure = undefined;
          e.addedExposureOfIndex = undefined;
          e.keyframe = {
            version: "3.2.0",
            objects: [],
          };
          e.history = [
            {
              keyframe: {
                version: "3.2.0",
                objects: [],
              },
              keyframesBase64: "",
            },
          ];
          newBgKeyframes = [...newBgKeyframes, e];
        } else {
          newBgKeyframes = [...newBgKeyframes, e];
        }
      });

      context.bgTimeline.bgKeyframes = newBgKeyframes;
      context.setState({
        sketchCanvas:
          context.bgTimeline.bgKeyframes[
            context.bgTimeline.bgLastDrawnAt[
              context.bgTimeline.bgLastDrawnAt.length - 1
            ].index
          ].keyframe,
        activeKeyframeIndex:
          context.bgTimeline.bgLastDrawnAt[
            context.bgTimeline.bgLastDrawnAt.length - 1
          ].index,
      });
    }
  }

  nextPressed() {
    let { keyframes } = this.props.canvas.currentProject;
    let { keyframesLimit, activeKeyframeIndex } = this.state;

    if (activeKeyframeIndex === keyframes.length - 1) {
      this.saveKeyframe(activeKeyframeIndex);
    } else {
      let keyframeToBeDrawn = keyframes[activeKeyframeIndex + 1].keyframe
        ? keyframes[activeKeyframeIndex + 1].keyframe
        : "";

      if (keyframeToBeDrawn === "") {
        this.sketchCanvas.clear();
      }
      this.setState(
        {
          sketchCanvas: keyframeToBeDrawn,
          activeKeyframeIndex: activeKeyframeIndex + 1,
          // traceEnabled: !this.state.traceEnabled
        },
        () => {
          // setTimeout(() => {
          // this.setState({
          // traceEnabled: !this.state.traceEnabled
          // });
          // }, 5);
        }
      );
    }
  }

  prevPressed() {
    let { keyframes } = this.props.canvas.currentProject;
    let { keyframesLimit, activeKeyframeIndex } = this.state;
    if (activeKeyframeIndex === 0) {
      return;
    } else {
      this.setState(
        {
          sketchCanvas: keyframes[activeKeyframeIndex - 1].keyframe,
          activeKeyframeIndex: activeKeyframeIndex - 1,
          // traceEnabled: !this.state.traceEnabled
        },
        () => {
          // setTimeout(() => {
          // this.setState({
          // traceEnabled: !this.state.traceEnabled
          // });
          // }, 5);
        }
      );
    }
  }

  addNewPressedFromTimeline() {
    let { keyframes } = this.props.canvas.currentProject;
    let lastKeyframe = keyframes.length - 1;
    if (
      keyframes[keyframes.length - 1].hasOwnProperty("keyframe") &&
      keyframes[keyframes.length - 1].keyframe.objects.length > 0
    ) {
      let newKeyframeObj = {
        keyframeIndex: lastKeyframe + 1,
        saveBlankHistoryObj: true,
      };
      this.saveKeyframe(lastKeyframe);
    } else {
      this.setState({
        sketchCanvas: keyframes[keyframes.length - 1],
        activeKeyframeIndex: lastKeyframe,
      });
    }
  }

  backBtnActiveInactive() {
    let { activeKeyframeIndex } = this.state;
    if (activeKeyframeIndex === 0) return false;
    else return true;
  }

  nextBtnActiveInactive() {
    let { keyframes } = this.currentProject;
    let { activeKeyframeIndex } = this.state;
    let haveObjectsInKeyframe =
      keyframes.length > 0 &&
      keyframes[activeKeyframeIndex] &&
      keyframes[activeKeyframeIndex].hasOwnProperty("keyframe") &&
      keyframes[activeKeyframeIndex].keyframe != undefined &&
      keyframes[activeKeyframeIndex].hasOwnProperty("history") &&
      keyframes[activeKeyframeIndex].keyframe.objects.length > 0;

    if (
      !haveObjectsInKeyframe &&
      keyframes.length > 0 &&
      activeKeyframeIndex === keyframes.length - 1
    )
      return false;
    else return true;
  }

  checkIfPlayButtonIsEnabled() {
    let context = this,
      { keyframes } = context.currentProject,
      { bgKeyframes } = context.bgTimeline;
    if (keyframes.length > 0) {
      let count = _.countBy(keyframes, function (frame) {
        return frame.keyframe &&
          frame.keyframe.objects &&
          frame.keyframe.objects.length > 0
          ? "exists"
          : "doesntExist";
      });
      let bgCount = _.countBy(bgKeyframes, function (frame) {
        return frame.keyframe &&
          frame.keyframe.objects &&
          frame.keyframe.objects.length > 0
          ? "exists"
          : "doesntExist";
      });
      if (count.exists > 1 || bgCount.exists > 1) return true;
      else return false;
    } else return false;

    return false;
  }

  // importImg(e) {
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   reader.onloadend = () => {
  //     if (file.type) {
  //       this.setState({ import_img: true });
  //       this.sketchCanvas.addImg(reader.result);
  //     } else {
  //       this.importState(reader.result);
  //     }
  //   };
  //   if (file && file.type) reader.readAsDataURL(file);
  //   else if (
  //     file.name.substr(file.name.lastIndexOf("\\") + 1).split(".")[1] == "ish"
  //   )
  //     reader.readAsText(file);
  // }
  importImg(e) {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = (e) => {
      if (file.type && !file.name.endsWith(".txt")) {
        this.setState({ import_img: true });
        this.sketchCanvas.addImg(reader.result);
      } else {
        this.importState(reader.result);
        this.sketchCanvas.addImg(reader.result);
      }
    };
    if (file && file.name.endsWith(".txt")) reader.readAsText(file);
    else if (file && file.type) reader.readAsDataURL(file);
    else if (
      file.name.substr(file.name.lastIndexOf("\\") + 1).split(".")[1] == "ish"
    )
      reader.readAsText(file);
  }

  newProjectOrGoToHome(type) {
    let { keyframes } = this.currentProject,
      { bgKeyframes } = this.bgTimeline,
      { selectedSpace } = this.state,
      { isModified, _id, name } = this.props.canvas.currentProject,
      shallAsk;

    if (selectedSpace == "foreground") {
      shallAsk = keyframes.some((e) => {
        if (e.keyframe && e.keyframe.objects) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      });
    } else {
      shallAsk = bgKeyframes.some((e) => {
        if (e.keyframe && e.keyframe.objects) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      });
    }

    if (shallAsk && isModified) {
      this.setState({
        prompt: "alertBox",
        promptTowards: type,
      });
      $("#unsavedChanges").trigger("click");
    } else {
      if (type !== "openIcon") this.purgeCanvas();
      setTimeout(() => {
        if (type === "goToHome") {
          this.props.history.push("/dashboard");
        } else if (type === "openIcon") {
          this.purgeCanvas();
          this.props.history.push("/my-projects");

          // this.openProjectListModal();
        } else window.location.reload();
      }, 500);
    }
  }

  changeBrushSize(e) {
    this.setState({
      brushSize: Number(e.target.value) < 2 ? 1 : Number(e.target.value) / 5,
    });
  }

  changeAnimationSpeed(e) {
    let { playing, looping } = this.state;
    this.setState({
      animationSpeed: Number(e.target.value),
    });
    this.debouncedAnimationSpeed();
  }

  debouncedAnimationSpeed() {
    let { playing, looping } = this.state;

    if (looping) {
      this.stopAnimation("fromChangeAnimationSpeed");
      this.playAnimation(0, "loop");
    } else if (playing) {
      this.stopAnimation("fromChangeAnimationSpeed");
      this.playAnimation(-1, "play");
    }
  }

  enableDisableSendToBack(sendToBackEnabled) {
    this.setState({
      sendToBackEnabled: sendToBackEnabled,
    });
  }

  enableDisableTrace(traceEnabled) {
    this.setState({
      traceEnabled: traceEnabled,
    });
  }

  setSelectedKeyframeFromTimeline(index, calledFrom = "") {
    let { keyframes } = this.currentProject,
      { bgKeyframes } = this.bgTimeline,
      context = this,
      keyframeAdded = false,
      { selectedSpace, activeKeyframeIndex } = context.state,
      keyframeToBeDrawn;
    if (selectedSpace == "foreground") {
      keyframeToBeDrawn =
        keyframes[index] && keyframes[index].keyframe
          ? keyframes[index].keyframe
          : {};
    } else if (selectedSpace == "background") {
      keyframeToBeDrawn =
        bgKeyframes[index] && bgKeyframes[index].keyframe
          ? bgKeyframes[index].keyframe
          : {};
    }

    context.setState(
      {
        sketchCanvas: "",
        activeKeyframeIndex: index,
      },
      () => {
        context.setState(
          {
            sketchCanvas: keyframeToBeDrawn,
          },
          () => {
            if (selectedSpace == "background") {
              if (keyframes[index] == undefined) {
                context.sketchCanvas.toggleBackground(
                  "setBackgroundOuterKeyframeKey",
                  {
                    version: "3.2.0",
                    objects: [],
                  }
                );
                return;
              }

              context.sketchCanvas.toggleBackground(
                "setBackgroundOuterKeyframeKey",
                keyframes[index].keyframe
              );
            } else {
              if (bgKeyframes[index] == undefined) {
                context.sketchCanvas.toggleBackground(
                  "setBackgroundFromBackgroundKey",
                  {
                    version: "3.2.0",
                    objects: [],
                  }
                );
                return;
              }
              context.sketchCanvas.toggleBackground(
                "setBackgroundFromBackgroundKey",
                bgKeyframes[index].keyframe
              );
            }
          }
        );
      }
    );
  }

  addKeyframeAt(index, calledFrom = "") {
    let addNewKeyframeObj = {
        index,
        advanced: true,
      },
      lastDrawnAt = [],
      bgLastDrawnAt = [],
      { selectedSpace } = this.state;
    this.props.addNewKeyframeAt(addNewKeyframeObj);
    this.currentProject.keyframes.splice(index, 0, {
      history: [],
      keyframe: {
        version: "3.2.0",
        objects: [],
      },
    }); // added a new keyframe on(foreground) the index specified

    this.bgTimeline.bgKeyframes.splice(index, 0, {
      history: [],
      keyframe: {
        version: "3.2.0",
        objects: [],
      },
      index,
    }); // added a new keyframe on(background) the index specified

    _.filter(this.currentProject.lastDrawnAt, (e) => {
      lastDrawnAt = [...lastDrawnAt, e];
    });

    _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
      bgLastDrawnAt = [...bgLastDrawnAt, e];
    });

    let addedAtIndex = index,
      lastDrawnObj = {
        index: addedAtIndex,
        history: 0,
      },
      bgLastDrawnObj = {
        index: addedAtIndex,
        history: 0,
        background: true,
      };

    if (selectedSpace == "foreground")
      this.currentProject.lastDrawnAt.push(lastDrawnObj);
    else if (selectedSpace == "background")
      this.bgTimeline.bgLastDrawnAt.push(bgLastDrawnObj);

    if (calledFrom != "cellClicked")
      this.setSelectedKeyframeFromTimeline(index, "addKeyframe");
  }

  addNewBlankKeyframe(index) {
    let addNewKeyframeObj = {
        index,
        advanced: true,
      },
      lastDrawnAt = [],
      bgLastDrawnAt = [],
      { selectedSpace, looping, playing } = this.state;

    if (playing || looping) {
      return;
    }
    this.props.showLoader();
    this.props.addNewKeyframeAt(addNewKeyframeObj);
    this.currentProject.keyframes.splice(index, 0, {
      history: [],
    }); // added a new keyframe on(foreground) the index specified

    this.bgTimeline.bgKeyframes.splice(index, 0, {
      history: [],
      keyframe: {
        version: "3.2.0",
        objects: [],
      },
      index,
    }); // added a new keyframe on(background) the index specified

    this.setSelectedKeyframeFromTimeline(index, "addKeyframe");

    if (selectedSpace == "foreground") {
      setTimeout(() => {
        _.filter(this.currentProject.lastDrawnAt, (e) => {
          lastDrawnAt = [...lastDrawnAt, e];
        });

        let addedAtIndex = index;

        lastDrawnAt[lastDrawnAt.length - 1].index = addedAtIndex;

        lastDrawnAt.map((data, key) => {
          if (key == lastDrawnAt.length - 1) {
          } else if (addedAtIndex <= data.index) {
            data.index = data.index + 1;
          }
          if (lastDrawnAt.length - 1 === key) {
            setTimeout(() => {
              this.props.hideLoader();
            }, 500);
          }
        });
        this.currentProject.lastDrawnAt = lastDrawnAt;
      }, 500);
    } else {
      setTimeout(() => {
        _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
          bgLastDrawnAt = [...bgLastDrawnAt, e];
        });

        let addedAtIndex = index;

        bgLastDrawnAt[bgLastDrawnAt.length - 1].index = addedAtIndex;

        bgLastDrawnAt.map((data, key) => {
          if (key == bgLastDrawnAt.length - 1) {
          } else if (addedAtIndex <= data.index) {
            data.index = data.index + 1;
          }
          if (bgLastDrawnAt.length - 1 === key) {
            setTimeout(() => {
              this.props.hideLoader();
            }, 500);
          }
        });
        this.bgTimeline.bgLastDrawnAt = bgLastDrawnAt;
      }, 500);
    }
  }

  addExposure(index) {
    let exposureObj = {
        index,
        activeKeyframeIndex: this.state.activeKeyframeIndex,
        selectedSpace: this.state.selectedSpace,
      },
      { looping, playing } = this.state,
      lastDrawnAt = [],
      bgLastDrawnAt = [],
      addExposureObj = {};

    if (looping || playing) {
      return;
    }

    this.props.showLoader();
    this.props.addExposureAt(exposureObj);

    // addExposureObj = {
    // keyframeofIndex: index - 1,
    // at: index - 1,
    // selectedSpace: this.state.selectedSpace
    // };
    // this.props.updateKeyframeAsExposureWithPreviousDrawnObject(addExposureObj);

    if (this.state.selectedSpace == "foreground") {
      // this.currentProject.keyframes[index - 1] = {
      // history: [],
      // addedExposure: true,
      // addedExposureOfIndex: index - 1,
      // keyframe: this.currentProject.keyframes[index - 1].keyframe
      // };

      let persistedHistory = this.currentProject.keyframes[index - 1].history;
      this.currentProject.keyframes[index - 1] = {
        history: persistedHistory,
        addedExposure: true,
        dntReset: true,
        addedExposureOfIndex: index - 1,
        keyframe: this.currentProject.keyframes[index - 1].keyframe,
      };

      this.currentProject.keyframes.splice(index, 0, {
        history: [
          {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
            keyframesBase64: "",
          },
        ],
        addedExposure: true,
        singleExposure: true,
        addedExposureOfIndex: exposureObj.activeKeyframeIndex,
        keyframe: this.currentProject.keyframes[index - 1].keyframe,
      }); // added a new keyframe on the index specified (foreground)

      let lastIndexFromLastDrawnAt =
        this.currentProject.lastDrawnAt.length > 0
          ? this.currentProject.lastDrawnAt[
              this.currentProject.lastDrawnAt.length - 1
            ].index
          : 0;

      this.currentProject.lastDrawnAt.push({
        index: lastIndexFromLastDrawnAt + 1,
        history: 1,
        exposured: true,
        singleExposure: true,
        exposuredFrom: exposureObj.activeKeyframeIndex,
      });

      if (this.bgTimeline.bgKeyframes[index]) {
        this.bgTimeline.bgKeyframes.push({
          history: [],
          isBackground: true,
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: this.bgTimeline.bgKeyframes.length,
        });

        let handleObj = {
          index: this.bgTimeline.bgKeyframes.length,
          atEnd: true,
        };
        this.props.handleBgOnAddExposure(handleObj);
      } else {
        this.bgTimeline.bgKeyframes.splice(index, 0, {
          history: [],
          isBackground: true,
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: index,
        });

        let handleObj = {
          index,
          atEnd: false,
        };
        this.props.handleBgOnAddExposure(handleObj);
      }

      setTimeout(() => {
        _.filter(this.currentProject.lastDrawnAt, (e) => {
          lastDrawnAt = [...lastDrawnAt, e];
        });

        let addedAtIndex = lastIndexFromLastDrawnAt + 1;

        lastDrawnAt[lastDrawnAt.length - 1].index = addedAtIndex;
        lastDrawnAt[lastDrawnAt.length - 1].history = 1;

        lastDrawnAt.map((data, key) => {
          if (key == lastDrawnAt.length - 1) {
          } else if (addedAtIndex <= data.index) {
            data.index = data.index + 1;
          }

          if (lastDrawnAt.length - 1 === key) {
            setTimeout(() => {
              this.props.hideLoader();
            }, 500);
          }
        });
        this.currentProject.lastDrawnAt = lastDrawnAt;
      }, 500);
    } else if (this.state.selectedSpace == "background") {
      let persistedHistory = this.bgTimeline.bgKeyframes[index - 1].history;
      this.bgTimeline.bgKeyframes[index - 1] = {
        history: persistedHistory,
        addedExposure: true,
        dntReset: true,
        addedExposureOfIndex: index - 1,
        keyframe: this.bgTimeline.bgKeyframes[index - 1].keyframe,
      };

      this.bgTimeline.bgKeyframes.splice(index, 0, {
        history: [
          {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
            keyframesBase64: "",
          },
        ],
        addedExposure: true,
        singleExposure: true,
        addedExposureOfIndex: exposureObj.activeKeyframeIndex,
        keyframe: this.bgTimeline.bgKeyframes[index - 1].keyframe,
      }); // added a new keyframe on the index specified (background)

      let lastIndexFromLastDrawnAt =
        this.bgTimeline.bgLastDrawnAt[this.bgTimeline.bgLastDrawnAt.length - 1]
          .index;

      this.bgTimeline.bgLastDrawnAt.push({
        index: lastIndexFromLastDrawnAt + 1,
        history: 1,
        exposured: true,
        singleExposure: true,
        exposuredFrom: exposureObj.activeKeyframeIndex,
      });

      if (this.currentProject.keyframes[index]) {
        this.currentProject.keyframes.push({
          history: [],
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: this.currentProject.keyframes.length,
        });

        let handleObj = {
          index: this.currentProject.keyframes.length,
          atEnd: true,
        };
        this.props.handleFgOnAddExposure(handleObj);
      } else {
        this.currentProject.keyframes.splice(index, 0, {
          history: [],
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: index,
        });

        let handleObj = {
          index,
          atEnd: false,
        };
        this.props.handleFgOnAddExposure(handleObj);
      }

      setTimeout(() => {
        _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
          bgLastDrawnAt = [...bgLastDrawnAt, e];
        });

        let addedAtIndex = lastIndexFromLastDrawnAt + 1;

        bgLastDrawnAt[bgLastDrawnAt.length - 1].index = addedAtIndex;
        bgLastDrawnAt[bgLastDrawnAt.length - 1].history = 1;

        bgLastDrawnAt.map((data, key) => {
          if (key == bgLastDrawnAt.length - 1) {
          } else if (addedAtIndex <= data.index) {
            data.index = data.index + 1;
          }

          if (bgLastDrawnAt.length - 1 === key) {
            setTimeout(() => {
              this.props.hideLoader();
            }, 500);
          }
        });
        this.bgTimeline.bgLastDrawnAt = bgLastDrawnAt;
      }, 500);
    }

    // this.setSelectedKeyframeFromTimeline(index, "duplicateKeyframe");
  }

  duplicateKeyframe(index) {
    let { selectedSpace, looping, playing } = this.state,
      duplicateKeyframeObj = {
        index,
        selectedSpace,
      },
      lastDrawnAt = [],
      bgLastDrawnAt = [];

    if (looping || playing) {
      return;
    }

    this.props.showLoader();
    this.props.duplicateKeyframeAt(duplicateKeyframeObj);

    if (selectedSpace == "foreground") {
      this.currentProject.keyframes.splice(index, 0, {
        history: [
          {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
            keyframesBase64: "",
          },
        ],
        duplicated: true,
        keyframe: this.currentProject.keyframes[index - 1].keyframe,
      }); // added a new keyframe on the index specified
      this.setSelectedKeyframeFromTimeline(index, "duplicateKeyframe");
      this.currentProject.lastDrawnAt.push({ index: index, history: 1 });

      if (this.bgTimeline.bgKeyframes[index]) {
        this.bgTimeline.bgKeyframes.push({
          history: [],
          isBackground: true,
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: this.bgTimeline.bgKeyframes.length,
        });

        let handleObj = {
          index: this.bgTimeline.bgKeyframes.length,
          atEnd: true,
        };
        this.props.handleBgOnDuplicateKeyframe(handleObj);
      } else {
        this.bgTimeline.bgKeyframes.splice(index, 0, {
          history: [],
          isBackground: true,
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: index,
        });

        let handleObj = {
          index,
          atEnd: false,
        };
        this.props.handleBgOnDuplicateKeyframe(handleObj);
      }

      setTimeout(() => {
        _.filter(this.currentProject.lastDrawnAt, (e) => {
          lastDrawnAt = [...lastDrawnAt, e];
        });

        let addedAtIndex = index;

        lastDrawnAt[lastDrawnAt.length - 1].index = addedAtIndex;
        lastDrawnAt[lastDrawnAt.length - 1].history = 1;

        lastDrawnAt.map((data, key) => {
          if (key == lastDrawnAt.length - 1) {
          } else if (addedAtIndex <= data.index) {
            data.index = data.index + 1;
          }

          if (lastDrawnAt.length - 1 === key) {
            setTimeout(() => {
              this.props.hideLoader();
            }, 500);
          }
        });

        this.currentProject.lastDrawnAt = lastDrawnAt;
      }, 500);
    } else if (selectedSpace == "background") {
      this.bgTimeline.bgKeyframes.splice(index, 0, {
        history: [
          {
            keyframe: {
              version: "3.2.0",
              objects: [],
            },
            keyframesBase64: "",
          },
        ],
        duplicated: true,
        keyframe: this.bgTimeline.bgKeyframes[index - 1].keyframe,
      }); // added a new keyframe on the index specified
      this.setSelectedKeyframeFromTimeline(index, "duplicateKeyframe");
      this.bgTimeline.bgLastDrawnAt.push({ index: index, history: 1 });

      if (this.currentProject.keyframes[index]) {
        this.currentProject.keyframes.push({
          history: [],
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: this.currentProject.keyframes.length,
        });

        let handleObj = {
          index: this.currentProject.keyframes.length,
          atEnd: true,
        };
        this.props.handleFgOnDuplicateKeyframe(handleObj);
      } else {
        this.currentProject.keyframes.splice(index, 0, {
          history: [],
          keyframe: {
            version: "3.2.0",
            objects: [],
          },
          index: index,
        });

        let handleObj = {
          index,
          atEnd: false,
        };
        this.props.handleFgOnDuplicateKeyframe(handleObj);
      }

      setTimeout(() => {
        _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
          bgLastDrawnAt = [...bgLastDrawnAt, e];
        });

        let addedAtIndex = index;

        bgLastDrawnAt[bgLastDrawnAt.length - 1].index = addedAtIndex;
        bgLastDrawnAt[bgLastDrawnAt.length - 1].history = 1;

        bgLastDrawnAt.map((data, key) => {
          if (key == bgLastDrawnAt.length - 1) {
          } else if (addedAtIndex <= data.index) {
            data.index = data.index + 1;
          }

          if (bgLastDrawnAt.length - 1 === key) {
            setTimeout(() => {
              this.props.hideLoader();
            }, 500);
          }
        });

        this.bgTimeline.bgLastDrawnAt = bgLastDrawnAt;
      }, 500);
    }
  }

  checkIfUndoIsDisabled() {
    let { lastDrawnAt } = this.currentProject,
      { bgLastDrawnAt } = this.bgTimeline,
      { selectedSpace } = this.state;

    if (selectedSpace == "foreground") {
      if (
        lastDrawnAt.length == 0 ||
        (lastDrawnAt.length == 1 &&
          lastDrawnAt[0].index == 0 &&
          lastDrawnAt[0].history == 0)
      ) {
        return false;
      }

      return true;
    } else {
      if (
        bgLastDrawnAt.length == 0 ||
        (bgLastDrawnAt.length == 1 &&
          bgLastDrawnAt[0].index == 0 &&
          bgLastDrawnAt[0].history == 0)
      ) {
        return false;
      }

      return true;
    }
  }

  checkIfObjectIsSelected(status) {
    let { isObjectSelected } = this.state;
    if (!status) {
      if (isObjectSelected) {
        this.setState({
          isObjectSelected: false,
        });
      }
    } else {
      if (!isObjectSelected) {
        this.setState({
          isObjectSelected: true,
        });
      }
    }
  }

  async saveAsImgSequence() {
    let { keyframes } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      context = this,
      bgs = [],
      fgs = [];
    var zip = new JSZip();
    // bgKeyframes.map((item, index) => {
    // context._bgGifCanvas.loadFromJSON(item.keyframe, () => {
    // bgs = [...bgs, context._bgGifCanvas.toDataURL()];
    // });
    // });

    bgs = await this.waitLoadFromJSON(context._bgGifCanvas, bgKeyframes);

    context.props.showLoader();

    keyframes.map((item, index) => {
      if (
        item.keyframe &&
        item.keyframe.objects &&
        item.keyframe.objects.length > 0
      ) {
        var tempCanvas = new fabric.StaticCanvas();
        tempCanvas.setDimensions({
          width: context.state.canvasWidth,
          height: context.state.canvasHeight,
        });
        tempCanvas.loadFromJSON(item.keyframe, () => {
          tempCanvas.backgroundColor = "white";
          fabric.Image.fromURL(bgs[index], function (oImg) {
            tempCanvas.setBackgroundImage(oImg);
            var img = zip.folder("images");
            img.file(
              `image${index}.png`,
              tempCanvas.toDataURL().replace("data:image/png;base64,", ""),
              // tempCanvas.toDataURL({ multiplier: scaleFactor }).replace("data:image/png;base64,", ""),
              {
                base64: true,
              }
            );
            tempCanvas.renderAll();
          });
        });
      }
    });

    setTimeout(() => {
      zip
        .generateAsync({ type: "blob" })
        .then(function (content) {
          context.props.hideLoader();
          saveAs(content, "example.zip");
        })
        .catch((e) => console.log(e, "e"));
    }, 3000);

    // keyframes.map((item, index) => {
    // if (item.keyframe && item.keyframe.objects.length > 0) {
    // context._imgSequence.loadFromJSON(item.keyframe, () => {
    // context._imgSequence.backgroundColor = "white";
    // context._imgSequence.renderAll();
    // saveAs(context._imgSequence.toDataURL(), "download.png");
    // });
    // }
    // });
  }

  setPressureOpacity(e) {
    this.setState({
      pressureOpacity: Number(e.target.value),
    });
  }

  checkIfPasteIsEnabled(status) {
    this.setState({
      isPasteEnabled: status,
    });
  }

  checkIfGroupAllEnabled(status) {
    this.setState({
      isGroupAllEnabled: status,
    });
  }

  onSortEnd(oldIndex, newIndex) {
    let { selectedSpace } = this.state;
    let sortObj = {
      oldIndex: oldIndex.oldIndex,
      newIndex: oldIndex.newIndex,
      selectedSpace,
    };
    let { keyframes } = this.props.canvas.currentProject;
    let { bgKeyframes } = this.props.canvas.bgTimeline;
    if (
      (keyframes.map((item) => item.index).includes(sortObj.newIndex) &&
        Object.keys(keyframes[sortObj.newIndex]).includes("keyframesBase64") &&
        selectedSpace == "foreground") ||
      (bgKeyframes.length > 1 &&
        bgKeyframes[sortObj.newIndex]?.keyframe?.version === "3.6.6")
    ) {
      // this.props.reorderKeyframePosition(sortObj);
      // this.setSelectedKeyframeFromTimeline(oldIndex.newIndex, "onSortEnd");
    }
    this.props.reorderKeyframePosition(sortObj);
    this.setSelectedKeyframeFromTimeline(oldIndex.newIndex, "onSortEnd");
  }

  saveFromModal(e) {
    e.preventDefault();

    let { prompt, promptTowards } = this.state;
    if (this.refs.projectName.value.trim() !== "") {
      // $("[data-dismiss=modal]").trigger({ type: "click" });
      const modalElement = document.getElementById('save_modal');
      modalElement.classList.remove('in');
      modalElement.setAttribute('aria-hidden', 'true');
      modalElement.style.display = 'none';
      document.querySelector('.modal-backdrop').remove();
      if (prompt === "alertBox") {
        this.saveOrUpdateProject("alertBox", promptTowards);
      } else {
        this.saveOrUpdateProject();
      }
    } else {
      this.setState({
        blankProjectName: "* This is a required field.",
      });
    }
  }

  saveOrUpdateProjectFromSaveIcon() {
    let { keyframes } = this.currentProject,
      { bgKeyframes } = this.bgTimeline,
      { selectedSpace } = this.state,
      { isModified, _id, name } = this.props.canvas.currentProject,
      shallAsk;

    if (selectedSpace == "foreground") {
      shallAsk = keyframes.some((e) => {
        if (e.keyframe && e.keyframe.objects) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      });
    } else {
      shallAsk = bgKeyframes.some((e) => {
        if (e.keyframe && e.keyframe.objects) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      });
    }

    if (shallAsk && isModified) {
      if (_id && name) {
        this.saveOrUpdateProject();
      } else {
        $("#openModal").trigger("click");
      }
    }
  }

  createNewProject(calledFrom, type) {
    let { keyframes, _id, name, isModified, panTransform } =
        this.props.canvas.currentProject,
      shallAsk,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      { userDetails } = this.props.user;

    var data = {
      keyframes,
      bgKeyframes,
      panTransform,
    };
    let saveObj = {
      data: JSON.stringify(data),
      user: userDetails.id,
      name: this.refs.projectName.value.trim(),
      category: "advanced-doodle",
    };

    this.props.createNewProject(saveObj, (res) => {
      if (res.success) {
        // ref hook to pass data down to child
        // Not an ideal way - Do it through Redux
        if (!type || type === "openIcon")
          this.projectListingComponentRef.current
            .getWrappedInstance()
            .addOrUpdateProject("add", res.result.project);

        if (calledFrom == "alertBox") {
          // otherwise this will remove _id(save project id)
          if (type !== "openIcon") this.purgeCanvas();

          setTimeout(() => {
            if (type === "goToHome") {
              this.props.history.push("/dashboard");
            } else if (type === "openIcon") {
              // this.purgeCanvas();
              // this.props.history.push("/project-vault");

              this.openProjectListModal();
            } else window.location.reload();
          }, 500);
        }
      }
    });
    this.refs.projectName.value = "";
  }

  saveOrUpdateProject(calledFrom = "", type = "") {
    let { keyframes, _id, name, isModified, panTransform } =
        this.props.canvas.currentProject,
      shallAsk,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      { userDetails } = this.props.user;

    if (this.state.selectedSpace == "foreground") {
      shallAsk = this.currentProject.keyframes.some((e) => {
        if (e.keyframe && e.keyframe.objects) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      });
    } else {
      shallAsk = this.bgTimeline.bgKeyframes.some((e) => {
        if (e.keyframe && e.keyframe.objects) {
          return e.keyframe.objects.length > 0 && e.history.length > 1;
        } else return false;
      });
    }

    if (shallAsk && isModified) {
      if (_id && name) {
        var data = {
          keyframes,
          bgKeyframes,
          panTransform,
        };

        let updateObj = {
          _id,
          category: "advanced-doodle",
          data: JSON.stringify(data),
        };
        this.props.updateProject(updateObj, (res) => {
          if (res.success) {
            // ref hook to pass data down to child
            // Not an ideal way - Do it through Redux
            if (!type || type === "openIcon")
              this.projectListingComponentRef.current
                .getWrappedInstance()
                .addOrUpdateProject("update", res.result.project);

            if (calledFrom == "alertBox") {
              // otherwise this will remove _id(save project id)
              if (type !== "openIcon") this.purgeCanvas();

              setTimeout(() => {
                if (type === "goToHome") {
                  this.props.history.push("/dashboard");
                } else if (type === "openIcon") {
                  // this.purgeCanvas();
                  // this.props.history.push("/project-vault");

                  this.openProjectListModal();
                } else window.location.reload();
              }, 500);
            }
          }
        });
      } else {
        this.createNewProject(calledFrom, type);
      }
    }
  }

  purgeCanvas() {
    this.props.history.replace("/advanced-doodle", null);
    this.props.purgeCanvas();
  }

  purgeCanvasAndGoToProjectVault() {
    this.props.history.replace("/advanced-doodle", null);
    this.props.purgeCanvas();
    $("[data-dismiss=modal]").trigger({ type: "click" });
    setTimeout(() => {
      this.props.history.push("/project-vault");
    }, 500);
  }

  openProjectsVault() {
    this.newProjectOrGoToHome("openIcon");
  }

  saveBtnInUnsavedChangesModal() {
    let { promptTowards } = this.state;
    let { isModified, _id, name } = this.props.canvas.currentProject;
    $("[data-dismiss=modal]").trigger({ type: "click" });
    if (!_id && !name) {
      setTimeout(() => {
        $("#openModal").trigger("click");
      }, 200);
    } else {
      this.saveOrUpdateProject("alertBox", promptTowards);
    }
  }

  noAndProceedBtn() {
    $("[data-dismiss=modal]").trigger({ type: "click" });
    let { promptTowards } = this.state;
    if (promptTowards === "goToFlipbook") {
      this.props.history.push("/flipbook");
    } else {
      if (promptTowards !== "openIcon") this.purgeCanvas();

      if (promptTowards === "goToHome") {
        this.props.history.push("/dashboard");
      } else if (promptTowards === "openIcon") {
        this.purgeCanvas();
        this.props.history.push("/my-projects");

        this.openProjectListModal();
      } else window.location.reload();
    }
  }

  cellClicked(index, whichGround) {
    let { keyframes } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      lastDrawnAt = [],
      bgLastDrawnAt = [];

    _.filter(this.currentProject.lastDrawnAt, (e) => {
      lastDrawnAt = [...lastDrawnAt, e];
    });

    _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
      bgLastDrawnAt = [...bgLastDrawnAt, e];
    });

    this.setState(
      {
        activeKeyframeIndex: index - 1,
        selectedSpace: whichGround,
        sketchCanvas: "",
      },
      () => {
        let i = 0;
        // this.setSelectedKeyframeFromTimeline(index - 1, "");

        for (i = 0; i < index; i++) {
          if (keyframes[i] == undefined) {
            this.addKeyframeAt(i, "cellClicked");
          } else {
            if (this.state.selectedSpace == "foreground") {
              var lastDrawnObjAlreadyExists = _.findIndex(lastDrawnAt, (e) => {
                return e.index == i && (e.history == 0 || e.history == 1);
              });
              if (lastDrawnObjAlreadyExists < 0) {
                let addedAtIndex = i;
                let lastDrawnObj = {
                  index: addedAtIndex,
                  history: 0,
                };
                this.currentProject.lastDrawnAt.push(lastDrawnObj);
              }
            } else {
              var lastDrawnObjAlreadyExists = _.findIndex(
                bgLastDrawnAt,
                (e) => {
                  return e.index == i && (e.history == 0 || e.history == 1);
                }
              );
              if (lastDrawnObjAlreadyExists < 0) {
                let addedAtIndex = i;
                let lastDrawnObj = {
                  index: addedAtIndex,
                  history: 0,
                };

                this.bgTimeline.bgLastDrawnAt.push(lastDrawnObj);
              }
            }
          }
        }
        if (whichGround == "foreground") {
          if (
            keyframes[index - 1].addedExposure &&
            index - 1 == keyframes[index - 1].addedExposureOfIndex
          ) {
            this.setState({
              isFirstInExposured: true,
            });
          } else if (
            keyframes[index - 1].addedExposure &&
            index - 1 != keyframes[index - 1].addedExposureOfIndex
          ) {
            this.setState({
              isFirstInExposured: false,
            });
          } else {
            this.setState({
              isFirstInExposured: null,
            });
          }

          this.setState(
            {
              sketchCanvas: keyframes[index - 1].keyframe,
            },
            () => {
              if (bgKeyframes[index - 1])
                this.sketchCanvas.toggleBackground(
                  "setBackgroundFromBackgroundKey",
                  bgKeyframes[index - 1].keyframe
                );
            }
          );
        } else if (whichGround == "background" && bgKeyframes[index - 1]) {
          if (
            bgKeyframes[index - 1].addedExposure &&
            index - 1 == bgKeyframes[index - 1].addedExposureOfIndex
          ) {
            this.setState({
              isFirstInExposured: true,
            });
          } else if (
            bgKeyframes[index - 1].addedExposure &&
            index - 1 != bgKeyframes[index - 1].addedExposureOfIndex
          ) {
            this.setState({
              isFirstInExposured: false,
            });
          } else {
            this.setState({
              isFirstInExposured: null,
            });
          }

          this.setState(
            {
              sketchCanvas: bgKeyframes[index - 1].keyframe,
            },
            () => {
              this.sketchCanvas.toggleBackground(
                "setBackgroundOuterKeyframeKey",
                keyframes[index - 1].keyframe
              );
            }
          );
        }
      }
    );

    // this.sketchCanvas.toggleBackground(whichGround);
  }

  updateKeyframeAsExposureWithPreviousDrawnObject(
    keyframeofIndex,
    fromIndex,
    toIndex,
    selectedSpace
  ) {
    let i,
      addExposureObj,
      lastDrawnAt = [],
      bgLastDrawnAt = [];

    _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
      bgLastDrawnAt = [...bgLastDrawnAt, e];
    });

    _.filter(this.currentProject.lastDrawnAt, (e) => {
      lastDrawnAt = [...lastDrawnAt, e];
    });
    for (i = fromIndex; i <= toIndex; i++) {
      addExposureObj = {
        keyframeofIndex,
        at: i,
        selectedSpace,
      };
      this.props.updateKeyframeAsExposureWithPreviousDrawnObject(
        addExposureObj
      );

      if (selectedSpace == "foreground") {
        lastDrawnAt.map((item) => {
          if (item.index == i && i !== fromIndex) {
            item.exposuredFrom = keyframeofIndex;
            item.exposured = true;
          }
        });

        if (i == fromIndex) {
          let persistedHistory = this.currentProject.keyframes[i].history;
          this.currentProject.keyframes[i] = {
            history: persistedHistory,
            addedExposure: true,
            dntReset: true,
            addedExposureOfIndex: keyframeofIndex,
            keyframe: this.currentProject.keyframes[keyframeofIndex].keyframe,
          };
        } else
          this.currentProject.keyframes[i] = {
            history: [],
            addedExposure: true,
            addedExposureOfIndex: keyframeofIndex,
            keyframe: this.currentProject.keyframes[keyframeofIndex].keyframe,
          };
      } else if (selectedSpace == "background") {
        bgLastDrawnAt.map((item) => {
          if (item.index == i && i !== fromIndex) {
            item.exposuredFrom = keyframeofIndex;
            item.exposured = true;
          }
        });

        if (i == fromIndex) {
          let persistedHistory = this.bgTimeline.bgKeyframes[i].history;

          this.bgTimeline.bgKeyframes[i] = {
            history: persistedHistory,
            addedExposure: true,
            dntReset: true,
            addedExposureOfIndex: keyframeofIndex,
            keyframe: this.bgTimeline.bgKeyframes[keyframeofIndex].keyframe,
          };
        } else
          this.bgTimeline.bgKeyframes[i] = {
            history: [],
            addedExposure: true,
            addedExposureOfIndex: keyframeofIndex,
            keyframe: this.bgTimeline.bgKeyframes[keyframeofIndex].keyframe,
          };
      }
    }
  }

  updateAllExposuredFrames(
    addedExposureOfIndex,
    selectedSpace,
    activeKeyframeIndex,
    currentJSONCanvasFromUndo
  ) {
    let { keyframes } = this.currentProject,
      { bgKeyframes } = this.bgTimeline,
      newHistory = [],
      exposuredReplaceWith = undefined,
      currentJSONCanvas = this.sketchCanvas ? this.sketchCanvas.toJSON() : {};

    if (
      currentJSONCanvasFromUndo &&
      currentJSONCanvasFromUndo !== "undefined"
    ) {
      exposuredReplaceWith = currentJSONCanvasFromUndo;
    } else if (
      currentJSONCanvasFromUndo &&
      currentJSONCanvasFromUndo == "undefined"
    ) {
      exposuredReplaceWith = {
        version: "3.2.0",
        objects: [],
      };
    }

    if (selectedSpace == "foreground") {
      keyframes.map((data, index) => {
        if (data.dntReset) {
          newHistory = [
            ...data.history,
            ...keyframes[activeKeyframeIndex].history,
          ];
        } else {
          newHistory = keyframes[activeKeyframeIndex].history;
        }

        if (data.addedExposureOfIndex == addedExposureOfIndex) {
          data.keyframe = exposuredReplaceWith
            ? exposuredReplaceWith
            : currentJSONCanvas;
          data.history = keyframes[activeKeyframeIndex].history;
        }
      });
    } else if (selectedSpace == "background") {
      bgKeyframes.map((data, index) => {
        if (data.addedExposureOfIndex == addedExposureOfIndex) {
          data.keyframe = exposuredReplaceWith
            ? exposuredReplaceWith
            : currentJSONCanvas;
          data.history = bgKeyframes[activeKeyframeIndex].history;
        }
      });
    }

    let updateExposuredFrameObj = {
      addedExposureOfIndex,
      currentJSONCanvas: exposuredReplaceWith
        ? exposuredReplaceWith
        : currentJSONCanvas,
      selectedSpace,
    };
    this.props.updateExposuredFrame(updateExposuredFrameObj);
  }

  handleLastDrawnArrOnDeleteKeyframe(index) {
    let lastDrawnAt = [],
      bgLastDrawnAt = [];
    _.filter(this.currentProject.lastDrawnAt, (e) => {
      if (e.index != index) {
        lastDrawnAt = [...lastDrawnAt, e];
      }
    });
    lastDrawnAt.map((data, key) => {
      if (index < data.index) {
        data.index = data.index - 1;
      }
    });

    _.filter(this.bgTimeline.bgLastDrawnAt, (e) => {
      if (e.index != index) {
        bgLastDrawnAt = [...bgLastDrawnAt, e];
      }
    });
    bgLastDrawnAt.map((data, key) => {
      if (index < data.index) {
        data.index = data.index - 1;
      }
    });

    this.currentProject.lastDrawnAt = lastDrawnAt;
    this.bgTimeline.bgLastDrawnAt = bgLastDrawnAt;
  }

  deleteForegroundBackgroundObjects(whichGround, index) {
    let { playing, looping } = this.state;

    if (playing || looping) {
      return false;
    }

    if (whichGround.includes("foreground")) whichGround = "foreground";
    else whichGround = "background";

    // this.sketchCanvas.deleteBackgroundForegroundObjs(whichGround);

    this.deleteKeyframeFromTimeline(index, whichGround);
  }

  deleteKeyframeFromTimeline(index, whichSpace) {
    let deleteKeyframeObj = {
      index,
      whichSpace,
      advanced: true,
    };
    this.props.deleteKeyframe(deleteKeyframeObj);
    this.currentProject.keyframes.splice(index, 1);
    this.bgTimeline.bgKeyframes.splice(index, 1);
    this.setSelectedKeyframeFromTimeline(index, "deleteKeyframe");

    this.handleLastDrawnArrOnDeleteKeyframe(index);
  }

  onPanStop(val) {
    this.props.dispatch(_handlePanTransform(val));
  }

  canvasColorPicker() {
    this.setState({
      pickCanvasColor: true,
      selectedTool: Tools.Eyedropper,
      selectedBrushColor: "rgba(0,0,0,0)",
    });
  }

  setCanvasColor(rgba) {
    if (rgba === "rgba(0,0,0,0)") {
      this.setState({
        pickCanvasColor: true,
        selectedTool: Tools.Eyedropper,
        selectedBrushColor: "#ffffff00",
      });
    } else {
      this.setState({
        pickCanvasColor: false,
        selectedTool: Tools.Pencil,
        selectedBrushColor: rgba,
      });
    }
  }

  render() {
    const styles = reactCSS({
      default: {
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    let {
      selectedTool,
      activeKeyframeIndex,
      playing,
      looping,
      traceEnabled,
      sendToBackEnabled,
      brushSize,
      selectedBrushColor,
      isObjectSelected,
      canvasWidth,
      canvasHeight,
      pressureOpacity,
      animationSpeed,
      isPasteEnabled,
      isGroupAllEnabled,
      selectedSpace,
      isFirstInExposured,
    } = this.state;

    let { keyframes, panTransform } = this.props.canvas.currentProject,
      { bgKeyframes } = this.props.canvas.bgTimeline,
      lastIndexWhereObjectExists = this.lastIndexWhereObjectExists();
    if (canvasHeight > 0 && canvasWidth > 0) {
      clearInterval(this.interval);
    }
    return (
      <div className="body_wrap">
        <div className="conta_iner">
          <div className="animation_ish_box advanced_outer_box">
            <Topbar
              isLoading={this.props.isLoading}
              newProjectOrGoToHome={this.newProjectOrGoToHome.bind(this)}
              saveVideo={this.saveVideo.bind(this)}
              exportState={this.exportState.bind(this)}
              loadProjectFile={this.loadProjectFile.bind(this)}
              loadProjectFromPopup={this.loadProjectFromPopup.bind(this)}
              openProjectListModal={this.openProjectListModal.bind(this)}
              undoAction={this.undoAction.bind(this)}
              removeSelectedObject={this.removeSelectedObject.bind(this)}
              enableDisableSendToBack={this.enableDisableSendToBack.bind(this)}
              sendToBackEnabled={sendToBackEnabled}
              enableDisableTrace={this.enableDisableTrace.bind(this)}
              traceEnabled={traceEnabled}
              importImg={this.importImg.bind(this)}
              checkIfUndoIsDisabled={this.checkIfUndoIsDisabled.bind(this)}
              isObjectSelected={isObjectSelected}
              saveAsImgSequence={this.saveAsImgSequence.bind(this)}
              setPressureOpacity={this.setPressureOpacity.bind(this)}
              pressureOpacity={pressureOpacity}
              selectedBrushColor={selectedBrushColor}
              saveOrUpdateProjectFromSaveIcon={this.saveOrUpdateProjectFromSaveIcon.bind(
                this
              )}
              history={this.props.history}
              zoomCanvas={this.zoomCanvas.bind(this)}
              openProjectsVault={this.openProjectsVault.bind(this)}
              copy={this.copy.bind(this)}
              paste={this.paste.bind(this)}
              cut={this.cut.bind(this)}
              isPasteEnabled={isPasteEnabled}
              isGroupAllEnabled={isGroupAllEnabled}
              checkIfGroupAllEnabled={this.checkIfGroupAllEnabled.bind(this)}
              checkIfPasteIsEnabled={this.checkIfPasteIsEnabled.bind(this)}
              selectedTool={selectedTool}
              swal={this.props}
            />
            <div className="tabLandscapeLayout">
              <div className="wiggle_doddle_main_mid_sec  wiggle_outer_div flipbook_mian_sec advanced_main_sec row">
                <LeftTools
                  selectedTool={selectedTool}
                  setSelectedTool={this.setSelectedTool.bind(this)}
                  checkIfGroupAllEnabled={this.checkIfGroupAllEnabled.bind(
                    this
                  )}
                />
                <div className="wiggle_doddle_main_mid_center col-sm-8">
                  <div className="doddle_board_and_text">
                    <div className="bg_img">
                      <div
                        className="make_drawing_board"
                        style={
                          playing || isFirstInExposured == false
                            ? { pointerEvents: "none", userSelect: "none" }
                            : {}
                        }
                      >
                        <div
                          id={
                            this.state.draggableIdActive ? "draggableBoard" : ""
                          }
                        >
                          <div
                            className="drawing_board"
                            id="drawing_board"
                            ref={(drawing_board) =>
                              (this.drawing_board = drawing_board)
                            }
                          >
                            {this.state.canvasHeight != 0 &&
                              !this.state.playingGif && (
                                <SketchField
                                  ref={(sketchDraw) =>
                                    (this.sketchCanvas = sketchDraw)
                                  }
                                  height={this.state.canvasHeight}
                                  width={this.state.canvasWidth}
                                  tool={this.state.selectedTool}
                                  traceEnabled={this.state.traceEnabled}
                                  sendToBackEnabled={sendToBackEnabled}
                                  lineColor={this.state.selectedBrushColor}
                                  lineWidth={this.state.brushSize}
                                  value={this.state.sketchCanvas}
                                  opacity={pressureOpacity}
                                  rgbToHex={this.rgbToHex.bind(this)}
                                  hexToRgb={this.hexToRgb.bind(this)}
                                  setCanvasColor={this.setCanvasColor.bind(
                                    this
                                  )}
                                  pickCanvasColor={this.state.pickCanvasColor}
                                  keyframes={
                                    this.props.canvas.currentProject.keyframes
                                  }
                                  bgKeyframes={
                                    this.props.canvas.bgTimeline.bgKeyframes
                                  }
                                  advancedSection={true}
                                  activeKeyframeIndex={activeKeyframeIndex}
                                  checkIfObjectIsSelected={this.checkIfObjectIsSelected.bind(
                                    this
                                  )}
                                  checkIfPasteIsEnabled={this.checkIfPasteIsEnabled.bind(
                                    this
                                  )}
                                  panTransformVal={
                                    this.props.canvas.currentProject
                                      .panTransform
                                  }
                                  onPanStop={this.onPanStop.bind(this)}
                                  draggableIdActive={
                                    this.state.draggableIdActive
                                  }
                                  randomColorState={this.state.randomColorState}
                                  selectedSpace={this.state.selectedSpace}
                                  import_img={this.state.import_img}
                                  onMovePathChange={(
                                    transformPath,
                                    activeKeyframeIndex
                                  ) =>
                                    this.handleMovePathChange(
                                      transformPath,
                                      activeKeyframeIndex
                                    )
                                  }
                                  onChange={
                                    playing
                                      ? (e) => {}
                                      : (e) =>
                                          this.updateSelectedKeyframe(
                                            e,
                                            activeKeyframeIndex
                                          )
                                  }
                                />
                              )}

                            {(playing || looping) && (
                              <img
                                style={{
                                  height: this.state.canvasHeight,
                                  width: this.state.canvasWidth,
                                  transform: panTransform
                                    ? panTransform
                                    : // : "translate(-240px, -130px)"
                                      "translate(0px, 0px)",
                                }}
                                src={this.state.playingGif}
                                onChange={(e) => console.log(e)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="color_box next_prev_play_button">
                  <div className="play_pause_button">
                    <div className="next_pre_button play_button_pause_sec">
                      <ul>
                        <li
                          onClick={() => this.playTransform()}
                          className="active_button"
                          title="Play"
                        >
                          <img src="images/okay_picker_button.png" />
                        </li>
                        {!playing && (
                          <li
                            onClick={
                              !this.checkIfPlayButtonIsEnabled() || looping
                                ? () => {}
                                : () => this.playAnimation(-1, "play")
                            }
                            className={classnames({
                              active_button:
                                this.checkIfPlayButtonIsEnabled() && !looping,
                            })}
                            title="Play"
                          >
                            <img src="images/flipbook/play.png" />
                          </li>
                        )}
                        {playing && (
                          <li
                            onClick={() => this.stopAnimation()}
                            className="active_button"
                            title="Stop"
                          >
                            <img src="images/flipbook/pause.png" />
                          </li>
                        )}

                        {!looping && (
                          <li
                            onClick={
                              !this.checkIfPlayButtonIsEnabled() || playing
                                ? () => {}
                                : () => this.playAnimation(0, "loop")
                            }
                            className={classnames({
                              active_button:
                                this.checkIfPlayButtonIsEnabled() && !playing,
                            })}
                            title="Loop"
                          >
                            <img src="images/flipbook/loop.png" />
                          </li>
                        )}

                        {looping && (
                          <li
                            onClick={() => this.stopAnimation()}
                            className="active_button"
                            title="Stop"
                          >
                            <img src="images/flipbook/pause.png" />
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="slow_fast_buttons">
                    <div className="slw_fast_icons">
                      <div className="slidecontainer">
                        <input
                          type="range"
                          min="1"
                          max="3"
                          className="speedSlider"
                          id="myRange"
                          value={animationSpeed}
                          onChange={(e) => this.changeAnimationSpeed(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>

                {
                  <div>
                    {this.state.colorPick ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker
                          color={this.state.selectedBrushColor}
                          onChangeComplete={this.handleChangeComplete}
                          presetColors={this.state.recentColors}
                        />
                      </div>
                    ) : null}
                  </div>
                }

                <RightTools
                  setBrushColor={this.setBrushColor.bind(this)}
                  newColorPicker={this.newColorPicker.bind(this)}
                  openColorPicker={this.openColorPicker.bind(this)}
                  brushSize={brushSize}
                  selectedBrushColor={selectedBrushColor}
                  changeBrushSize={this.changeBrushSize.bind(this)}
                  backBtnActiveInactive={this.backBtnActiveInactive.bind(this)}
                  prevPressed={this.prevPressed.bind(this)}
                  nextPressed={this.nextPressed.bind(this)}
                  playAnimation={this.playAnimation.bind(this)}
                  keyframes={keyframes}
                  activeKeyframeIndex={activeKeyframeIndex}
                  stopAnimation={this.stopAnimation.bind(this)}
                  nextBtnActiveInactive={this.nextBtnActiveInactive.bind(this)}
                  canvasColorPicker={this.canvasColorPicker.bind(this)}
                  playing={playing}
                  checkIfPlayButtonIsEnabled={this.checkIfPlayButtonIsEnabled.bind(
                    this
                  )}
                  playTransform={this.playTransform.bind(this)}
                  looping={looping}
                  animationSpeed={animationSpeed}
                  changeAnimationSpeed={this.changeAnimationSpeed.bind(this)}
                />
              </div>
            </div>
            <BottomTools
              isLoading={this.props.isLoading}
              cellClicked={this.cellClicked.bind(this)}
              duplicateKeyframe={this.duplicateKeyframe.bind(this)}
              playing={playing}
              looping={looping}
              addExposure={this.addExposure.bind(this)}
              activeKeyframeIndex={activeKeyframeIndex}
              addNewBlankKeyframe={this.addNewBlankKeyframe.bind(this)}
              keyframes={keyframes}
              bgKeyframes={bgKeyframes}
              selectedSpace={selectedSpace}
              lastIndexWhereObjectExists={lastIndexWhereObjectExists}
              deleteForegroundBackgroundObjects={this.deleteForegroundBackgroundObjects.bind(
                this
              )}
              onSortEnd={this.onSortEnd.bind(this)}
            />{" "}
          </div>
        </div>

        <button
          id="openModal"
          type="button"
          data-toggle="modal"
          data-target="#save_modal"
          style={{ display: "none" }}
        >
          Open Modal
        </button>
        <div className="modal fade" id="save_modal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-header">
              <button type="button" className="close">
                &times;
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-body">
                <div className="havnot_picker_popup">
                  <div className="have_not_box">
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/havent_saved_background.png" />
                    <div className="project_file_save">
                      <h2>Project Name</h2>
                    </div>
                    <div className="saved_save_popup">
                      <div className="type_name">
                        <img src="https://d3rc7k2n78msdl.cloudfront.net/input_background.png" alt="image" />
                        <div className="typing_input_name">
                          <input
                            type="text"
                            name="name"
                            placeholder="File Name"
                            ref="projectName"
                            autofocus
                            onChange={() =>
                              this.setState({ blankProjectName: "" })
                            }
                          />
                          <p className="errorMsg blankProjectName">
                            {this.state.blankProjectName}
                          </p>
                        </div>
                      </div>
                      <div className="yes_save">
                        <img
                          src="https://d3rc7k2n78msdl.cloudfront.net/yes_sve.png"
                          onClick={(e) => this.saveFromModal(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColorPickerModal
          handleColorPicker={this.handleColorPicker.bind(this)}
          selectedBrushColor={selectedBrushColor}
        />
        <UnsavedChangesModal
          noAndProceedBtn={this.noAndProceedBtn.bind(this)}
          saveBtnInUnsavedChangesModal={this.saveBtnInUnsavedChangesModal.bind(
            this
          )}
        />
        <ProjectListingModal
          ref={this.projectListingComponentRef}
          category={this.state.projectType}
          loadProjectFromPopup={this.loadProjectFromPopup.bind(this)}
          purgeCanvasAndGoToProjectVault={this.purgeCanvasAndGoToProjectVault.bind(
            this
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  canvas: state.canvas,
  user: state.user,
  isLoading: state.app.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  newKeyframe: bindActionCreators(newKeyframe, dispatch),
  addNewKeyframeAt: bindActionCreators(addNewKeyframeAt, dispatch),
  updateKeyframe: bindActionCreators(updateKeyframe, dispatch),
  setProjectType: bindActionCreators(setProjectType, dispatch),
  swal: bindActionCreators(swal.showAlert, dispatch),
  hideLoader: bindActionCreators(hideLoader, dispatch),
  showLoader: bindActionCreators(showLoader, dispatch),
  undoKeyframe: bindActionCreators(undoKeyframe, dispatch),
  deleteKeyframe: bindActionCreators(deleteKeyframe, dispatch),
  emptyKeyframe: bindActionCreators(emptyKeyframe, dispatch),
  duplicateKeyframeAt: bindActionCreators(duplicateKeyframeAt, dispatch),
  addExposureAt: bindActionCreators(addExposureAt, dispatch),
  generateVideo: bindActionCreators(generateVideo, dispatch),
  reorderKeyframePosition: bindActionCreators(
    reorderKeyframePosition,
    dispatch
  ),

  createNewProject: bindActionCreators(createNewProject, dispatch),
  updateProject: bindActionCreators(updateProject, dispatch),
  getProjectDetailsById: bindActionCreators(getProjectDetailsById, dispatch),
  getProjectDetailsByIdAndCategory: bindActionCreators(
    getProjectDetailsByIdAndCategory,
    dispatch
  ),
  loadExistingProjectFile: bindActionCreators(
    loadExistingProjectFile,
    dispatch
  ),
  purgeCanvas: bindActionCreators(purgeCanvas, dispatch),
  updateKeyframeAsExposureWithPreviousDrawnObject: bindActionCreators(
    updateKeyframeAsExposureWithPreviousDrawnObject,
    dispatch
  ),
  updateExposuredFrame: bindActionCreators(updateExposuredFrame, dispatch),
  resetAllExposuredFramesOfSpecificAddedExposureOfIndex: bindActionCreators(
    resetAllExposuredFramesOfSpecificAddedExposureOfIndex,
    dispatch
  ),
  handleBgOnDuplicateKeyframe: bindActionCreators(
    handleBgOnDuplicateKeyframe,
    dispatch
  ),
  handleFgOnDuplicateKeyframe: bindActionCreators(
    handleFgOnDuplicateKeyframe,
    dispatch
  ),
  handleBgOnAddExposure: bindActionCreators(handleBgOnAddExposure, dispatch),
  handleFgOnAddExposure: bindActionCreators(handleFgOnAddExposure, dispatch),
  updateMovePath: bindActionCreators(updateMovePath, dispatch),
});

let advancedDoodle = hotkeys(AdvancedDoodle);

export default connect(mapStateToProps, mapDispatchToProps)(advancedDoodle);
