import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import { attemptLogin, attemptLoginfromToken } from "../../redux/user/actions";
import TermsModal from "../../components/TermsModal";
import { clearCache } from "../../utilities/clearCache";

class Login extends Component {
	state = {
		usernameError: "",
		passwordError: ""
	};

	// componentDidMount(){

	// 	let token = (new URLSearchParams(window.location.search)).get("token");

	// 	if (token){

	// 		this.props.attemptLoginfromToken()
	// 	}
	// }

  componentDidMount() {
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookiesAsArray = decodedCookie.split(";");
    const data = {};
    if (cookiesAsArray.length) {
      for (let i = 0; i < cookiesAsArray.length; i++) {
        let value = decodedCookie.split("=");
        data[value[0]] = value[1];
      } }
    if (data.token) {
	// clearCache();
      this.props.attemptLoginfromToken(data.token);
    }
  }

	validateLogin() {
		let username = this.refs.username.value,
			password = this.refs.password.value,
			loginObj = {
				username,
				password,
				sku: "ANIMATIONISH"
			};

		if (!username) {
			this.setState({
				usernameError: "Please enter the username."
			});
		} else if (!password) {
			this.setState({
				passwordError: "Please enter password."
			});
		} else {
			$("#termsModal").trigger("click");
		}
	}
	

	loginUser() {
		// $("#termsModal").trigger("click");

		let username = this.refs.username.value,
			password = this.refs.password.value,
			loginObj = {
				username,
				password,
				sku: "ANIMATIONISH"
			};
		if (!username) {
			this.setState({
				usernameError: "Please enter the username."
			});
		} else if (!password) {
			this.setState({
				passwordError: "Please enter password."
			});
		} else {
			this.props.attemptLogin(loginObj);
		}
	}


	render() {
		let { usernameError, passwordError } = this.state;
		return (
			<div className="body_wrap">
				<div className="conta_iner">
					<div className="animation_ish_box">
						<div className="log_in_page">
							<div className="login_logo">
								<div className="logo_img_sec">
									<img src="https://d3rc7k2n78msdl.cloudfront.net/login/logo.png" />
								</div>
								<div className="logo_img_sec">
									<a
										href="https://www.fablevisionlearning.com"
										target="_blank"
									>
										<img src="https://d3rc7k2n78msdl.cloudfront.net/fabelvision.png" />
									</a>
								</div>
							</div>
							<div className="login_fields">
								<div className="login_heading">
									<h2>
										Welcome <span>to Animation-ish</span>
									</h2>
								</div>
								<div className="all_login_fields">
									<div className="input_fields">
										<input
											type="text"
											placeholder="username"
											name="username"
											ref="username"
											onChange={() =>
												this.setState({
													usernameError: ""
												})
											}
										/>
										{usernameError && (
											<span
												className="errorMsg"
												style={{ marginLeft: "10px" }}
											>
												{usernameError}
											</span>
										)}
									</div>
									<div className="input_fields">
										<input
											type="password"
											placeholder="Password"
											name="psd"
											ref="password"
											onChange={() =>
												this.setState({
													passwordError: ""
												})
											}
										/>
										{passwordError && (
											<span
												className="errorMsg"
												style={{ marginLeft: "10px" }}
											>
												{passwordError}
											</span>
										)}
									</div>
									{/*<div className="input_fields forgot_passwd">
										<Link to="/forgot-password">
											Forgot Password
										</Link>
									</div>*/}
									<div className="input_fields submit_fields">
										<input
											type="submit"
											value="Login"
											onClick={() => this.loginUser()}
										/>
										<a
											href="https://www.fablevisionlearning.com/animationish"
											target="_blank"
										>
											<button>Buy animation-ish</button>
										</a>
									</div>
								</div>
								<div className="tnc">
									<p
										className="tncText"
										onClick={() =>
											$("#termsModal").trigger("click")
										}
									>
										Terms & Conditions
									</p>
									<p style={{ marginTop: "5px" }}>
										By logging in, you are accepting the
										terms and conditions
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<TermsModal />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = dispatch => ({
	attemptLogin: bindActionCreators(attemptLogin, dispatch),
	attemptLoginfromToken: bindActionCreators(attemptLoginfromToken, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
