import React, { Component } from "react";
import classnames from "classnames";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { data } from "jquery";

class BottomTools extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this._imageCanvas = new fabric.Canvas();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onSortEnd(oldIndex, newIndex);

  };

  renderKeyframesAsImg(keyframes, activeKeyframeIndex) {
    let context = this;

    // this._imageCanvas.setDimensions({
    // 	width: this.props.canvasWidth,
    // 	height: this.props.canvasHeight
    // });

    return keyframes.map((data, index) => {
      if (
        (index != 0 &&
          index === keyframes.length - 1 &&
          data.keyframe &&
          data.keyframe.objects.length === 0) ||
        (!data.keyframe && index === keyframes.length - 1)
      ) {
        return null;
      }

      // context._imageCanvas.loadFromJSON(data.keyframe, e => {
      // 	keyframesBase64 = context._imageCanvas.toDataURL();
      // });

      return (
        <li
          key={index}
          onClick={
            activeKeyframeIndex !== index
              ? () => context.props.setSelectedKeyframeFromTimeline(index)
              : () => {}
          }
          onDoubleClick={() => console.log(index)}
        >
          <div
            className={classnames({
              flipbook_frames_divs: true,
              selected: activeKeyframeIndex === index,
            })}
          >
            <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/frames.png" />
            <div className="flip_book_inside_image">
              {data.keyframesBase64 && data.keyframesBase64 !== "data:," && (
                <img
                  src={data.keyframesBase64}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>
          </div>
          <div className="typed_sec">{index + 1}</div>
        </li>
      );
    });
  }

  keyframeNotEmpty(keyframes, activeKeyframeIndex) {
    // if (
    // 	keyframes.length > 0 &&
    // 	keyframes[activeKeyframeIndex] &&
    // 	keyframes[activeKeyframeIndex].keyframe &&
    // 	keyframes[activeKeyframeIndex].keyframe.objects.length > 0
    // ) {
    // 	return true;
    // }

    let firstKeyframeHasObjects =
        keyframes &&
        keyframes.length > 0 &&
        keyframes[0] &&
        keyframes[0].keyframe &&
        keyframes[0].keyframe.objects &&
        keyframes[0].keyframe.objects.length > 0,
      lastKeyframeHasObjects =
        keyframes &&
        keyframes.length > 0 &&
        keyframes[keyframes.length - 1] &&
        keyframes[keyframes.length - 1].keyframe &&
        keyframes[keyframes.length - 1].keyframe.objects &&
        keyframes[keyframes.length - 1].keyframe.objects.length > 0;

    if (!firstKeyframeHasObjects && keyframes.length === 1) {
      return false;
    } else if (
      !lastKeyframeHasObjects &&
      activeKeyframeIndex === keyframes.length - 1
    ) {
      return false;
    }

    return true;
  }

  render() {
    let { keyframes, activeKeyframeIndex } = this.props,
      context = this;
    // changes done here for staging
    const SortableItem = SortableElement(({value, data}) => {
		const {index, items: {keyframes, activeKeyframeIndex} } = value;
		
      return (
        <li
          onClick={
            activeKeyframeIndex !== index
              ? () =>
                  context.props.setSelectedKeyframeFromTimeline(index)
              : () => {}
          }
          onDoubleClick={() => console.log(index)}
        >
          <div
            className={classnames({
              flipbook_frames_divs: true,
              selected: activeKeyframeIndex === index,
            })}
          >
            <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/frames.png" />
            <div className="flip_book_inside_image">
              {keyframes[index].keyframesBase64 !== "data:," && (
				
                <img
                  src={keyframes[data].keyframesBase64}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>
          </div>
          <div className="typed_sec">{index + 1}</div>
        </li>
      );
    });

    const SortableList = SortableContainer(({items}) => {
      let context = this;
		const {activeKeyframeIndex, currentProject} = this.props;
      return (
        <ul>
          {keyframes.map((data, index) => {
            if (
              (index != 0 &&
                keyframes &&
                index === keyframes.length - 1 &&
                data.keyframe &&
                data.keyframe.objects &&
                data.keyframe.objects.length == 0) ||
              (keyframes && !data.keyframe && index === keyframes.length - 1)
            ) {
              return null;
            }
            return (
              <SortableItem
                key={`item-${index}`}
                data={index}
                index={index}
				value={{items, index}}
                // listItemtest={index}
              />
            );
          })}

          <li>
            <div
              className={classnames({
                selected:
                  keyframes.length > 1 &&
                  !context.props.nextBtnActiveInactive() &&
                  activeKeyframeIndex === keyframes.length - 1,
              })}
            >
              <img
                src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/add_frame.png"
                onClick={() => context.props.addNewPressedFromTimeline()}
                style={
                  context.props.nextBtnActiveInactive() &&
                  !context.props.playing
                    ? { pointerEvents: "auto" }
                    : { pointerEvents: "none" }
                }
              />
            </div>
          </li>
        </ul>
      );
    });

    return (
      <div>
        <div className="flip_book_bottom_border">
          <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/border.png" />
        </div>
        <div className="flip_book_bottom_data">
          <div className="top_icons">
            <ul>
              <li
                onClick={
                  this.keyframeNotEmpty(keyframes, activeKeyframeIndex)
                    ? () => this.props.addKeyframeAt(activeKeyframeIndex)
                    : null
                }
                className={classnames({
                  active_plus: this.keyframeNotEmpty(
                    keyframes,
                    activeKeyframeIndex
                  ),
                })}
                title="Insert Blank Frame"
              >
                <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/plus.png" />
              </li>
              <li
                onClick={
                  this.keyframeNotEmpty(keyframes, activeKeyframeIndex) &&
                  keyframes.length !== 1
                    ? () =>
                        this.props.deleteKeyframeFromTimeline(
                          activeKeyframeIndex
                        )
                    : () => {}
                }
                className={classnames({
                  active_plus:
                    this.keyframeNotEmpty(keyframes, activeKeyframeIndex) &&
                    keyframes.length !== 1,
                })}
                title="Remove Frame"
              >
                <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/minus.png" />
              </li>
              <li
                onClick={() =>
                  !this.props.isLoading &&
                  keyframes[activeKeyframeIndex] &&
                  keyframes[activeKeyframeIndex].keyframe &&
                  keyframes[activeKeyframeIndex].keyframe.objects.length > 0
                    ? this.props.duplicateKeyframe(activeKeyframeIndex + 1)
                    : null
                }
                className={classnames({
                  active_plus:
                    keyframes[activeKeyframeIndex] &&
                    keyframes[activeKeyframeIndex].keyframe &&
                    keyframes[activeKeyframeIndex].keyframe.objects &&
                    keyframes[activeKeyframeIndex].keyframe.objects.length > 0,
                })}
                title="Duplicate Frame"
              >
                <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/nex-prev.png" />
              </li>
            </ul>
          </div>
          <div className="frames_creates_div">
            <div
              className="prev_button"
              style={this.props.playing ? { pointerEvents: "none" } : {}}
              title="Previous Frame"
              onClick={() => this.props.prevPressed()}
            >
              <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/left_arrow.png" />
            </div>
            <div className="frames_sec">
              <SortableList
				 items={{keyframes, activeKeyframeIndex}} 
                onSortEnd={this.onSortEnd}
                axis="x"
                pressDelay={200}
              />
            </div>
            <div
              className={classnames({
                next_button: true,
                inactive_button: !this.props.nextBtnActiveInactive(),
              })}
              onClick={() => this.props.nextPressed()}
              style={
                this.props.nextBtnActiveInactive() && !this.props.playing
                  ? { pointerEvents: "auto" }
                  : { pointerEvents: "none" }
              }
              title="Next Frame"
            >
              <span>
                <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/right_arrow.png" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BottomTools;
