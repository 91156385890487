import React, { Component } from "react";

class UnsavedChangesModal extends Component {
	render() {
		return (
			<div className="modale_animation">
				<button
					type="button"
					id="unsavedChanges"
					style={{ display: "none" }}
					data-toggle="modal"
					data-target="#havnot_saved"
				>
					Open Modal
				</button>

				<div className="modal fade" id="havnot_saved" role="dialog" >
					<div className="modal-dialog">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div className="modal-content">
							<div className="modal-body">
								<div className="havnot_picker_popup">
									<div className="have_not_box">
										<img src="https://d3rc7k2n78msdl.cloudfront.net/havent_saved_background.png" />
										<div className="have_not_text">
											<img src="https://d3rc7k2n78msdl.cloudfront.net/havent_saved_bg.png" />
										</div>
										<div className="save_no_save">
											<div
												className="No_save"
												onClick={() =>
													this.props.noAndProceedBtn()
												}
											>
												<img src="https://d3rc7k2n78msdl.cloudfront.net/no_dont_save.png" />
											</div>
											<div
												className="yes_save"
												onClick={() =>
													this.props.saveBtnInUnsavedChangesModal()
												}
											>
												<img src="https://d3rc7k2n78msdl.cloudfront.net/yes_sve.png" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UnsavedChangesModal;
