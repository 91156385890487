import RestClient from "../../utilities/RestClient";
import * as CANVAS_TYPE from "./types";
import * as appActions from "../app/actions";
// import { push } from "react-router-redux";
import { swal } from "react-redux-sweetalert2";

//Action Creator For Reducers
export const _saveNewKeyframe = data => ({
  type: CANVAS_TYPE.SAVE_NEW_KEYFRAME,
  data: data
});

export const _addNewKeyframeAt = data => ({
  type: CANVAS_TYPE.ADD_NEW_KEYFRAME_AT,
  data: data
});

export const _updateKeyframe = data => ({
  type: CANVAS_TYPE.UPDATE_KEYFRAME,
  data: data
});

export const _deleteKeyframe = data => ({
  type: CANVAS_TYPE.DELETE_KEYFRAME,
  data: data
});

export const _handlePanTransform = data => ({
  type: CANVAS_TYPE.PAN_TRANSFORM,
  data: data
});

export const _clearPanTransform = data => ({
  type: CANVAS_TYPE.CLEAR_PAN_TRANSFORM,
  data
});

export const _emptyKeyframe = data => ({
  type: CANVAS_TYPE.EMPTY_KEYFRAME,
  data: data
});

export const _duplicateKeyframeAt = data => ({
  type: CANVAS_TYPE.DUPLICATE_KEYFRAME_AT,
  data: data
});

export const _addExposureAt = data => ({
  type: CANVAS_TYPE.ADD_EXPOSURE_AT,
  data: data
});

export const _setProjectType = data => ({
  type: CANVAS_TYPE.SET_PROJECT_TYPE,
  data: data
});

export const _undoKeyframe = data => ({
  type: CANVAS_TYPE.UNDO,
  data: data
});

export const _purgeCanvas = () => ({
  type: CANVAS_TYPE.PURGE_CANVAS
});

export const _reorderKeyframePosition = data => ({
  type: CANVAS_TYPE.REORDER_KEYFRAME_POSITION,
  data
});


export const removeNameAndID = () => ({
  type: CANVAS_TYPE.REMOVE_NAME_AND_ID
});

export const _updateKeyframeAsExposureWithPreviousDrawnObject = data => ({
  type: CANVAS_TYPE.UPDATE_KEYFRAME_AS_EXPOSURED,
  data
});

export const _resetAllExposuredFramesOfSpecificAddedExposureOfIndex = data => ({
  type: CANVAS_TYPE.RESET_ALL_EXPOSURED_OF_SPECIFIC_EXPOSURED_INDEX,
  data
});

export const _updateExposuredFrame = data => ({
  type: CANVAS_TYPE.UPDATE_EXPOSURED_FRAME,
  data
});

export const _handleBgOnDuplicateKeyframe = data => ({
  type: CANVAS_TYPE.HANDLE_BG_ON_DUPLICATE_KEYFRAME,
  data
});

export const _handleBgOnAddExposure = data => ({
  type: CANVAS_TYPE.HANDLE_BG_ON_ADD_EXPOSURE,
  data
});

export const _handleFgOnAddExposure = data => ({
  type: CANVAS_TYPE.HANDLE_FG_ON_ADD_EXPOSURE,
  data
});

export const _handleFgOnDuplicateKeyframe = data => ({
  type: CANVAS_TYPE.HANDLE_FG_ON_DUPLICATE_KEYFRAME,
  data
});

export const _updateMovePath = data => ({
  type: CANVAS_TYPE.UPDATE_MOVE_PATH,
  data: data
});

// Thunk Action Creators For Api //

// action creator for registering to user
export const newKeyframe = keyframe => {
  return dispatch => {
    // dispatch(appActions.showLoader());
    dispatch(_saveNewKeyframe(keyframe));
    // dispatch(appActions.hideLoader());
  };
};

export const addNewKeyframeAt = params => {
  return dispatch => {
    // dispatch(appActions.showLoader());
    dispatch(_addNewKeyframeAt(params));
    // dispatch(appActions.hideLoader());
  };
};

export const updateKeyframe = (params, cb) => {
  return dispatch => {
    dispatch(_updateKeyframe(params));
  };
};

export const deleteKeyframe = params => {
  return dispatch => {
    dispatch(_deleteKeyframe(params));
  };
};

export const emptyKeyframe = params => {
  return dispatch => {
    dispatch(_emptyKeyframe(params));
  };
};

export const duplicateKeyframeAt = params => {
  return dispatch => {
    // dispatch(appActions.showLoader());
    dispatch(_duplicateKeyframeAt(params));
    // dispatch(appActions.hideLoader());
  };
};

export const updateKeyframeAsExposureWithPreviousDrawnObject = params => {
  return dispatch => {
    dispatch(_updateKeyframeAsExposureWithPreviousDrawnObject(params));
  };
};

export const resetAllExposuredFramesOfSpecificAddedExposureOfIndex = params => {
  return dispatch => {
    dispatch(_resetAllExposuredFramesOfSpecificAddedExposureOfIndex(params));
  };
};

export const handleBgOnDuplicateKeyframe = params => {
  return dispatch => {
    dispatch(_handleBgOnDuplicateKeyframe(params));
  };
};

export const handleBgOnAddExposure = params => {
  return dispatch => {
    dispatch(_handleBgOnAddExposure(params));
  };
};

export const handleFgOnAddExposure = params => {
  return dispatch => {
    dispatch(_handleFgOnAddExposure(params));
  };
};

export const handleFgOnDuplicateKeyframe = params => {
  return dispatch => {
    dispatch(_handleFgOnDuplicateKeyframe(params));
  };
};

export const updateExposuredFrame = params => {
  return dispatch => {
    dispatch(_updateExposuredFrame(params));
  };
};

export const addExposureAt = params => {
  return dispatch => {
    // dispatch(appActions.showLoader());
    dispatch(_addExposureAt(params));
    // dispatch(appActions.hideLoader());
  };
};

export const setProjectType = params => {
  return dispatch => {
    // dispatch(appActions.showLoader());
    dispatch(_setProjectType(params));
    // dispatch(appActions.hideLoader());
  };
};

export const undoKeyframe = params => {
  return dispatch => {
    // dispatch(appActions.showLoader());
    try {
      dispatch(_undoKeyframe(params));
    } catch (e) {
      dispatch(
        swal.showAlert({
          title: "Error!",
          text: "Something went wrong! Please try again.",
          type: "error",
          confirmAlert: () => false
        })
      );
    }

    // dispatch(appActions.hideLoader());
  };
};

export const reorderKeyframePosition = data => {
  return dispatch => {
    try {
      dispatch(_reorderKeyframePosition(data));
    } catch (e) {
      dispatch(
        swal.showAlert({
          title: "Error!",
          text: "Something went wrong! Please try again.",
          type: "error",
          confirmAlert: () => false
        })
      );
    }

    // dispatch(appActions.hideLoader());
  };
};



export const purgeCanvas = () => {
  return dispatch => {
    try {
      dispatch(_purgeCanvas());
    } catch (e) {
      dispatch(
        swal.showAlert({
          title: "Error!",
          text: "Something went wrong! Please try again.",
          type: "error",
          confirmAlert: () => false
        })
      );
    }

    // dispatch(appActions.hideLoader());
  };
};

export const generateVideo = (params, cb) => {
  return dispatch => {
    dispatch(appActions.showLoader());
    RestClient.formData("/downloadlink", params)
      .then(result => {
        if (result.filename) {
          cb(result);
        }
        dispatch(appActions.hideLoader());
      })
      .catch(e => {
        dispatch(
          swal.showAlert({
            title: "Error!",
            text: "Something went wrong! Please try again.",
            type: "error",
            confirmAlert: () => false
          })
        );
        dispatch(appActions.hideLoader());
      });
  };
};

export const updateMovePath = (params, cb) => {
  return dispatch => {
    dispatch(_updateMovePath(params));
  };
};
