import React, { Component } from "react";
import Pdf from "../utilities/terms_March2023.pdf"

class TermsAndConditionsModal extends Component {
	render() {
		return (
			<div className="modale_animation">
				<button
					type="button"
					id="termsModal"
					style={{ display: "none" }}
					data-toggle="modal"
					data-target="#havnot_saved"
				>
					Open Modal
				</button>

				<div className="modal fade" id="havnot_saved" role="dialog">
					<div className="modal-dialog">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div className="modal-content">
							<div className="modal-body">
								<embed
    								src={Pdf + "#toolbar=0"}
    								type="application/pdf"
    								height={500}
    								width={700}
									/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TermsAndConditionsModal;
