/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import eraserHelper from "./eraserHelper";

const fabric = require("fabric").fabric;

class Eraser extends FabricCanvasTool {
  configureCanvas(prop) {
    let canvas = this._canvas;
    canvas.discardActiveObject();
    canvas.requestRenderAll();

    var __renderOverlay = fabric.Canvas.prototype._renderOverlay;

    const EraserBrush = fabric.util.createClass(
      fabric.PencilBrush,
      /** @lends fabric.EraserBrush.prototype */ {
        type: "eraser",

        /**
         * When set to `true` the brush will create a visual effect of undoing erasing
         */
        inverted: false,

        /**
         * @private
         */
        _isErasing: false,

        /**
         *
         * @private
         * @param {fabric.Object} object
         * @returns boolean
         */
        _isErasable: function (object) {
          return object.erasable !== false;
        },

        /**
         * @private
         * This is designed to support erasing a collection with both erasable and non-erasable objects.
         * Iterates over collections to allow nested selective erasing.
         * Prepares the pattern brush that will draw on the top context to achieve the desired visual effect.
         * If brush is **NOT** inverted render all non-erasable objects.
         * If brush is inverted render all erasable objects that have been erased with their clip path inverted.
         * This will render the erased parts as if they were not erased.
         *
         * @param {fabric.Collection} collection
         * @param {CanvasRenderingContext2D} ctx
         * @param {{ visibility: fabric.Object[], eraser: fabric.Object[], collection: fabric.Object[] }} restorationContext
         */
        _prepareCollectionTraversal: function (
          collection,
          ctx,
          restorationContext
        ) {
          collection.forEachObject(function (obj) {
            if (obj.type == 'group') {
              //  traverse
              this._prepareCollectionTraversal(obj, ctx, restorationContext);
            } else if (!this.inverted && obj.erasable && obj.visible) {
              //  render only non-erasable objects
              obj.visible = false;
              collection.dirty = true;
              restorationContext.visibility.push(obj);
              restorationContext.collection.push(collection);
            } else if (this.inverted && obj.visible) {
              //  render only erasable objects that were erased
              if (obj.erasable && obj.eraser) {
                obj.eraser.inverted = true;
                obj.dirty = true;
                collection.dirty = true;
                restorationContext.eraser.push(obj);
                restorationContext.collection.push(collection);
              } else {
                obj.visible = false;
                collection.dirty = true;
                restorationContext.visibility.push(obj);
                restorationContext.collection.push(collection);
              }
            }
          }, this);
        },

        /**
         * Prepare the pattern for the erasing brush
         * This pattern will be drawn on the top context, achieving a visual effect of erasing only erasable objects
         * @todo decide how overlay color should behave when `inverted === true`, currently draws over it which is undesirable
         * @private
         */
        preparePattern: function () {
          if (!this._patternCanvas) {
            this._patternCanvas = fabric.util.createCanvasElement();
          }
          var canvas = this._patternCanvas;
          canvas.width = this.canvas.width;
          canvas.height = this.canvas.height;
          var patternCtx = canvas.getContext("2d");
          if (this.canvas._isRetinaScaling()) {
            var retinaScaling = this.canvas.getRetinaScaling();
            this.canvas.__initRetinaScaling(retinaScaling, canvas, patternCtx);
          }
          var backgroundImage = this.canvas.backgroundImage,
            bgErasable = backgroundImage && this._isErasable(backgroundImage),
            overlayImage = this.canvas.overlayImage,
            overlayErasable = overlayImage && this._isErasable(overlayImage);
          if (
            !this.inverted &&
            ((backgroundImage && !bgErasable) || !!this.canvas.backgroundColor)
          ) {
            if (bgErasable) {
              this.canvas.backgroundImage = undefined;
            }
            this.canvas._renderBackground(patternCtx);
            if (bgErasable) {
              this.canvas.backgroundImage = backgroundImage;
            }
          } else if (this.inverted && backgroundImage && bgErasable) {
            var color = this.canvas.backgroundColor;
            this.canvas.backgroundColor = undefined;
            this.canvas._renderBackground(patternCtx);
            this.canvas.backgroundColor = color;
          }
          patternCtx.save();
          patternCtx.transform.apply(patternCtx, this.canvas.viewportTransform);
          var restorationContext = {
            visibility: [],
            eraser: [],
            collection: [],
          };
          this._prepareCollectionTraversal(
            this.canvas,
            patternCtx,
            restorationContext
          );
          this.canvas._renderObjects(patternCtx, this.canvas._objects);
          restorationContext.visibility.forEach(function (obj) {
            obj.visible = true;
          });
          restorationContext.eraser.forEach(function (obj) {
            obj.eraser.inverted = false;
            obj.dirty = true;
          });
          restorationContext.collection.forEach(function (obj) {
            obj.dirty = true;
          });
          patternCtx.restore();
          if (
            !this.inverted &&
            ((overlayImage && !overlayErasable) || !!this.canvas.overlayColor)
          ) {
            if (overlayErasable) {
              this.canvas.overlayImage = undefined;
            }
            __renderOverlay.call(this.canvas, patternCtx);
            if (overlayErasable) {
              this.canvas.overlayImage = overlayImage;
            }
          } else if (this.inverted && overlayImage && overlayErasable) {
            var color = this.canvas.overlayColor;
            this.canvas.overlayColor = undefined;
            __renderOverlay.call(this.canvas, patternCtx);
            this.canvas.overlayColor = color;
          }
        },

        /**
         * Sets brush styles
         * @private
         * @param {CanvasRenderingContext2D} ctx
         */
        _setBrushStyles: function (ctx) {
          this.callSuper("_setBrushStyles", ctx);
          ctx.strokeStyle = "black";
        },

        /**
         * **Customiztion**
         *
         * if you need the eraser to update on each render (i.e animating during erasing) override this method by **adding** the following (performance may suffer):
         * @example
         * ```
         * if(ctx === this.canvas.contextTop) {
         *  this.preparePattern();
         * }
         * ```
         *
         * @override fabric.BaseBrush#_saveAndTransform
         * @param {CanvasRenderingContext2D} ctx
         */
        _saveAndTransform: function (ctx) {
          this.callSuper("_saveAndTransform", ctx);
          this._setBrushStyles(ctx);
          ctx.globalCompositeOperation =
            ctx === this.canvas.getContext()
              ? "destination-out"
              : "source-over";
        },

        /**
         * We indicate {@link fabric.PencilBrush} to repaint itself if necessary
         * @returns
         */
        needsFullRender: function () {
          return true;
        },

        /**
         *
         * @param {fabric.Point} pointer
         * @param {fabric.IEvent} options
         * @returns
         */
        onMouseDown: function (pointer, options) {
          if (!this.canvas._isMainEvent(options.e)) {
            return;
          }
          this._prepareForDrawing(pointer);
          // capture coordinates immediately
          // this allows to draw dots (when movement never occurs)
          this._captureDrawingPath(pointer);

          //  prepare for erasing
          this.preparePattern();
          this._isErasing = true;
          this.canvas.fire("erasing:start");
          this._render();
        },

        /**
         * Rendering Logic:
         * 1. Use brush to clip canvas by rendering it on top of canvas (unnecessary if `inverted === true`)
         * 2. Render brush with canvas pattern on top context
         *
         */
        _render: function () {
          var ctx;
          if (!this.inverted) {
            //  clip canvas
            ctx = this.canvas.getContext();
            this.callSuper("_render", ctx);
          }
          //  render brush and mask it with image of non erasables
          ctx = this.canvas.contextTop;
          this.canvas.clearContext(ctx);
          this.callSuper("_render", ctx);
          ctx.save();
          var t = this.canvas.getRetinaScaling(),
            s = 1 / t;
          ctx.scale(s, s);
          ctx.globalCompositeOperation = "source-in";
          ctx.drawImage(this._patternCanvas, 0, 0);
          ctx.restore();
        },

        /**
         * Creates fabric.Path object
         * @override
         * @private
         * @param {(string|number)[][]} pathData Path data
         * @return {fabric.Path} Path to add on canvas
         * @returns
         */
        createPath: function (pathData) {
          var path = this.callSuper("createPath", pathData);
          path.globalCompositeOperation = this.inverted
            ? "source-over"
            : "destination-out";
          path.stroke = this.inverted ? "white" : "black";
          return path;
        },

        /**
         * Utility to apply a clip path to a path.
         * Used to preserve clipping on eraser paths in nested objects.
         * Called when a group has a clip path that should be applied to the path before applying erasing on the group's objects.
         * @param {fabric.Path} path The eraser path in canvas coordinate plane
         * @param {fabric.Object} clipPath The clipPath to apply to the path
         * @param {number[]} clipPathContainerTransformMatrix The transform matrix of the object that the clip path belongs to
         * @returns {fabric.Path} path with clip path
         */
        applyClipPathToPath: function (
          path,
          clipPath,
          clipPathContainerTransformMatrix
        ) {
          var pathInvTransform = fabric.util.invertTransform(
              path.calcTransformMatrix()
            ),
            clipPathTransform = clipPath.calcTransformMatrix(),
            transform = clipPath.absolutePositioned
              ? pathInvTransform
              : fabric.util.multiplyTransformMatrices(
                  pathInvTransform,
                  clipPathContainerTransformMatrix
                );
          //  when passing down a clip path it becomes relative to the parent
          //  so we transform it acoordingly and set `absolutePositioned` to false
          clipPath.absolutePositioned = false;
          fabric.util.applyTransformToObject(
            clipPath,
            fabric.util.multiplyTransformMatrices(transform, clipPathTransform)
          );
          //  We need to clip `path` with both `clipPath` and it's own clip path if existing (`path.clipPath`)
          //  so in turn `path` erases an object only where it overlaps with all it's clip paths, regardless of how many there are.
          //  this is done because both clip paths may have nested clip paths of their own (this method walks down a collection => this may reccur),
          //  so we can't assign one to the other's clip path property.
          path.clipPath = path.clipPath
            ? fabric.util.mergeClipPaths(clipPath, path.clipPath)
            : clipPath;
          return path;
        },

        /**
         * Utility to apply a clip path to a path.
         * Used to preserve clipping on eraser paths in nested objects.
         * Called when a group has a clip path that should be applied to the path before applying erasing on the group's objects.
         * @param {fabric.Path} path The eraser path
         * @param {fabric.Object} object The clipPath to apply to path belongs to object
         * @param {Function} callback Callback to be invoked with the cloned path after applying the clip path
         */
        clonePathWithClipPath: function (path, object, callback) {
          var objTransform = object.calcTransformMatrix();
          var clipPath = object.clipPath;
          var _this = this;
          path.clone(function (_path) {
            clipPath.clone(
              function (_clipPath) {
                callback(
                  _this.applyClipPathToPath(_path, _clipPath, objTransform)
                );
              },
              ["absolutePositioned", "inverted"]
            );
          });
        },

        /**
         * Adds path to object's eraser, walks down object's descendants if necessary
         *
         * @fires erasing:end on object
         * @param {fabric.Object} obj
         * @param {fabric.Path} path
         */
        _addPathToObjectEraser: function (obj, path) {
          return new Promise((r) => {
            var _this = this;
            //  object is collection, i.e group
            if (obj.type == 'group') {
              var targets = obj._objects.filter(function (_obj) {
                return _obj.erasable;
              });
              if (targets.length > 0 && obj.clipPath) {
                this.clonePathWithClipPath(path, obj, function (_path) {
                  targets.forEach(async function (_obj) {
                    await _this._addPathToObjectEraser(_obj, _path);
                  });
                });
              } else if (targets.length > 0) {
                targets.forEach(async function (_obj) {
                  await _this._addPathToObjectEraser(_obj, path);
                });
              }
              return r();
            }
            //  prepare eraser
            var eraser = obj.eraser;
            if (!eraser) {
              eraser = new fabric.Eraser();
              obj.eraser = eraser;
            }
            //  clone and add path
            path.clone(function (path) {
              // http://fabricjs.com/using-transformations
              var desiredTransform = fabric.util.multiplyTransformMatrices(
                fabric.util.invertTransform(obj.calcTransformMatrix()),
                path.calcTransformMatrix()
              );
              fabric.util.applyTransformToObject(path, desiredTransform);
              eraser.addWithUpdate(path);
              obj.set("dirty", true);
              obj.fire("erasing:end", {
                path: path,
              });
              if (obj.group && Array.isArray(_this.__subTargets)) {
                _this.__subTargets.push(obj);
              }
              return r();
            });
          });
        },

        /**
         * Add the eraser path to canvas drawables' clip paths
         *
         * @param {fabric.Canvas} source
         * @param {fabric.Canvas} path
         * @returns {Object} canvas drawables that were erased by the path
         */
        applyEraserToCanvas: function (path) {
          return new Promise((r) => {
            var canvas = this.canvas;
            var drawables = {};
            ["backgroundImage", "overlayImage"].forEach(async function (prop) {
              var drawable = canvas[prop];
              if (drawable && drawable.erasable) {
                await this._addPathToObjectEraser(drawable, path);
                drawables[prop] = drawable;
              }
            }, this);
            return r(drawables);
          });
        },

        /**
         * On mouseup after drawing the path on contextTop canvas
         * we use the points captured to create an new fabric path object
         * and add it to every intersected erasable object.
         */
        _finalizeAndAddPath: async function () {
          var ctx = this.canvas.contextTop,
            canvas = this.canvas;
          ctx.closePath();
          if (this.decimate) {
            this._points = this.decimatePoints(this._points, this.decimate);
          }

          // clear
          canvas.clearContext(canvas.contextTop);
          this._isErasing = false;

          var pathData =
            this._points && this._points.length > 1
              ? this.convertPointsToSVGPath(this._points)
              : null;
          if (!pathData || this._isEmptySVGPath(pathData)) {
            canvas.fire("erasing:end");
            // do not create 0 width/height paths, as they are
            // rendered inconsistently across browsers
            // Firefox 4, for example, renders a dot,
            // whereas Chrome 10 renders nothing
            canvas.requestRenderAll();
            return;
          }

          var path = this.createPath(pathData);
          //  needed for `intersectsWithObject`
          path.setCoords();
          //  commense event sequence
          canvas.fire("before:path:created", { path: path });

          // finalize erasing
          var drawables = await this.applyEraserToCanvas(path);
          var _this = this;
          this.__subTargets = [];
          var targets = [];
          canvas.forEachObject(async function (obj) {
            if (obj.erasable && obj.intersectsWithObject(path, true, true)) {
              await _this._addPathToObjectEraser(obj, path);
              targets.push(obj);
            }
          });
          //  fire erasing:end
          canvas.fire("erasing:end", {
            path: path,
            targets: targets,
            subTargets: this.__subTargets,
            drawables: drawables,
          });
          delete this.__subTargets;

          canvas.requestRenderAll();
          this._resetShadow();

          // fire event 'path' created
          canvas.fire("path:created", { path: path });
        },
      }
    );

    // to use it, just set the brush
    canvas.isDrawingMode = true;
    const eraserBrush = new EraserBrush(canvas);
    eraserBrush.width = prop.lineWidth;
    eraserBrush.color = "#ffffff";
    canvas.freeDrawingBrush = eraserBrush;

    // canvas.freeDrawingBrush.width = 5;
    // canvas.freeDrawingBrush.color = "green";

    /*
     * Note: Might not work with versions other than 3.1.0
     *
     * Made it so that the bound is calculated on the original only
     */
    const ErasedGroup = fabric.util.createClass(fabric.Group, {
      original: null,
      erasedPath: null,
      initialize: function (original, erasedPath, options, isAlreadyGrouped) {
        this.original = original;
        this.erasedPath = erasedPath;
        this.callSuper(
          "initialize",
          [this.original, this.erasedPath],
          options,
          isAlreadyGrouped
        );
      },

      _calcBounds: function (onlyWidthHeight) {
        const aX = [],
          aY = [],
          props = ["tr", "br", "bl", "tl"],
          jLen = props.length,
          ignoreZoom = true;

        let o = this.original;
        o.setCoords(ignoreZoom);
        for (let j = 0; j < jLen; j++) {
          prop = props[j];
          aX.push(o.oCoords[prop].x);
          aY.push(o.oCoords[prop].y);
        }

        this._getBounds(aX, aY, onlyWidthHeight);
      },
    });
  }
}

export default Eraser;