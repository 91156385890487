import * as TYPE from "./types";

// initial state of the reducer
const initialState = {
	userDetails: {},
	loggedIn: false
};

// const initialState = {
// 	userDetails: {
// 		id: "77965",
// 		type: "2",
// 		schoolID: "87",
// 		userName: "melissaAI",
// 		firstName: "Melissa",
// 		lastName: "S TEST"
// 	},
// 	loggedIn: true
// };

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case TYPE.LOGIN_SUCCESS:
			return { ...state, loggedIn: true, userDetails: action.data };

		case TYPE.LOGOUT:
			return initialState;
		default:
			return state;
	}
}
