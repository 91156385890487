export const SAVE_NEW_KEYFRAME = "SAVE_NEW_KEYFRAME";
export const ADD_NEW_KEYFRAME_AT = "ADD_NEW_KEYFRAME_AT";
export const UPDATE_KEYFRAME = "UPDATE_KEYFRAME";
export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const DELETE_KEYFRAME = "DELETE_KEYFRAME";
export const PURGE_CANVAS = "PURGE_CANVAS";
export const PAN_TRANSFORM = "PAN_TRANSFORM";
export const CLEAR_PAN_TRANSFORM = "CLEAR_PAN_TRANSFORM";
export const DUPLICATE_KEYFRAME_AT = "DUPLICATE_KEYFRAME_AT";
export const UNDO = "UNDO";
export const REORDER_KEYFRAME_POSITION = "REORDER_KEYFRAME_POSITION";
export const NEW_PROJECT_CREATED = "NEW_PROJECT_CREATED";
export const PROJECT_DETAILS_FETCHED = "PROJECT_DETAILS_FETCHED";
export const PROJECT_UPDATED = "PROJECT_UPDATED";
export const REMOVE_NAME_AND_ID = "REMOVE_NAME_AND_ID";
export const ADD_EXPOSURE_AT = "ADD_EXPOSURE_AT";
export const UPDATE_KEYFRAME_AS_EXPOSURED = "UPDATE_KEYFRAME_AS_EXPOSURED";
export const UPDATE_EXPOSURED_FRAME = "UPDATE_EXPOSURED_FRAME";
export const EMPTY_KEYFRAME = "EMPTY_KEYFRAME";
export const RESET_ALL_EXPOSURED_OF_SPECIFIC_EXPOSURED_INDEX =
	"RESET_ALL_EXPOSURED_OF_SPECIFIC_EXPOSURED_INDEX";
export const HANDLE_BG_ON_DUPLICATE_KEYFRAME =
	"HANDLE_BG_ON_DUPLICATE_KEYFRAME";
export const HANDLE_FG_ON_DUPLICATE_KEYFRAME =
	"HANDLE_FG_ON_DUPLICATE_KEYFRAME";
export const HANDLE_BG_ON_ADD_EXPOSURE = "HANDLE_BG_ON_ADD_EXPOSURE";
export const HANDLE_FG_ON_ADD_EXPOSURE = "HANDLE_FG_ON_ADD_EXPOSURE";
export const UPDATE_MOVE_PATH = "UPDATE_MOVE_PATH";
