import * as TYPE from "./types";
import * as USER_TYPE from "../user/types";

const initialState = {
  showAlert: false,
  errorMessage: "this is error message of this",
  title: "title here",
  isError: false,
  isLoading: false,
  alertCustomFunction: () => {
    alert(23423);
  } // Experimental functionality, may be deprecated in next release
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.HIDE_LOADER:
      return { ...state, isLoading: false };

    case TYPE.SHOW_LOADER:
      return { ...state, isLoading: true };

    case TYPE.HANDLE_ERROR:
      return {
        ...state,
        title: "",
        isLoading: false,
        ...action.data
      };
    case TYPE.SWEETALERT_SHOW:
      return { ...state, isLoading: false };

    case USER_TYPE.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
