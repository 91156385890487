/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import { linearDistance } from "./utils";

const fabric = require("fabric").fabric;

class Triangle extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = false;

    canvas.forEachObject(o => {
      o.selectable = false;
      o.evented = false;
      o.hasControls = false;
      o.hasBorders = false;
    });

    this._width = props.lineWidth;
    this._color = props.lineColor;
    this._fill = props.fillColor;
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    if (this.triangle) return; // If a triangle already exists, return early

    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];
    const fixedSize = 200; // Set the desired size here
    const halfSize = fixedSize / 2;

    const points = [
      { x: this.startX, y: this.startY - halfSize }, // Top
      { x: this.startX - halfSize, y: this.startY + halfSize }, // Bottom left
      { x: this.startX + halfSize, y: this.startY + halfSize } // Bottom right
    ];

    this.triangle = new fabric.Triangle({
      left: this.startX,
      top: this.startY,
      originX: "center",
      originY: "center",
      strokeWidth: this._width,
      stroke: this._color,
      fill: this._fill,
      selectable: false,
      evented: false,
      width: fixedSize,
      height: fixedSize,
      angle: 0,
      strokeUniform: true
    });

    canvas.add(this.triangle);
    canvas.bringToFront(this.triangle);
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.triangle = null; // Reset the triangle so a new one can be added on the next mouse down
  }
}

export default Triangle;
