import { compose, applyMiddleware, createStore } from "redux";
import {
	actionStorageMiddleware,
	createStorageListener
} from "redux-state-sync";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import reducer from "../redux";
import { routerMiddleware } from "react-router-redux";

export default function configureStore(history) {
	const store = createStore(
		reducer,
		compose(
			applyMiddleware(
				thunk,
				actionStorageMiddleware,
				routerMiddleware(history)
			)
		)
	);

	const persistor = persistStore(store);

	//Sync state between browser tabs
	createStorageListener(store, {});

	return { persistor, store };
}
