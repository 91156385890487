// Global loader for whole application //

import React from "react";
import { RotateLoader } from "halogenium";

export default ({ loading = true }) => {
	return (
		loading && (
			<div
				style={{
					display: "block",
					fontSize: "0",
					position: "fixed",
					zIndex: "9999",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)"
				}}
			>
				<RotateLoader color="#33A5C9" size="22px" margin="4px" />
			</div>
		)
	);
};
